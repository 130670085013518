<template>
  <div
    v-loading="$store.getters['user/userLoad']"
    class="px-md-3 main_student_container plc_user_profile_for_container"
  >
    <div class="main_profile_card_when_print_it">
      <!-- Start user information and learn type -->
      <div class="user_information_when_print">
        <div class="the_info_one">
          <img class="img" :src="image" alt="user img" />
          <div>
            <span class="email_name d-block text-capitalize">
              {{ `${user.first_name} ${user.last_name}` }}
            </span>
            <span class="email_name email_too d-block">
              {{ user.email }}
            </span>
          </div>
        </div>
        <!-- Start learn type -->
        <div class="learner_type">
          <div class="part_title">
            <span class=" text-uppercase" v-if="user.classification == 'Bridge'"
              >{{ localization("Bridge Learner") }}
              {{ localization("Definition") }}
            </span>
            <span
              class=" text-uppercase"
              v-if="user.classification == 'Dynamic'"
              >{{ localization("Dynamic Learner") }}
              {{ localization("Definition") }}</span
            >
            <span
              class=" text-uppercase"
              v-if="user.classification == 'Strong-Willed Learner'"
              >{{ localization("Strong-Willed Learner") }}
              {{ localization("Definition") }}</span
            >
          </div>
          <div class="part_description">
            <span class=" d-block" v-if="user.classification == 'Bridge'">
              {{ localization("The Bridge Learner uses all Patterns at a Use as Needed level. Your Patterns serve a helpful role by leading from the middle. Bridge learners are team catalysts. The Bridge learner can close the gap of understanding between learners who have Use First and Avoid Patterns.") }}
            </span>
            <span class=" d-block" v-if="user.classification == 'Dynamic'">
              {{ localization("The Dynamic Learner uses at least one Pattern at the Use First level, while the remaining ones are any combination of another Use First, Use as Needed, or Avoid. When moving from using one Pattern to another you can actually sense that a shift in your thoughts, feelings and actions is occurring particularly when you move from your Use First Patterns to those you Avoid.") }}
            </span>
            <span
              class=" d-block"
              v-if="user.classification == 'Strong-Willed Learner'"
            >
              {{ localization("The Strong-Willed Learner uses three or more Patterns at the Use First level. Strong-Willed learners seek opportunities to lead rather than be led. Your Patterns would have you prefer to control the plan, the ideas, the talks, the decisions, the processes, and the outcomes and do it all yourself. Understanding how your Patterns affect those around you is particularly important when you are a Strong-Willed Learner.") }}
            </span>
          </div>
        </div>
        <!-- End learn type -->
      </div>
      <!-- End user information and learn type -->

      <!-- Start User Score -->
      <div class="user_pattern_scores">
        <div class="every_user_score sequence_score ">
          <div class="word_and_score">
            <span class="word">
              {{ localization("Sequence") }}
            </span>
            <span class="score">
              {{ userScore.sequence != null ? userScore.sequence : 0 }}
            </span>
          </div>
          <div class="descriptions_texts">
            <div class="main_description_title">
              {{ localization("SEQUENTIAL LEARNING PATTERNS") }}
            </div>
            <div class="another_text">{{ localization("Organizes life") }}</div>
            <div class="another_text">{{ localization("Makes Lists") }}</div>
            <div class="another_text">
              {{ localization("Lives by schedules") }}
            </div>
            <div class="another_text">
              {{ localization("Makes extensive plans") }}
            </div>
          </div>
        </div>
        <div class="every_user_score precision_score">
          <div class="word_and_score">
            <span class="word">
              {{ localization("precision") }}
            </span>
            <span class="score">
              {{ userScore.precision != null ? userScore.precision : 0 }}
            </span>
          </div>
          <div class="descriptions_texts">
            <div class="main_description_title">
              {{ localization("PRECISION LEARNING PATTERNS") }}
            </div>
            <div class="another_text">
              {{ localization("Relishes information") }}
            </div>
            <div class="another_text">{{ localization("Seeks facts") }}</div>
            <div class="another_text">
              {{ localization("Must be right") }}
            </div>
            <div class="another_text">
              {{ localization("Provides detailed responses") }}
            </div>
          </div>
        </div>
        <div class="every_user_score technical_reasoning_score">
          <div class="word_and_score">
            <span class="word">
              {{ localization("Technical Reasoning") }}
            </span>
            <span class="score">
              {{
                userScore.technical_reasoning != null
                  ? userScore.technical_reasoning
                  : 0
              }}
            </span>
          </div>
          <div class="descriptions_texts">
            <div class="main_description_title">
              {{ localization("TECHNICAL LEARNING PATTERNS") }}
            </div>
            <div class="another_text">
              {{ localization("Solves problems") }}
            </div>
            <div class="another_text">{{ localization("Uses few words") }}</div>
            <div class="another_text">
              {{ localization("Looks for relevance and practicality") }}
            </div>
            <div class="another_text">
              {{ localization("Works alone") }}
            </div>
          </div>
        </div>
        <div class="every_user_score confluence_score">
          <div class="word_and_score">
            <span class="word">
              {{ localization("Confluence") }}
            </span>
            <span class="score">
              {{ userScore.confluence != null ? userScore.confluence : 0 }}
            </span>
          </div>
          <div class="descriptions_texts">
            <div class="main_description_title">
              {{ localization("CONFLUENCE LEARNING PATTERNS") }}
            </div>
            <div class="another_text">{{ localization("Takes risks") }}</div>
            <div class="another_text">
              {{ localization("Sees the big picture") }}
            </div>
            <div class="another_text">
              {{ localization("Embraces innovation") }}
            </div>
            <div class="another_text">
              {{ localization("Initiates change") }}
            </div>
          </div>
        </div>
      </div>
      <!-- End User Score -->

      <!-- Start Use Degree -->
      <div class="use_degree_wrapper">
        <div class="a_one with_title">
          <span class="text-uppercase">{{
            localization("DEGREE OF USE")
          }}</span>
          <span>:</span>
        </div>
        <div class="a_one ">
          <span class="text-capitalize">{{ localization("Use First") }}</span>
          <span>:</span>
          <span class="text-uppercase">35 - 25</span>
        </div>
        <div class="a_one a_two">
          <span class="text-capitalize">{{ localization("As Needed") }}</span>
          <span>:</span>
          <span class="text-uppercase">24 - 18</span>
        </div>
        <div class="a_one ">
          <span class="text-capitalize">{{ localization("Avoid") }}</span>
          <span>:</span>
          <span class="text-uppercase">17 - 7</span>
        </div>
      </div>
      <!-- End Use Degree -->

      <!-- Start Personalize Cards -->
      <div class="personalize_cards_container" v-if="lciCardsContent">
        <div class="every_lci_card" v-if="lciCardsContent[0].body != null">
          <div class="icons_text_wrapper">
            <div class="img_icons">
              <img
                class="img"
                src="@/assets/images/icons/form.png"
                alt="add"
                draggable="false"
              />
            </div>
            <div>
              <span v-html="lciCardsContent[0].body"></span>
            </div>
          </div>
        </div>
        <div class="every_lci_card" v-if="lciCardsContent[1].body != null">
          <div class="icons_text_wrapper">
            <div class="img_icons">
              <img
                class="img"
                src="@/assets/images/icons/add.png"
                alt="add"
                draggable="false"
              />
            </div>
            <div>
              <span v-html="lciCardsContent[1].body"></span>
            </div>
          </div>
        </div>
      </div>
      <!-- End Personalize Cards -->
    </div>
    <!-- Direct user to join class room -->

    <!--  Start alert when user not has classroom id   -->
    <div class="join_class_room py-2" v-if="!user.student_classroom_id">
      <el-alert
        type="info"
        show-icon
        effect="dark"
        :class="{ is_rtl: isRtl() }"
        class="alert_google "
      >
        <!-- <div > -->
        <p style="font-size: 1.2rem; margin-bottom: 0px">
          <span>{{ localization("Sync your account to google classroom") }}</span>
          <router-link to="/sync-data" class="text-white font-weight-bolder">
            {{ localization("Sync On") }}
          </router-link>
        </p>

        <!-- </div> -->
      </el-alert>
    </div>
    <!--  End alert when user not has classroom id  -->

    <!--  Start main card for user information  -->
    <card class="card-user position-relative">
      <!--//? start user background image  -->
      <div class="main-image-card user_bg_img plc_hide_while_printing">
        <img
          class="img d-none"
          src="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=800"
          alt="..."
        />
        <div class="new_user_background"></div>
      </div>
      <!--//? end user background image  -->

      <div class="plc_hide_while_printing">
        <div class="author">
          <el-upload
            v-if="this.auth().id === user.id"
            class="upload-demo"
            :show-file-list="false"
            accept=".png,.jpg"
            :on-success="Refresh"
            :action="upload_src"
          >
            <i
              class="fa fa-edit hide-edit"
              style="
                position: relative;
                left: 0;
                top: 8.7rem;
                z-index: 1001;
                font-weight: bolder;
                font-size: 16px;
              "
            ></i>
          </el-upload>
          <img class="avatar border-dark" :src="image" alt="..." />
          <h4 class="title head-of-user">
            <p class="hide-while-print">
              <ShareNetwork
                network="twitter"
                :url="`https://test.plcplatform.com/profile/${user.id}`"
                title="PERSONAL LEARNING COACH (PLC)"
                :quote="
                  `sequence : ${userScore.sequence} precision : ${userScore.precision} technical_reasoning : ${userScore.technical_reasoning}  confluence : ${userScore.confluence} `
                "
                hashtags="PERSONAL_LEARNING_COACH(PLC)"
              >
                <i
                  class="fa fa-twitter mr-3 sharing_icons_media"
                  style="font-size: 20px"
                  v-tooltip.top-center="`${localization('Share on twitter')}`"
                ></i>
              </ShareNetwork>
              <ShareNetwork
                network="facebook"
                :url="`https://test.plcplatform.com/profile/${user.id}`"
                title="PERSONAL LEARNING COACH (PLC)"
                :quote="
                  `sequence : ${userScore.sequence} precision : ${userScore.precision} technical_reasoning : ${userScore.technical_reasoning}  confluence : ${userScore.confluence} `
                "
                hashtags="PERSONAL_LEARNING_COACH(PLC)"
              >
                <i
                  class="fa fa-facebook sharing_icons_media"
                  style="font-size: 20px"
                  v-tooltip.top-center="`${localization('Share on facebook')}`"
                ></i>
              </ShareNetwork>
            </p>
            <div class="main_auth_user_info">
              <span class="d-block mb-2">{{
                `${user.first_name} ${user.last_name}`
              }}</span>
              <small> {{ user.email }}</small>
            </div>

            <div class="which_user_type mt-2 mb-3">
              <div class="nn">
                <span
                  class="classification"
                  v-if="user.classification == 'Bridge'"
                  >{{ localization("Bridge Learner") }}</span
                >
                <span
                  class="classification"
                  v-if="user.classification == 'Dynamic'"
                  >{{ localization("Dynamic Learner") }}</span
                >
                <span
                  class="classification"
                  v-if="user.classification == 'Strong-Willed Learner'"
                  >{{ localization("Strong-Willed Learner") }}</span
                >

                <div class="another_content">
                  <span
                    class="tit text-uppercase"
                    v-if="user.classification == 'Bridge'"
                    >{{ localization("Bridge Learner") }}
                    {{ localization("Definition") }}
                  </span>
                  <span
                    class="tit text-uppercase"
                    v-if="user.classification == 'Dynamic'"
                    >{{ localization("Dynamic Learner") }}
                    {{ localization("Definition") }}</span
                  >
                  <span
                    class="tit text-uppercase"
                    v-if="user.classification == 'Strong-Willed Learner'"
                    >{{ localization("Strong-Willed Learner") }}
                    {{ localization("Definition") }}</span
                  >

                  <!-- Description Text  -->
                  <span
                    class="tex d-block"
                    v-if="user.classification == 'Bridge'"
                  >
                    {{ localization("The Bridge Learner uses all Patterns at a Use as Needed level. Your Patterns serve a helpful role by leading from the middle. Bridge learners are team catalysts. The Bridge learner can close the gap of understanding between learners who have Use First and Avoid Patterns.") }}
                  </span>
                  <span
                    class="tex d-block"
                    v-if="user.classification == 'Dynamic'"
                  >
                    {{ localization("The Dynamic Learner uses at least one Pattern at the Use First level, while the remaining ones are any combination of another Use First, Use as Needed, or Avoid. When moving from using one Pattern to another you can actually sense that a shift in your thoughts, feelings and actions is occurring particularly when you move from your Use First Patterns to those you Avoid.") }}
                  </span>
                  <span
                    class="tex d-block"
                    v-if="user.classification == 'Strong-Willed Learner'"
                  >
                    {{ localization("The Strong-Willed Learner uses three or more Patterns at the Use First level. Strong-Willed learners seek opportunities to lead rather than be led. Your Patterns would have you prefer to control the plan, the ideas, the talks, the decisions, the processes, and the outcomes and do it all yourself. Understanding how your Patterns affect those around you is particularly important when you are a Strong-Willed Learner.") }}
                  </span>
                </div>
                <!-- Dynamic -->
                <!-- Strong-Willed Learner -->
              </div>
            </div>
            <!-- IS THAT WE PRINTING  -->
            <div class="is_that_we_printing">
              <div class="part_title">
                <span
                  class=" text-uppercase"
                  v-if="user.classification == 'Bridge'"
                  >{{ localization("Bridge Learner") }}
                  {{ localization("Definition") }}
                </span>
                <span
                  class=" text-uppercase"
                  v-if="user.classification == 'Dynamic'"
                  >{{ localization("Dynamic Learner") }}
                  {{ localization("Definition") }}</span
                >
                <span
                  class=" text-uppercase"
                  v-if="user.classification == 'Strong-Willed Learner'"
                  >{{ localization("Strong-Willed Learner") }}
                  {{ localization("Definition") }}</span
                >
              </div>
              <div class="part_description">
                <span class=" d-block" v-if="user.classification == 'Bridge'">
                  {{ localization("The Bridge Learner uses all Patterns at a Use as Needed level. Your Patterns serve a helpful role by leading from the middle. Bridge learners are team catalysts. The Bridge learner can close the gap of understanding between learners who have Use First and Avoid Patterns.") }}
                </span>
                <span class=" d-block" v-if="user.classification == 'Dynamic'">
                  {{ localization("The Dynamic Learner uses at least one Pattern at the Use First level, while the remaining ones are any combination of another Use First, Use as Needed, or Avoid. When moving from using one Pattern to another you can actually sense that a shift in your thoughts, feelings and actions is occurring particularly when you move from your Use First Patterns to those you Avoid.") }}
                </span>
                <span
                  class=" d-block"
                  v-if="user.classification == 'Strong-Willed Learner'"
                >
                  {{ localization("The Strong-Willed Learner uses three or more Patterns at the Use First level. Strong-Willed learners seek opportunities to lead rather than be led. Your Patterns would have you prefer to control the plan, the ideas, the talks, the decisions, the processes, and the outcomes and do it all yourself. Understanding how your Patterns affect those around you is particularly important when you are a Strong-Willed Learner.") }}
                </span>
              </div>
            </div>
            <!-- <div class="main_auth_user_info_print">
              <span class="d-block mb-2 n_name">{{
                `${user.first_name} ${user.last_name}`
              }}</span>
              <span class="d-block n_email"> {{ user.email }}</span>
            </div> -->
          </h4>
          <!-- Show how much time remaining to end subscription -->
          <!-- Hide if admin  -->
          <div
            class="expiration_timer_card position-absolute px-3 py-2 text-white"
            v-if="
              !hasPermission('access_dashboard') &&
                this.remainingTimeToExpire > 0
            "
            v-show="show_expiration_timer"
          >
            <p class="p-0 m-0 pr-3">
              {{ localization("Subscription Expired") }}<br />
              <span class="timer">
                {{ getRmainTimeToExp() }}
              </span>
            </p>
            <button
              class="
                close_expiration_timer
                bg-white
                rounded-circle
                border border-0
                position-absolute
              "
              @click="show_expiration_timer = false"
            >
              {{ $t("profile.X") }}
            </button>
          </div>
        </div>

        <!-- //? end hasPermission  -->
      </div>
      <!-- Start Icons Wrapper  -->
      <div
        class="row d-flex justify-content-end plc_hide_while_printing"
        v-if="this.auth().id === user.id || hasPermission('access_users')"
      >
        <el-tooltip
          class=" mb-2 mr-4 edit-btn print-btn main_tool_tip"
          :content="`${localization('Edit score')}`"
          placement="top-start"
        >
          <el-button
            v-if="hasPermission('access_users') || hasPermission('edit_users')"
            @click="open_score_dialog"
            type="success"
            class="mb-2 mr-4 edit-btn edit_score_button"
            ><i class="fa fa-edit"></i>
          </el-button>
        </el-tooltip>

        <el-tooltip
          class=" mb-2 mr-4 edit-btn print-btn main_tool_tip"
          :content="`${localization('Print profile')}`"
          placement="top-start"
        >
          <el-button
            type="success"
            @click="printprofilePage"
            class="mb-2 mr-4 edit-btn print-btn"
            ><i class="fa fa-print"></i>
          </el-button>
        </el-tooltip>

        <el-tooltip
          class=" mb-2 mr-4 edit-btn print-btn main_tool_tip"
          :content="`${localization('Share your profile')}`"
          placement="top-start"
        >
          <el-button
            type="success"
            @click="open_share_profile_dialog"
            class="mb-2 mr-4 edit-btn print-btn"
            ><i class="fa fa-share-alt"></i>
          </el-button>
        </el-tooltip>
        <!-- //? for share profile page  -->
      </div>
      <!-- End Icons Wrapper  -->

      <!-- //start Score Cards  -->
      <!--// start  Medium & Small Screens  -->
      <div
        class="row my-4 d-lg-none main-card-print_small plc_hide_while_printing"
      >
        <div class="col-sm-12 d-flex justify-content-center flex-column">
          <!-- Sequence  -->
          <div class="when_joel_mobile">
            <div class="d-flex align-items-center">
              <p class="label sequence">
                {{ userScore.sequence != null ? userScore.sequence : 0 }}
              </p>
              <span style="font-size: 1.3rem; text-transform: capitalize">{{
                localization("Sequence")
              }}</span>
            </div>
            <div class="hello_text">
              <span class="d-block text-uppercase ti">{{
                localization("SEQUENTIAL LEARNING PATTERNS")
              }}</span>
              <span class="d-block te">{{
                localization("Organizes life")
              }}</span>
              <span class="d-block te">{{ localization("Makes Lists") }}</span>
              <span class="d-block te">{{
                localization("Lives by schedules")
              }}</span>
              <span class="d-block te">{{
                localization("Makes extensive plans")
              }}</span>
            </div>
          </div>
          <div class="when_joel_mobile">
            <div class="d-flex align-items-center">
              <p class="label precision">
                {{ userScore.precision != null ? userScore.precision : 0 }}
              </p>
              <span style="font-size: 1.3rem; text-transform: capitalize">{{
                localization("precision")
              }}</span>
            </div>
            <div class="hello_text">
              <span class="d-block text-uppercase ti">{{
                localization("PRECISION LEARNING PATTERNS")
              }}</span>
              <span class="d-block te">{{
                localization("Relishes information")
              }}</span>
              <span class="d-block te">{{ localization("Seeks facts") }}</span>
              <span class="d-block te">{{
                localization("Must be right")
              }}</span>
              <span class="d-block te">{{
                localization("Provides detailed responses")
              }}</span>
            </div>
          </div>
          <!-- Technical Reasoning  -->
          <div class="when_joel_mobile">
            <div class="d-flex align-items-center">
              <p class="label technical_reasoning">
                {{
                  userScore.technical_reasoning != null
                    ? userScore.technical_reasoning
                    : 0
                }}
              </p>
              <span style="font-size: 1.3rem">{{
                localization("Technical Reasoning")
              }}</span>
            </div>
            <div class="hello_text">
              <span class="d-block text-uppercase ti">{{
                localization("TECHNICAL LEARNING PATTERNS")
              }}</span>
              <span class="d-block te">{{
                localization("Solves problems")
              }}</span>
              <span class="d-block te">{{
                localization("Uses few words")
              }}</span>
              <span class="d-block te">{{
                localization("Looks for relevance and practicality")
              }}</span>
              <span class="d-block te">{{ localization("Works alone") }}</span>
            </div>
          </div>
          <!-- Confluence  -->
          <div class="when_joel_mobile">
            <div class="d-flex align-items-center">
              <p class="label confluence">
                {{ userScore.confluence != null ? userScore.confluence : 0 }}
              </p>
              <span style="font-size: 1.3rem; text-transform: capitalize">{{
                localization("Confluence")
              }}</span>
            </div>
            <div class="hello_text">
              <span class="d-block text-uppercase ti">{{
                localization("CONFLUENCE LEARNING PATTERNS")
              }}</span>
              <span class="d-block te">{{ localization("Takes risks") }}</span>
              <span class="d-block te">{{
                localization("Sees the big picture")
              }}</span>
              <span class="d-block te">{{
                localization("Embraces innovation")
              }}</span>
              <span class="d-block te">{{
                localization("Initiates change")
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!--// end Medium & Small Screens  -->
      <!--  end Score Cards  -->

      <!-- // Start Large Screens  -->
      <div
        class="row my-4 d-none d-lg-block main-card-print plc_hide_while_printing"
      >
        <div
          class="col-sm-12 px-0 px-lg-3 d-flex align-items-center the_patterns_with_new_theme"
        >
          <!-- Start Sequence  -->
          <div style="min-width: 25%;">
            <card
              :height="`${userScore.sequence * 2.5 + 120}px`"
              style="
              background-color: #39c;
              border-radius: 0;
              width: 100%;
              max-height: 230px;
              min-height: 120px;
            "
              class="my-auto score"
            >
              <div class="d-flex flex-column my-auto">
                <!-- score title text -->
                <!-- Medium & larger screens text   -->
                <p
                  class="mt-1 text-center text-white mb-0"
                  style="font-size: 2rem"
                >
                  {{ localization("Sequence") }}
                </p>
                <div
                  class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                "
                >
                  <!-- First Letter  -->
                  <p class="letter mb-0 text-white">
                    {{ localization("Sequence shortcut") }}
                  </p>
                  <!-- Score Number  -->

                  <!-- Large Screens  -->
                  <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 50 is min font value -->
                  <p
                    class="number mb-0 text-white"
                    :style="{
                      fontSize: (userScore.sequence / 28) * 25 + 65 + 'px'
                    }"
                  >
                    {{ userScore.sequence != null ? userScore.sequence : 0 }}
                  </p>
                </div>
              </div>
            </card>
            <div class="new_theme_pattern_content">
              <div class="main_content_container with_sequence">
                <span class="d-block text-uppercase ti">{{
                  localization("SEQUENTIAL LEARNING PATTERNS")
                }}</span>
                <span class="d-block te">{{
                  localization("Organizes life")
                }}</span>
                <span class="d-block te">{{
                  localization("Makes Lists")
                }}</span>
                <span class="d-block te">{{
                  localization("Lives by schedules")
                }}</span>
                <span class="d-block te">{{
                  localization("Makes extensive plans")
                }}</span>
              </div>
            </div>
          </div>
          <!-- End Sequence  -->

          <!-- Start Precision   -->
          <div style="min-width: 25%;">
            <card
              :height="`${userScore.precision * 2.5 + 120}px`"
              style="
              border-radius: 0;
              min-width: 25%;
              background-color: #3c3;
              max-height: 230px;
              min-height: 120px;
            "
              class="my-auto score"
            >
              <div class="d-flex flex-column my-auto">
                <!-- score title text -->

                <!-- Medium & larger screens text   -->
                <p
                  class="mt-1 text-center text-white mb-0"
                  style="font-size: 2rem"
                >
                  {{ localization("precision") }}
                </p>

                <div
                  class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                "
                >
                  <!-- First Letter  -->
                  <p class="letter mb-0 text-white">
                    {{ localization("precision shortcut") }}
                  </p>
                  <!-- Score Number  -->
                  <!-- Large Screens  -->
                  <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                  <p
                    class="number mb-0 text-white"
                    :style="{
                      fontSize: (userScore.precision / 28) * 25 + 65 + 'px'
                    }"
                  >
                    {{ userScore.precision != null ? userScore.precision : 0 }}
                  </p>
                </div>
              </div>
            </card>
            <div class="new_theme_pattern_content">
              <div class="main_content_container with_precision">
                <span class="d-block text-uppercase ti">{{
                  localization("PRECISION LEARNING PATTERNS")
                }}</span>
                <span class="d-block te">{{
                  localization("Relishes information")
                }}</span>
                <span class="d-block te">{{
                  localization("Seeks facts")
                }}</span>
                <span class="d-block te">{{
                  localization("Must be right")
                }}</span>
                <span class="d-block te">{{
                  localization("Provides detailed responses")
                }}</span>
              </div>
            </div>
          </div>
          <!-- End Precision   -->
          <!-- Start Technical Reasoning  -->
          <div style="min-width: 25%;">
            <!-- border: 1px solid #ccc; -->
            <card
              :height="`${userScore.technical_reasoning * 2.5 + 120}px`"
              style="
                border-radius: 0;
                min-width: 25%;
                background-color: #f93;
                max-height: 230px;
                min-height: 120px;
              "
              class="my-auto score"
            >
              <div class="d-flex flex-column my-auto">
                <!-- score title text -->
                <!-- Medium & larger screens text   -->
                <p
                  class="mt-1 text-center text-white mb-0"
                  style="font-size: 1.6rem"
                >
                  {{ localization("Technical Reasoning") }}
                </p>
                <!--
                  the wrap befor in this cause of
                  flex-column flex-md-row
                 -->
                <div
                  class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around

                    tr_printable
                  "
                >
                  <!-- First Letter  -->
                  <p class="letter mb-0 text-white">
                    {{ localization("Technical Reasoning shortcut") }}
                  </p>
                  <!-- Score Number  -->
                  <!-- Large Screens  -->
                  <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                  <p
                    class="number mb-0 text-white"
                    :style="{
                      fontSize:
                        (userScore.technical_reasoning / 28) * 25 + 65 + 'px'
                    }"
                  >
                    {{
                      userScore.technical_reasoning != null
                        ? userScore.technical_reasoning
                        : 0
                    }}
                  </p>
                </div>
              </div>
            </card>
            <div class="new_theme_pattern_content">
              <div class="main_content_container with_technical_reasoning">
                <span class="d-block text-uppercase ti">{{
                  localization("TECHNICAL LEARNING PATTERNS")
                }}</span>
                <span class="d-block te">{{
                  localization("Solves problems")
                }}</span>
                <span class="d-block te">{{
                  localization("Uses few words")
                }}</span>
                <span class="d-block te">{{
                  localization("Looks for relevance and practicality")
                }}</span>
                <span class="d-block te">{{
                  localization("Works alone")
                }}</span>
              </div>
            </div>
          </div>
          <!-- End Technical Reasoning  -->
          <!--Start Confluence  -->
          <div style="min-width: 25%;">
            <card
              :height="`${userScore.confluence * 2.5 + 120}px`"
              style="
                    border-radius: 0;

                    min-width: 25%;
                    background-color: #c36;
                    max-height: 230px;
                    min-height: 120px;
                  "
              class="my-auto score"
            >
              <div class="d-flex flex-column my-auto">
                <!-- score title text -->
                <!-- Medium & larger screens text   -->
                <p
                  class="mt-1 text-center text-white mb-0"
                  style="font-size: 2rem"
                >
                  {{ localization("Confluence") }}
                </p>

                <div
                  class="
                        d-flex
                        align-items-center align-items-xxl-start
                        justify-content-center justify-content-lg-around
                      "
                >
                  <!-- First Letter  -->
                  <p class="letter mb-0 text-white">
                    {{ localization("Confluence shortcut") }}
                  </p>
                  <!-- Score Number  -->
                  <!-- Large Screens  -->
                  <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                  <p
                    class="number mb-0 text-white"
                    :style="{
                      fontSize: (userScore.confluence / 28) * 25 + 65 + 'px'
                    }"
                  >
                    {{
                      userScore.confluence != null ? userScore.confluence : 0
                    }}
                  </p>
                </div>
              </div>
            </card>
            <div class="new_theme_pattern_content">
              <div class="main_content_container with_confluence">
                <span class="d-block text-uppercase ti">{{
                  localization("CONFLUENCE LEARNING PATTERNS")
                }}</span>
                <span class="d-block te">{{
                  localization("Takes risks")
                }}</span>
                <span class="d-block te">{{
                  localization("Sees the big picture")
                }}</span>
                <span class="d-block te">{{
                  localization("Embraces innovation")
                }}</span>
                <span class="d-block te">{{
                  localization("Initiates change")
                }}</span>
              </div>
            </div>
          </div>
          <!--End Confluence  -->
        </div>

        <!-- Start New Section  -->
        <div class="wrapper_for_use_first col-sm-12 mt-5 px-0 px-lg-3">
          <div class="main_co">
            <div class="a_one with_title">
              <span class="text-uppercase">{{
                localization("DEGREE OF USE")
              }}</span>
              <span>:</span>
            </div>
            <div class="a_one ">
              <span class="text-capitalize">{{
                localization("Use First")
              }}</span>
              <span>:</span>
              <span class="text-uppercase">35 - 25</span>
            </div>
            <div class="a_one a_two">
              <span class="text-capitalize">{{
                localization("As Needed")
              }}</span>
              <span>:</span>
              <span class="text-uppercase">24 - 18</span>
            </div>
            <div class="a_one ">
              <span class="text-capitalize">{{ localization("Avoid") }}</span>
              <span>:</span>
              <span class="text-uppercase">17 - 7</span>
            </div>
          </div>
        </div>
      </div>
      <!-- //  Large Screens  -->

      <!-- //// start accordion -->
      <div class="expand-panel">
        <card class="the_strategy_card">
          <template slot="header">
            <h3
              class="card-title hide_for_share_pdf_one personalize_title plc_hide_while_printing"
              :class="{ is_rtl: isRtl() }"
            >
              {{ localization("Personalize Your Profile") }}
            </h3>
          </template>
          <div class="main_cards_container_lci plc_hide_while_printing">
            <div class="row" v-if="lciCardsContent">
              <div
                class="col-lg-4 col-md-6 col-sm-12 every_lci_card mb-3"
                v-if="lciCardsContent[0].body != null"
              >
                <div class="icons_text_wrapper">
                  <div class="img_icons">
                    <img
                      class="img"
                      src="@/assets/images/icons/form.png"
                      alt="add"
                      draggable="false"
                    />
                  </div>
                  <div>
                    <span v-html="lciCardsContent[0].body"></span>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4 col-md-6 col-sm-12 every_lci_card mb-3"
                v-if="lciCardsContent[1].body != null"
              >
                <div class="icons_text_wrapper">
                  <div class="img_icons">
                    <img
                      class="img"
                      src="@/assets/images/icons/add.png"
                      alt="add"
                      draggable="false"
                    />
                  </div>
                  <div>
                    <span v-html="lciCardsContent[1].body"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            class="hide_for_share_pdf"
            :class="{ main_youtube_html_render: isRtl() }"
            v-if="profileInstructions"
            v-html="profileInstructions"
          ></div> -->
          <!-- //! start  collapse  -->

          <el-collapse
            class="mt-5 customization_for_collapse"
            v-model="panel"
            multiple
            v-if="$store.getters['user/attrs'] && this.auth().id === user.id"
          >
            <div
              class="child"
              v-for="(atrr, i) in $store.getters['user/attrs']"
              :key="i"
            >
              <el-collapse-item :name="`${i}`" class="">
                <!-- //! start  collapse title -->
                <template #title>
                  <div
                    class=" main_collapse_title_wrapper"
                    :class="{ is_rtl: isRtl() }"
                    style="width: 100%"
                  >
                    <div>
                      <h4 class="every_collapse_title">
                        <i
                          :class="`fa fa-star mr-1 ${i}`"
                          :style="`color: ${getProgressColor(i)}`"
                        ></i>
                        {{ localization(i) }}
                      </h4>
                    </div>

                    <span class="">
                      <button
                        class="btn btn-social btn-round every_collapse_button"
                        :style="
                          `border-color:${getProgressColor(
                            i
                          )};  background: ${getProgressColor(i)}`
                        "
                      >
                        {{ getProgressValue(i) }}
                      </button>
                      <!-- //? add icon  -->
                      <a
                        v-tooltip.top-center="
                          `${localization('Add statement')}`
                        "
                        @click="open_new_item_dialog(i, atrr[0].type)"
                        class="btn btn-default btn-link btn-md"
                      >
                        <i
                          :class="`fa fa-plus`"
                          class="add_new_checked_item"
                          :style="`background-color: ${getProgressColor(i)}`"
                        ></i>
                      </a>
                    </span>
                  </div>
                </template>
                <!-- //! end  collapse title -->
                <div>
                  <el-collapse class="pt-3 pl-lg-4">
                    <span
                      class="span_attr_type d-block"
                      :class="{ 'text-right': isRtl() }"
                      >{{ localization(atrr[0].type) }}</span
                    >
                    <l-alert
                      :style="
                        `opacity:0.8;background-color : ${getProgressColor(i)}`
                      "
                      class="mb3 mt-2 alert_for_collapse"
                      :class="{ 'text-right': isRtl() }"
                    >
                      <span>
                        <b> {{ localization(atrr[0].type) }} - </b>
                        {{ getHint(i, atrr[0].type) }}
                      </span>
                    </l-alert>

                    <div
                      class="child text_to_speech_container my-2"
                      v-for="(item, i) in atrr"
                      :key="i"
                      :class="{ 'flex-row-reverse': isRtl() }"
                    >
                      <el-checkbox-group
                        class=""
                        v-model="selected"
                        v-if="item"
                      >
                        <el-checkbox
                          @change="select"
                          :label="item.id"
                          :class="{
                            'd-none': item.title < 1,
                            'flex-row-reverse': isRtl(),
                            'text-right': isRtl()
                          }"
                        >
                          {{ item.title }}
                        </el-checkbox>
                      </el-checkbox-group>
                      <!-- //? start text to speech  -->
                      <audio controls id="audio" class="d-none">
                        <source id="audio-source" type="audio/mp3" />
                      </audio>
                      <div class="icons_speech_container">
                        <i
                          v-if="item.user_id != null"
                          @click="delete_statement(item.id)"
                          v-tooltip.top-center="
                            `${localization('Delete statement')}`
                          "
                          class="fa fa-times icons_style speech delete"
                          style="cursor: pointer"
                          :class="{ 'd-none': item.title < 1 }"
                        ></i>
                        <i
                          v-tooltip.top-center="
                            `${localization('Read out loud')}`
                          "
                          @click="text_speech_audio(item.title)"
                          class="fa fa-volume-up icons_style speech"
                          style="cursor: pointer"
                          :class="{ 'd-none': item.title < 1 }"
                        ></i>
                      </div>

                      <!-- //? end text to speech @click="text_speech_audio(item.title)"  -->
                    </div>
                  </el-collapse>
                </div>
              </el-collapse-item>
            </div>
          </el-collapse>
          <!-- // End  collapse  -->
        </card>
      </div>
      <!-- // End accordion -->
      <!-- Start Icons Wrapper  -->
      <div
        class="row d-flex justify-content-end plc_hide_while_printing"
        v-if="this.auth().id === user.id || hasPermission('access_users')"
      >
        <el-tooltip
          class=" mb-2 mr-4 edit-btn print-btn main_tool_tip"
          :content="`${localization('Edit score')}`"
          placement="top-start"
        >
          <el-button
            v-if="hasPermission('access_users') || hasPermission('edit_users')"
            @click="open_score_dialog"
            type="success"
            class="mb-2 mr-4 edit-btn edit_score_button"
            ><i class="fa fa-edit"></i>
          </el-button>
        </el-tooltip>

        <el-tooltip
          class=" mb-2 mr-4 edit-btn print-btn main_tool_tip"
          :content="`${localization('Print profile')}`"
          placement="top-start"
        >
          <el-button
            type="success"
            @click="printprofilePage"
            class="mb-2 mr-4 edit-btn print-btn"
            ><i class="fa fa-print"></i>
          </el-button>
        </el-tooltip>

        <el-tooltip
          class=" mb-2 mr-4 edit-btn print-btn main_tool_tip"
          :content="`${localization('Share your profile')}`"
          placement="top-start"
        >
          <el-button
            type="success"
            @click="open_share_profile_dialog"
            class="mb-2 mr-4 edit-btn print-btn"
            ><i class="fa fa-share-alt"></i>
          </el-button>
        </el-tooltip>
      </div>
      <!-- End Icons Wrapper  -->
      <!-- Start Cms Cards And Localization -->
      <div class=" the_bottom_lci_cards_for_print">
        <div class="every_lci_card" v-if="lciCardsPlpBox[0].body != null">
          <div class=" icons_text_wrapper_2">
            <router-link to="/tasks" class="icons_text_wrapper w-100">
              <div class="img_icons">
                <!-- src="@/assets/images/icons/strategy.png" -->
                <img
                  class="img"
                  src="@/assets/images/icons/target-1.png"
                  alt="add"
                  draggable="false"
                />
              </div>
              <div>
                <span v-html="lciCardsPlpBox[0].body"></span>
              </div>
            </router-link>
          </div>
        </div>
        <!-- card two -->
        <div class="every_lci_card">
          <div
            class=" icons_text_wrapper_2"
            role="button"
            @click="printprofilePage"
          >
            <div class="icons_text_wrapper">
              <div class="img_icons">
                <i class="fa fa-print icon_share"></i>
              </div>
              <div>
                {{ localization("Print your profile and share it with your teacher, instructor, classmates, colleagues, or even for a job inerview.") }}
              </div>
            </div>
          </div>
        </div>
        <!-- card three -->
        <div class="every_lci_card">
          <div
            class=" icons_text_wrapper_2"
            role="button"
            @click="open_share_profile_dialog"
          >
            <div class="icons_text_wrapper">
              <div class="img_icons">
                <i class="fa fa-share-alt icon_share"></i>
              </div>
              <div>
                {{ localization("Digitally share your profile with your teacher, instructor, classmates, or colleagues.") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <!--End main card for user information  -->

    <!-- //start  add new item dialog  -->
    <el-dialog
      :title="`${localization('Add statement')}`"
      :visible.sync="add_new_items"
      class="user_score_dialog w-100"
      top="4vh"
      :class="{ add_custom_statement_with_rtl: isRtl() }"
    >
      <el-form
        ref="addStatementForm"
        class="p-2"
        :rules="rules"
        :model="payload"
        label-position="top"
      >
        <el-form-item
          :label="`${localization('Statement')}`"
          style="width: 100%"
          prop="title"
        >
          <el-input
            type="text"
            :placeholder="`${localization('Type a statement')}`"
            v-model="payload.title"
            style="width: 100%"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialog_footer">
        <el-button @click="close_new_item_dialog">{{
          localization("Cancel")
        }}</el-button>
        <el-button class="handle_save_button_dialog" @click="add_new_item">{{
          localization("Save")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- // end add new item dialog  -->

    <!-- Start dialog for edit user sore  -->
    <el-dialog
      :title="`${localization('Edit score')}`"
      :visible.sync="score_dialog"
      class="user_score_dialog w-100"
      top="15px"
      :class="{ 'text-right': isRtl() }"
    >
      <el-form
        :model="score"
        class="p-2"
        label-position="top"
        @submit.prevent="save"
      >
        <el-form-item :label="`${localization('Sequence')}`">
          <el-input-number
            :min="7"
            :max="35"
            v-model="score.sequence"
            :placeholder="`${localization('Sequence')}`"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
        <!-- percision  -->
        <el-form-item :label="`${localization('precision')}`">
          <el-input-number
            :min="7"
            :max="35"
            v-model="score.precision"
            :placeholder="`${localization('precision')}`"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
        <!-- technical reasoning  -->
        <el-form-item :label="`${localization('Technical Reasoning')}`">
          <el-input-number
            :min="7"
            :max="35"
            v-model="score.technical_reasoning"
            :placeholder="`${localization('Technical Reasoning')}`"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
        <!-- confluence  -->
        <el-form-item :label="`${localization('Confluence')}`">
          <el-input-number
            :min="7"
            :max="35"
            v-model="score.confluence"
            :placeholder="`${localization('Confluence')}`"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialog_footer">
        <el-button @click="score_dialog = false">{{
          localization("Cancel")
        }}</el-button>
        <el-button type="primary" @click="save">{{
          localization("Save")
        }}</el-button>
      </div>
    </el-dialog>
    <!--  End dialog for edit user sore  -->

    <!--Start dialog for share profile  -->
    <el-dialog
      :title="`${localization('Share your profile')}`"
      :visible.sync="share_profile_dialog"
      top="4vh"
      :class="{ share_profile_with_rtl: isRtl() }"
    >
      <el-form
        :model="share_profile_data"
        :rules="share_profile_rules"
        ref="share_profile_data"
      >
        <el-form-item
          :label="`${localization('Email')}`"
          prop="email"
          :rules="[
            {
              required: true,
              message: 'Please input email address',
              trigger: 'blur'
            },
            {
              type: 'email',
              message: 'Please input correct email address',
              trigger: ['blur', 'change']
            }
          ]"
        >
          <el-input
            v-model="share_profile_data.email"
            autocomplete="off"
            style="width: 100%"
            :placeholder="`${localization('Type Email')}`"
            type="email"
          ></el-input>
        </el-form-item>
      </el-form>

      <div
        slot="footer"
        class="
            dialog-footer
            main_dialog_footer
          "
      >
        <el-button
          @click="close_share_profile_dialog"
          class="for_c_buttons text-center text-capitalize"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          type="primary"
          class="
              text-center
              for_c_btns
              text-capitalize

            "
          @click="share_profile_page"
          >{{ localization("Send") }}</el-button
        >
      </div>
    </el-dialog>
    <!-- End dialog for share profile -->
  </div>
</template>

<script>
import Info from "../../../../components/Cards/Info.vue";
import LAlert from "src/components/Alert";
import Swal from "sweetalert2";
import moment from "moment";
// import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      locale_lang: this.$i18n.locale,
      share_profile_dialog: false,
      share_profile_data: {},
      score: {},
      score_dialog: false,
      show_expiration_timer: true,

      panel: [`Precision`, `Sequence`, "Technical Reasoning", "Confluence"],
      add_new_items: false,
      new_item: "",
      payload: {
        title: "",
        type: "",
        pattern: ""
      },
      rules: {
        title: [
          {
            required: true,
            // message: `${this.which_lang}`,
            message: "Please this field is required",
            trigger: "change"
          }
        ]
      },
      share_profile_rules: {
        email: [
          {
            required: true,
            type: "email",
            message: [
              "Please input email address",
              "Please input correct email address"
            ],
            trigger: ["blur", "change"]
          }
        ]
      },
      edit_score_rules: {
        sequence: [
          {
            required: true,
            message: ["Please this field is required"],
            trigger: ["change"]
          }
        ]
      },
      cards_steps: [
        {
          id: 0,
          title: "card one",
          text:
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga, dolores sit amet consectetur,."
        },
        {
          id: 1,
          title: "card two",
          text:
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga, dolores sit amet consectetur,."
        },
        {
          id: 2,
          title: "card three",
          text:
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga, dolores sit amet consectetur,."
        },
        {
          id: 3,
          title: "card four",
          text:
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga, dolores sit amet consectetur,."
        }
      ],
      select_attribute: []
    };
  },

  props: {
    selected: []
  },
  components: {
    Info,
    LAlert
  },
  mounted() {
    this.$store.dispatch("user/handlerProfileCards");
    this.$store.dispatch("user/handlerLciPlpBoxCards");
    // this.select_attribute = this.checkedAttributes;
  },

  computed: {
    // attributesData() {
    //   return this.$store.getters["user/attrs"];
    // },
    checkedAttributes() {
      let attributesData = this.$store.getters["user/attrs"];
      let objItems = Object.values(attributesData);
      let selectItems = [];
      objItems.forEach(element => {
        element.forEach(el => {
          if (this.selected.includes(el.id)) {
            selectItems.push(el.id);
          }
        });
      });

      return selectItems;

      // return this.selected;
      // select_attribute
      // selected
    },
    upload_src: function() {
      return `${process.env.VUE_APP_BASE_URL}/users/update_photo/${this.user.id}`;
    },
    user: function() {
      return this.$store.state.user.user;
    },
    image: function() {
      if (this.user.image) {
        return `${process.env.VUE_APP_BASE_URL}/users/photo/${this.user.image}`;
      }
      return "/static/img/lml-swerl.jpg";
    },
    profileInstructions() {
      return this.$store.getters["user/taskInstructions"]
        ? this.$store.getters["user/taskInstructions"][1].body
        : "";
    },
    remainingTimeToExpire() {
      return new Date(new Date(this.user.exp_time) - new Date());
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    lciCardsContent() {
      return this.$store.getters["user/get_profile_cards"];
    },
    lciCardsPlpBox() {
      return this.$store.getters["user/get_lci_plp_box_cards"];
    }
  },
  methods: {
    test_send_email() {
      let email = "mohamedalmarag97@gmail.com";
      let subject = "Test";
      let emailBody = "Hi Sample,";
      document.location =
        "mailto:" + email + "?subject=" + subject + "&body=" + emailBody;
    },
    //// share profile page
    share_profile_page() {
      this.share_profile_dialog = true;
      this.$refs["share_profile_data"].validate(valid => {
        if (valid) {
          let cards = document.querySelector(".card-user");
          // let expandPanel = document.querySelector(".expand-panel");
          let title = document.querySelector(".hide_for_share_pdf_one");
          // let youtube = document.querySelector(".hide_for_share_pdf");
          title.style.display = "none";
          // expandPanel.style.display = "none";
          // youtube.style.display = "none";
          html2canvas(cards, {
            logging: true,
            letterRendering: 1,
            allowTaint: true,
            useCORS: true
          }).then(canvas => {
            title.style.display = "block";
            // youtube.style.display = "block";
            // const base64 = canvas.toDataURL("image/png");
            // let anchor = document.createElement("a");
            // anchor.setAttribute("href", base64);
            const payload = new FormData();
            canvas.toBlob(blob => {
              let img_to_blob = new File([blob], "profile.png", {
                type: "image/png"
              });
              payload.append("photo", img_to_blob);

              payload.append("email", this.share_profile_data.email);
              this.$store.dispatch("user/sharing_profile_task", payload);
              Swal.fire({
                title: "",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                icon: "success",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false
              });
              this.share_profile_dialog = false;
              this.share_profile_data.email = "";
            }, "image/png");
          });
        }
      });
    },
    close_share_profile_dialog() {
      this.share_profile_dialog = false;
      this.share_profile_data.email = "";
    },
    open_share_profile_dialog() {
      // this.share_profile_data.email = "";
      this.share_profile_dialog = true;
    },
    // // text_speech_audio
    text_speech_audio(any_text) {
      this.$store
        .dispatch("user/get_text_to_speech_audio", { text: any_text })
        .then(res => {
          let audioBlob = new Blob([res], { type: "audio/mpeg" });
          let audioURL = URL.createObjectURL(audioBlob);
          let audio = document.getElementById("audio");
          var source = document.getElementById("audio-source");
          source.src = audioURL;
          audio.load();
          audio.play();
        });
    },
    printprofilePage() {
      window.print();
    },
    getRmainTimeToExp() {
      return `${moment
        .duration(moment(this.user.exp_time) - moment(new Date()))
        .years()} years, ${moment
        .duration(moment(this.user.exp_time) - moment(new Date()))
        .months()} months, ${moment
        .duration(moment(this.user.exp_time) - moment(new Date()))
        .days()} days`;
    },
    select() {
      let attributesData = this.$store.getters["user/attrs"];
      let objItems = Object.values(attributesData);
      let selectItems = [];
      objItems.forEach(element => {
        element.forEach(el => {
          if (this.selected.includes(el.id)) {
            selectItems.push(el.id);
          }
        });
      });
      this.$emit("select_attribute", selectItems);
    },
    Refresh() {
      this.$store.dispatch("user/user", this.user.id);
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    getProgressValue(key) {
      switch (key) {
        case "Precision":
          return this.userScore.precision;
        case "Technical Reasoning":
          return this.userScore.technical_reasoning;
        case "Confluence":
          return this.userScore.confluence;
        case "Sequence":
          return this.userScore.sequence;
      }
    },
    getHint(key, type) {
      switch (type) {
        case "Avoid":
          if (key == "Sequence") {
            return `${this.localization("Since my scale score for Sequence is between 7-17, I avoid Sequence")}`;
          } else if (key == "Precision") {
            return `${this.localization("Since my scale score for Precision is between 7-17, I avoid Precision")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("Since my scale score for Technical Reasoning is between 7-17, I avoid Technical Reasoning")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Since my scale score for Confluence is between 7-17, I avoid Confluence")}`;
          }
        // return `${this.localization("Scale Score")} ${this.localization(
        //   key
        // )} ${this.localization("First Score Degree")} ${this.localization(
        //   key
        // )}`;
        case "As Needed":
          // case "As Needed":
          if (key == "Sequence") {
            return `${this.localization("Since my scale score for Sequence is between 18-24, I use Sequence as needed.")}`;
          } else if (key == "Precision") {
            return `${this.localization("Since my scale score for Precision is between 18-24, I use Precision as needed.")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("Since my scale score for Technical Reasoning is between 18-24, I use Technical Reasoning as needed.")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Since my scale score for Confluence is between 18-24, I use Confluence as needed.")}`;
          }
        // return `${this.localization("Scale Score")} ${this.localization(
        //   key
        // )} ${this.localization("Second Score Degree")} ${this.localization(
        //   key
        // )} ${this.localization("as needed")}`;

        case `Use First`:
          if (key == "Sequence") {
            return `${this.localization("Since my scale score for Sequence is between 25-35, I use Sequence first.")}`;
          } else if (key == "Precision") {
            return `${this.localization("Since my scale score for Precision is between 25-35, I use Precision first.")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("Since my scale score for Technical Reasoning is between 25-35, I use Technical Reasoning first.")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Since my scale score for Confluence is between 25-35, I use Confluence first.")}`;
          }
        // return `${this.localization("Scale Score")} ${this.localization(
        //   key
        // )} ${this.localization("Third Score Degree")} ${this.localization(
        //   key
        // )} ${this.localization("first")}`;
        case "Sequence":
          // case `${this.localization("Sequence")}`:
          return `${this.localization("Hey, you have enough Sequence to complete this task. But you might want to check out these strategies to keep your Sequence pattern from taking over.")}`;
      }
    },

    save() {
      // this.$refs["edit_score_rules"].validate(valid => {
      //   if (valid) {
      this.$store
        .dispatch("user/save", {
          id: `/${this.user.id}`,
          query: this.score
        })
        .then(_ => {
          this.Refresh({});
          this.score_dialog = false;
          Swal.fire({
            title: "",
            text: `${this.localization("Done")}`,
            confirmButtonText: `${this.localization("Ok")}`,
            icon: "success",
            confirmButtonClass: "btn btn-success ",
            buttonsStyling: false
          });
        });
      //   }
      // });
    },
    open_score_dialog() {
      this.score = { ...this.userScore };
      this.score_dialog = true;
    },
    add_new_item() {
      this.$refs["addStatementForm"].validate(valid => {
        if (valid) {
          this.add_new_items = !this.add_new_items;
          this.$store
            .dispatch("user/add_custom_attribute", this.payload)
            .then(() => {
              this.Refresh();
              this.payload.title = "";
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        }
      });
    },
    open_new_item_dialog(pattern, type) {
      this.add_new_items = !this.add_new_items;
      this.payload.pattern = pattern;
      this.payload.type = type;
    },
    close_new_item_dialog() {
      this.add_new_items = !this.add_new_items;
      this.payload.pattern = null;
      this.payload.type = null;
    },
    //// remove statement
    delete_statement(id) {
      // delete_custom_attribute_profile
      Swal.fire({
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false,
        icon: "error"
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/delete_custom_attribute_profile", id)
            .then(_ => {
              this.Refresh(null);
              Swal.fire({
                title: "",
                icon: "success",
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false,
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`
              });
            });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.when_joel_mobile {
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
}
$just_theme_color: #00563f;
.link_to_use_ur_patterns {
  margin-bottom: 20px;
  .linkkk {
    background-color: #00563f;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 4px;
  }
}
.which_user_type {
  width: fit-content;
  margin: auto;
  transition: 0.5s all;
  .nn {
    // background-color: $just_theme_color;
    color: #fff;
    padding: 7px 10px;
    position: relative;
    transition: 0.5s all;
    &::before {
      position: absolute;
      content: "";
      background: #91b9ae;
      width: 55px;
      height: 10px;
      bottom: 8px;
      right: -38px;
      display: none;
      // opacity: 0;
      transition: 0.5s all;
      @media (min-width: 320px) and (max-width: 1150px) {
        display: none;
      }
    }
    .classification {
      position: relative;
      z-index: 6;
      padding: 0 10px;
      min-width: 180px;
      // padding: 7px 10px;
      border-radius: 12px;
      background-color: $just_theme_color;
      height: 45px;
      display: block;
      line-height: 2;
    }
  }
  .another_content {
    display: none;
    // opacity: 0;
    position: absolute;
    bottom: 8px;
    width: 358px;
    background: #91b9ae;
    border-radius: 10px;
    padding: 10px;
    right: -360px;
    transition: 0.5s all;
    z-index: 9999999999;
    @media (min-width: 1150px) and (max-width: 1280px) {
      right: -290px;
      width: 294px;
    }
    @media (min-width: 320px) and (max-width: 1150px) {
      right: auto;
      left: -60px;
      bottom: 60px;

      &::before {
        position: absolute;
        content: "";
        background: #91b9ae;
        height: 10px;
        width: 10px;
        bottom: -8px;
        left: 50%;
      }
    }
    @media (min-width: 320px) and (max-width: 567px) {
      width: 323px;
    }
    .tit {
      font-size: 15px;
    }
    .tex {
      font-size: 13px;
    }
  }
  &:hover {
    .nn {
      &::before {
        display: block;
        // opacity: 1;
      }
    }
    .another_content {
      display: block !important;
      // opacity: 1;
    }
    @media (min-width: 320px) and (max-width: 1150px) {
      .nn {
        &::before {
          display: none;
          // opacity: 0;
        }
      }
    }
  }
  @media print {
    display: block !important;
  }
}
.main_auth_user_info_print {
  display: none;
  position: absolute;
  top: 380px;
  left: 140px;
  .n_name {
    font-size: 24px;
    font-weight: bold;
    text-transform: capitalize;
  }
  .n_email {
    font-size: 22px;
  }
  @media print {
    display: block !important;
  }
}
.is_that_we_printing {
  text-align: left;
  color: #555;
  display: none;
  .part_title {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .part_description {
    line-height: 1.7;
    font-size: 16px;
  }
  @media print {
    display: block !important;
  }
}
.author {
  overflow: inherit;
}
.main_cards_container_lci {
  // background: #00563f;
  .every_lci_card {
    .icons_text_wrapper {
      display: flex;
      align-items: center;
      gap: 15px;
      // background: #00563f;
      background-color: $just_theme_color;
      color: #fff;
      padding: 8px;
      border-radius: 10px;
      min-height: 170px;
    }
    .img_icons {
      .img {
        max-width: 50px;
      }
      .icon_share {
        font-size: 35px;
        width: 50px;
        max-width: 50px;
      }
    }
  }
}
// Start Style Last Cms Cards For Print
.the_bottom_lci_cards_for_print {
  display: flex;
  gap: 2%;
  @media (min-width: 320px) and (max-width: 991px) {
    flex-wrap: wrap;
  }
  @media print {
    flex-wrap: nowrap;
  }
  .every_lci_card {
    width: calc(98% / 3);
    @media (min-width: 320px) and (max-width: 991px) {
      margin-bottom: 20px;
    }
    .icons_text_wrapper {
      display: flex;
      align-items: center;
      gap: 15px;
      // background: #00563f;
      background-color: $just_theme_color;
      color: #fff;
      padding: 8px;
      border-radius: 10px;
      min-height: 170px;
    }
    .img_icons {
      .img {
        max-width: 50px;
      }
      .icon_share {
        font-size: 35px;
        width: 50px;
        max-width: 50px;
      }
    }
  }
}
// End Style Last Cms Cards For Print
.main_timeline_wrapper {
  // background-color: #777;
  margin-bottom: 50px;
  padding: 40px 10px;
  .steps_container {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 15px;
    .one_step_wrapper {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      position: relative;
      &::after {
        background-color: #e3e3e3;
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        width: 100%;
        height: 4px;
      }
      &.first_step {
        &::after {
          background-color: #11bfe3;
        }
        .step {
          background-color: #11bfe3;
          color: #fff;
        }
      }
      .step {
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #e3e3e3;
        box-shadow: 0 5px 10px rgba(#000, 0.11);
        z-index: 2;
        color: #bbb;
        text-align: center;
        line-height: 30px;
        display: block;

        &::before {
          position: absolute;
          content: "";
          left: 50%;
          transform: translate(-50%, -50%);
          bottom: -30px;
          border-width: 15px;
          border-color: transparent transparent #f5f5f5 transparent;
          border-style: solid;
        }
      }
    }
  }
  .all_steps_cards_container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    .every_card_step {
      background-color: #f5f5f5;
      padding: 25px 10px 0;
      min-height: 200px;
      text-align: center;
      border-radius: 5px;
      box-shadow: 0 5px 10px rgba(#000, 0.11);
      color: #777;
      transition: 0.3s;
      &:hover {
        background-color: #11bfe3;
        color: #fff;
      }
      h4 {
        margin: 0 0 15px;
        text-transform: capitalize;
      }
      p {
        margin: 0;
        line-height: 1.7;
      }
    }
  }
}
.expiration_timer_card {
  display: inline-block;
  top: 10rem;
  right: 1rem;
  background: linear-gradient(133deg, #222328, #504143e6);
  border-radius: 1rem;
}
.expiration_timer_card h4 {
  display: inline-block;
}

.expiration_timer_card p {
  font-size: 12px;
}

.timer {
  font-family: "Poppins", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 130%;
}

.close_expiration_timer {
  font-size: 1.3rem;
  width: 2rem;
  height: 2rem;
  font-weight: 700;
  top: -10px;
  right: -5px;
  color: #436174;
  box-shadow: #436174 0px 0px 6px 0px;
}

/* Join class room alert style  */

.close_join_class_room_alert {
  font-size: 1.3rem;
  width: 2rem;
  height: 2rem;
  font-weight: 700;
  top: -10px;
  left: -5px;
  color: #436174;
  box-shadow: #436174 0px 0px 6px 0px;
}
/* mobile score progress design */

::v-deep .precision {
  margin: 5px;
  display: inline;
  background-color: #3c3;
  @media print {
    margin: 50px;
    display: inline;
    background-color: #3c3;
  }
}

::v-deep .technical_reasoning {
  display: inline;
  margin: 5px;
  background-color: #f93;
}

::v-deep .sequence {
  display: inline;
  margin: 5px;
  background-color: #39c;
}

::v-deep .confluence {
  display: inline;
  margin: 5px;
  background-color: #c36;
}

.label {
  // border-radius: 50%;
  // margin: 0.5rem;
  // width: 5rem;
  // height: 5rem;
  // text-align: center;
  // font-size: 1.8rem;
  // line-height: 2.8;
  // color: white;
  border-radius: 50%;
  margin: 0.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1rem;
  line-height: 2.8;
  color: white;
}

.letter {
  font-size: 40px;
}
.tr_printable {
  // @media print {
  //   background-color: firebrick !important;
  //   flex-wrap: nowrap !important;
  //   display: flex;
  //   width: 100%;
  //   // font-size: 20px;
  // }
}
.border {
  border: 2px solid white !important;
}

.number {
  line-height: 1;
}

.card.score .card-body {
  padding: 0.7rem 0 !important;
}
// Print Style
::v-deep .card-header {
  @media print {
    display: none !important;
  }
}
::v-deep .text-muted {
  @media print {
    display: none !important;
  }
}
::v-deep .card-category {
  @media print {
    display: none !important;
  }
}
::v-deep .iframe-container {
  @media print {
    display: none !important;
  }
}
.main-card-print {
  @media print {
    display: block !important;
  }
}
.main-card-print_small {
  @media print {
    display: none !important;
  }
}

.card label {
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: unset;
}

.el-collapse {
  border-top: 1px solid #ebeef5;
  border-bottom: 0px;
  background: #fff;
  min-height: 300px;
  @media print {
    padding: 0 10px !important;
  }
}

@media print {
  // .expand-panel,
  .join_class_room,
  .fa-edit,
  .edit-btn,
  .hide-while-print {
    display: none;
  }
}
.print-btn {
  background-color: #39c;
  border-color: #39c;
  transition: 0.2s;
  &.item {
    background-color: #f93;
    background-color: #c36;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    color: #fff !important;
    // .link-extention {
    // }
  }
  &:hover {
    opacity: 0.8;
  }
  .edit_score_button {
    background-color: #3c3;
  }
}

.main_tool_tip {
  background-color: #39c;
  border-color: #39c;
  transition: 0.2s;
  &.edit_score_button {
    background-color: #3c3;
    border-color: #3c3;
  }
}

::v-deep .el-checkbox {
  display: flex;
}
::v-deep .el-checkbox__label {
  // word-break: break-all !important;
  word-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
  // padding-right: 10px;
  white-space: normal;
  font-size: 1.2rem;
  color: #000;
  line-height: 1;
  padding: 0 7px;
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1rem;
  }
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  // color: #9a9a9a;
}
::v-deep .el-checkbox__inner {
  border: 1px solid #000;
}
.span_attr_type {
  font-size: 1.2rem;
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1rem;
  }
}
.alert_for_collapse {
  font-size: 1.2rem;
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1rem;
  }
  @media print {
    font-size: 16px;
    margin: 0 !important;
  }
}
// ::v-deep .texst {
//   word-break: break-all !important;
//   word-wrap: break-word;
//   white-space: normal;
// }
// user score dialog for add score
::v-deep .el-dialog {
  @media (min-width: 320px) and (max-width: 480px) {
    width: 90% !important;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 70% !important;
  }
}

.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
}

//? start new style
.user_bg_img {
  height: 150px;
  .new_user_background {
    background-color: $just_theme_color;
    height: 100%;
    margin: auto;
  }
  // @media print {
  //   display: none;
  // }
}
//? collapse style
.main_collapse_title_wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  .every_collapse_title {
    font-size: 1.2rem;
    @media (min-width: 320px) and (max-width: 480px) {
      // font-size: 18px;
      font-size: 1rem;
    }
    @media (min-width: 320px) and (max-width: 400px) {
      // font-size: 11px;
      font-size: 1rem;
    }
  }
  .every_collapse_button {
    color: #fff;
    width: 50px;
    height: 50px;
    @media (min-width: 320px) and (max-width: 480px) {
      width: 36px;
      height: 36px;
    }
  }
  &.is_rtl {
    flex-direction: row-reverse;
    padding-right: 10px;
  }
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}
.sharing_icons_media {
  background: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  transition: 0.5s;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2), 0 0 5px -2px rgba(0, 0, 0, 0.1);
  transition: 0.3s all ease-in-out;
  border-radius: 5px;
  &:hover {
    background-color: #23ccef;
    color: #fff;
  }
}

//  todo text_to_speech_container
.text_to_speech_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  @media print {
    margin: 5px !important;
  }
}
.icons_speech_container {
  display: flex;
  gap: 10px;
  @media print {
    display: none !important;
  }
}
.icons_style {
  background-color: #eee;
  // box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
  font-size: 14px;
  width: 30px;
  height: 30px;
  // box-shadow: $simple-shadow;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  cursor: pointer;
  color: #777;
  transition: 0.3s;
  &.speech {
    &:hover {
      background-color: #11bfe3;
      color: #fff;
    }
  }
  &.delete {
    &:hover {
      background-color: #fb404b;
      color: #fff;
    }
  }
}

// Style For Arabic Language
// .alert_google {
//   &.is_rtl {
//     flex-direction: row-reverse;
//     .el-alert__icon.is-big {
//       color: red !important;
//     }
//   }
// }
.personalize_title {
  &.is_rtl {
    text-align: right;
  }
}

.wrapper_for_use_first {
  .main_co {
    background-color: #eee;
    display: flex;
    justify-content: center;
    gap: 10px;
    color: #7d7979;
    padding: 15px;
    .a_one {
      &.with_title {
        color: #555;
      }
    }
    .a_two {
      border-left: 3px solid #777;
      border-right: 3px solid #777;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.the_patterns_with_new_theme {
  position: relative;

  .new_theme_pattern_content {
    padding: 0 10px;
    .main_content_container {
      border-left: 2px solid;
      border-right: 2px solid;
      border-bottom: 2px solid;
      text-align: center;
      padding: 10px 5px;
      &.with_sequence {
        border-color: #39c;
      }
      &.with_precision {
        border-color: #3c3;
      }
      &.with_technical_reasoning {
        border-color: #f93;
      }
      &.with_confluence {
        border-color: #c36;
      }
      .ti {
        // color: #555;
        font-size: 15px;
      }
      .te {
        color: #777;
        font-size: 13px;
      }
    }
  }
}
.join_class_room {
  position: relative;
  z-index: 400;
}

// Start New Style For Print User Card
.plc_user_profile_for_container {
  @media print {
    zoom: 100%;
  }
  .main_profile_card_when_print_it {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 30px 0;
    display: none;
    @media print {
      display: block;
    }
    // Start user information and learn type
    .user_information_when_print {
      .the_info_one {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: center;
        .img {
          display: block;
          width: 110px;
          height: 110px;
          border-radius: 50%;
          border: 2px solid #000;
        }
        .email_name {
          font-size: 18px;
          &.email_too {
            color: #777;
          }
        }
      }
      .learner_type {
        text-align: inherit;
        color: #555;
        padding: 10px;
        .part_title {
          font-size: 17px;
          // margin-bottom: 10px;
        }
        .part_description {
          font-size: 14px;
        }
        @media print {
          display: block !important;
        }
      }
    }
    // End user information and learn type

    // Start User Score
    .user_pattern_scores {
      // padding: 0 10px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      .every_user_score {
        width: 24%;
        border: 2px solid;
        &.sequence_score {
          border-color: #39c;
          .word_and_score {
            background-color: #39c;
            .score {
              color: #39c;
            }
          }
        }
        &.precision_score {
          border-color: #3c3;
          .word_and_score {
            background-color: #3c3;
            .score {
              color: #3c3;
            }
          }
        }
        &.technical_reasoning_score {
          border-color: #f93;
          .word_and_score {
            background-color: #f93;
            .score {
              color: #f93;
            }
          }
        }
        &.confluence_score {
          border-color: #c36;
          .word_and_score {
            background-color: #c36;
            .score {
              color: #c36;
            }
          }
        }
        .word_and_score {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 10px;
          font-size: 18px;
          font-weight: bold;
          .word {
            color: #fff;
          }
          .score {
            background-color: #fff;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .descriptions_texts {
          text-align: center;
          padding: 5px;
          .main_description_title {
            font-size: 14px;
            color: #000;
          }
          .another_text {
            font-size: 13px;
            color: #777;
          }
        }
      }
    }
    // End User Score

    // Start Use Degree
    .use_degree_wrapper {
      background-color: #eee;
      display: flex;
      justify-content: center;
      gap: 10px;
      color: #7d7979;
      padding: 10px;
      margin-top: 10px;
      .a_one {
        &.with_title {
          color: #555;
        }
      }
      .a_two {
        border-left: 3px solid #777;
        border-right: 3px solid #777;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    // End Use Degree

    // Start Personalize Cards
    .personalize_cards_container {
      display: flex;
      gap: 2%;
      justify-content: space-between;
      margin: 10px 0;

      .every_lci_card {
        width: 49%;
        .icons_text_wrapper {
          display: flex;
          align-items: center;
          gap: 15px;
          background-color: $just_theme_color;
          color: #fff;
          padding: 8px;
          border-radius: 10px;
          min-height: 120px;
        }
        .img_icons {
          .img {
            max-width: 50px;
          }
          .icon_share {
            font-size: 35px;
            width: 50px;
            max-width: 50px;
          }
        }
      }
    }
    // End Personalize Cards
  }

  .the_strategy_card {
    @media (min-width: 991px) {
      padding: 1.5rem;
    }
    @media print {
      padding: 0 !important;
    }
  }
}
// End New Style For Print User Card

// The Main Profile Printing
@media print {
  .join_class_room,
  .fa-edit,
  .edit-btn,
  .hide-while-print {
    display: none;
  }
  .plc_hide_while_printing {
    display: none !important;
  }
}
::v-deep .el-collapse-item__content {
  @media print {
    padding-bottom: 0;
  }
}
// The Main Profile Printing
</style>

<style lang="scss">
$just_theme_color: #00563f;
// Start Style For Arabic Language
.alert_google {
  z-index: 400;
  &.is_rtl {
    flex-direction: row-reverse;
    // .el-alert__icon.is-big {
    //   margin-right: 20px;
    // }
    .el-alert__closebtn {
      left: 15px;
      right: auto;
    }
  }
}
.add_custom_statement_with_rtl {
  text-align: right;
  .el-input__inner {
    text-align: right;
  }
}
// End Style For Arabic Language
.main_student_container {
  @media print {
    // body {
    // zoom: 75%;
    // }
  }
  .el-collapse-item__header {
    padding: 2.5rem 0;
    @media print {
      // padding: 1.5rem 0;
    }
  }
  .card-user .card-body {
    overflow: hidden;
  }
  .customization_for_collapse {
    @media print {
      // background: #11bfe3;
      margin: 0 !important;
    }
  }
}
//? tooltip style
.el-tooltip__popper.is-dark {
  @media print {
    display: none !important;
  }
}
.add_new_checked_item {
  color: #fff;
  border-radius: 50%;
  margin-left: 10px;
  width: 20px !important;
  height: 20px !important;
  line-height: 22px;
  font-size: 12px;
  text-align: center;
}
.share_profile_with_rtl {
  text-align: right;
  .el-form-item__label {
    width: 100%;
  }
  .el-input__inner {
    text-align: right;
  }
}
.main_youtube_html_render {
  // background: #11bfe3;
  .text-muted {
    text-align: right;
  }
  .card-category {
    text-align: right;
    list-style: none;
    li {
      text-align: right;
      position: relative;
      padding-right: 17px;
      text-align: right;
      &::before {
        position: absolute;
        content: "";
        background-color: #706f6f;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        right: 0;
        top: 7px;
      }
    }
  }
}

.handle_save_button_dialog {
  background-color: $just_theme_color;
}
</style>
