<template>
  <footer class="footer-buttons">
    <div class="step-buttons">
      <!-- Back Button -->
      <el-button
        type="info"
        plain
        @click="prevStep"
        v-if="!(currentStep <= 1)"
        class="custom-button back-btn"
      >
        <i class="el-icon-back"></i>
      </el-button>

      <!-- Continue Button -->
      <el-button
        type="primary"
        icon-right
        @click="nextStep"
        v-if="!(currentStep >= totalSteps)"
        class="custom-button continue-btn"
      >
        {{ localization("Continue") }}    
      </el-button>

      <el-button
        type="primary"
        @click="emitSignUp"
        v-if="currentStep >= totalSteps"
        :disabled="disableSubmit"
        class="custom-button continue-btn"
      >
        <!-- {{ publicLocalization("Create Account") }} -->
        Create Account
      </el-button>
      <!-- Forward Button -->
      <el-button
        type="info"
        plain
        @click="nextStep"
        v-if="!(currentStep >= totalSteps)"
        class="custom-button back-btn"
      >
        <i class="el-icon-right"></i>
      </el-button>

      <!-- Subscription Success Popup -->
      <el-dialog
        title="Subscription Successful"
        :visible.sync="showSuccessPopup"
        width="30%"
      >
        <p>Thank you for subscribing!</p>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="goToLCI">OK</el-button>
        </span>
      </el-dialog>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
      default: () => [
        { title: "Personal Information", step: 1 },
        { title: "Payment Information", step: 2 },
        { title: "Password", step: 3 }
      ]
    },
    currentStep: {
      type: Number,
      required: true
    },
    totalSteps: {
      type: Number,
      required: true
    },
    disableSubmit: {
      type: Boolean,
      default: false
    },
    formRef: {
      type: Object,
      required: true
    },
    institution: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      showSuccessPopup: false
    };
  },
  methods: {
    async validateStep(step) {
      if (!this.formRef) return false;

      // Define fields to validate for each step
      const stepFields = {
        1: ["first_name", "last_name", "email", "age_group_id", "country"],
        2: ["card_name", "card_number", "exp_time", "cvc_code"],
        3: ["password", "confirm_password"]
      };

      // Get fields for current step
      const fields = stepFields[step];

      // Trigger validation for each field in current step
      // fields.forEach(field => {
      //   this.formRef.validateField(field);
      // });

      // return new Promise((resolve) => {
      //   this.formRef.validateField(fields, (errorMessages) => {
      //     resolve(!errorMessages);
      //   });
      // });
      return new Promise(resolve => {
        this.formRef.validate(valid => {
          resolve(valid);
        });
      });
    },

    async prevStep() {
      if (this.currentStep > 1) {
        this.$emit("update:currentStep", this.currentStep - 1);
      }
    },

    async nextStep() {
      if (this.currentStep < this.totalSteps) {
        const isValid = await this.validateStep(this.currentStep);
        if (isValid) {
          if (this.institution && this.institution.trim() !== '') {
            // this.currentStep = 3;
            this.$emit("update:currentStep", this.currentStep = 3);

          } else {
            this.$emit("update:currentStep", this.currentStep + 1);
          }
        }
      }
    },

    async emitSignUp() {
      const isValid = await this.validateStep(this.currentStep);
      if (isValid) {
        this.$emit("sign-up");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.footer-buttons {
  position: fixed; /* Keep it fixed at the bottom */
  bottom: -4rem;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  box-shadow: 0 -5px 2px 0 rgba(0, 0, 0, 0.025);
  z-index: 10;
}
.step-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* Center alignment for the container */
.d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Step button container styles */
.step-buttons {
  display: flex;
  gap: 16px; /* Space between buttons */
}

/* Back Button Styling */
.back-btn {
  background-color: #f9fafb;
  color: #000;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-weight: 500;
  padding: 8px 16px;
  box-shadow: none;
  transition: all 0.3s ease;
}
.back-btn:hover {
  background-color: #5e3cba;
  color: #fff;
}

/* Continue Button Styling */
.continue-btn {
  background-color: #e5e7eb;
  color: #667085;
  border: 0;
  border-radius: 8px;
  font-weight: 500;
  padding: 8px 4rem;
  box-shadow: none;
  transition: all 0.3s ease;
}
.continue-btn:hover {
  background-color: #5e3cba;
  color: #fff;
}
</style>
