<template> </template>

<script>
export default {
  name: "login",
  data: () => ({}),

  beforeCreate() {
    this.$store.dispatch("user/logout").then(_ => {
      this.$router.push("/login");
    });
  },
  methods: {}
};
</script>

<style scoped></style>
