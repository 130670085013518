import Language from "../../api/language";

const language = new Language();

const languageModule = {
  namespaced: true,
  state: {
    languages: [],
    load: false,
    total: 0
  },
  getters: {
    languages: function(state) {
      return state.languages;
    },
    load: state => state.load,
    total: state => state.total
  },
  mutations: {
    SET_LANGUAGES(state, languages) {
      state.languages = languages;
    },
    SET_TOTAL(state, total) {
      state.total = total;
    },
    SET_LOAD(state, load) {
      state.load = load;
    }
  },
  actions: {
    list({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOAD", true);
        language
          .list(payload.query)
          .then(response => {
            commit("SET_LANGUAGES", response.languages);
            commit("SET_TOTAL", response.count);
            commit("SET_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    save({ commit }, payload) {
      return new Promise((resolve, reject) => {
        language
          .store(payload.query, payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    switch({ commit }, id) {
      return new Promise((resolve, reject) => {
        language
          .switch(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        language
          .delete(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    togglePublish({ commit }, id) {
      return new Promise((resolve, reject) => {
        language
          .togglePublish(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getLanguages({ commit }) {
      return new Promise((resolve, reject) => {
        language
          .getLanguages()
          .then(response => {
            commit("SET_LANGUAGES", response.languages);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};

export default languageModule;
