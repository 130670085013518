<template>
  <div class="patterns_wrapper">
    <h2 v-if="withTitle" class="main_title">{{localization('Your Learning Pattern Combination')}}</h2>
    <!-- Patterns -->

    <div>
      <div class="wrapper_for_pattern" v-if="patterns">
        <div
          :class="[
            `main_pattern_card`,
            item.visible === true ? 'border_card' : ''
          ]"
          v-for="(item, i) in patterns"
          :key="i"
        >
          <div
            :style="`background-color : ${getProgressColor(item.name)}`"
            :class="[`part_one`, item.visible === false ? 'round_card' : '']"
          >
            <div>
              <span class="pattern_name">{{ item.name_short }}</span>
            </div>
            <div class="numbers_wrapper">
              <span class="number">{{ item.number }}</span>
              <span class="number_key">{{ item.number_key }}</span>
            </div>
            <div class="d-flex justify-content-center ">
              <img
                v-if="item.visible == true"
                @click="hideCardDetails(i)"
                role="button"
                src="../../../assets/new-theme/profile/chevron-down.png"
                alt=""
              />
              <img
                v-if="item.visible == false"
                @click="hideCardDetails(i)"
                role="button"
                src="../../../assets/new-theme/profile/chevron-down-under.png"
                alt=""
              />
            </div>
          </div>
          <div class="part_two" v-if="item.visible">
            <div>
              <span class="pattern_name">{{ item.second_title }}</span>
            </div>
            <ul class="list_items">
              <li
                class="item_in_list mt-1"
                v-for="(pat, index) in item.items"
                :key="index"
              >
                {{ pat }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserPattern",
  props: {
    withTitle: {
      type: Boolean,
      default: true,
      required: false
    },
    // patterns: {
    //   type: Array,
    //   required: false
    // },
    userScore: {
      type: Object,
      required: false
    },
    visible: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      patterns: [
        {
          name: "Sequence",
          name_short: this.localization("Sequence (S)") != false ? this.localization("Sequence (S)") : "Sequence (S)",
          number: "-",
          number_key: "-",
          second_title: this.localization("Sequential Learning Patterns") != false ? this.localization("Sequential Learning Patterns") : "Sequential Learning Patterns",
          visible: false,
          items: [
            this.localization("Organizes life") != false ? this.localization("Organizes life") : "Organizes life",
            this.localization("Makes Lists") != false ? this.localization("Makes Lists") : "Makes Lists",
            this.localization("Lives by schedules") != false ? this.localization("Lives by schedules") : "Lives by schedules",
            this.localization("Makes extensive plans") != false ? this.localization("Makes extensive plans") : "Makes extensive plans",
          ]
        },
        {
          name: "Precision",
          name_short: this.localization("Precision (P)") != false ? this.localization("Precision (P)") : "Precision (P)",
          number: "-",
          number_key: "-",
          second_title: this.localization("Precision Learning Patterns") != false ? this.localization("Precision Learning Patterns") : "Precision Learning Patterns",
          visible: false,
          items: [
            this.localization("Relishes information") != false ? this.localization("Relishes information") : "Relishes information",
            this.localization("Seeks facts") != false ? this.localization("Seeks facts") : "Seeks facts",
            this.localization("Must be right") != false ? this.localization("Must be right") : "Must be right",
            this.localization("Provides detailed responses") != false ? this.localization("Provides detailed responses") : "Provides detailed responses",
          ]
        },
        {
          name: "Technical Reasoning",
          name_short: this.localization("Technical Reasoning (T)") != false ? this.localization("Technical Reasoning (T)") : "Technical Reasoning (T)",
          number: "-",
          number_key: "-",
          second_title: this.localization("Technical Learning Patterns") != false ? this.localization("Technical Learning Patterns") : "Technical Learning Patterns",
          visible: false,
          items: [
            this.localization("Solves problems") != false ? this.localization("Solves problems") : "Solves problems",
            this.localization("Uses few words") != false ? this.localization("Uses few words") : "Uses few words",
            this.localization("Looks for relevance and practicality") != false ? this.localization("Looks for relevance and practicality") : "Looks for relevance and practicality",
            this.localization("Works alone") != false ? this.localization("Works alone") : "Works alone",
          ]
        },
        {
          name: "Confluence",
          name_short: this.localization("Confluence (C)") != false ? this.localization("Confluence (C)") : "Confluence (C)",
          number: "-",
          number_key: "-",
          second_title: this.localization("Confluence Learning Patterns") != false ? this.localization("Confluence Learning Patterns") : "Confluence Learning Patterns",
          visible: false,
          items: [
            this.localization("Takes risks") != false ? this.localization("Takes risks") : "Takes risks",
            this.localization("Sees the big picture") != false ? this.localization("Sees the big picture") : "Sees the big picture",
            this.localization("Embraces innovation") != false ? this.localization("Embraces innovation") : "Embraces innovation",
            this.localization("Initiates change") != false ? this.localization("Initiates change") : "Initiates change",
          ]
        }
      ],
      test: ""
    };
  },
  methods: {
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    hideCardDetails(index) {
      this.patterns[index].visible = !this.patterns[index].visible;
    },
    handlePatterns() {
      this.patterns.forEach(pattern => {
        if (pattern.name == "Sequence") {
          pattern.number = this.userScore?.sequence;
          // Start Pattern range
          if (this.userScore?.sequence >= 25 && this.userScore.sequence <= 35) {
            pattern.number_key = this.localization("Use First") != false ? this.localization("Use First") : "Use First";
          } else if (
            this.userScore?.sequence >= 7 &&
            this.userScore.sequence <= 17
          ) {
            pattern.number_key = this.localization("Avoid") != false ? this.localization("Avoid") : "Avoid";
          } else if (
            this.userScore?.sequence >= 18 &&
            this.userScore.sequence <= 24
          ) {
            pattern.number_key = this.localization("As Needed") != false ? this.localization("As Needed") : "As Needed";
          }
          // End Pattern range
        }
        if (pattern.name == "Precision") {
          pattern.number = this.userScore?.precision;
          // Start Pattern range
          if (
            this.userScore?.precision >= 25 &&
            this.userScore.precision <= 35
          ) {
            pattern.number_key = this.localization("Use First") != false ? this.localization("Use First") : "Use First";
          } else if (
            this.userScore?.precision >= 7 &&
            this.userScore.precision <= 17
          ) {
            pattern.number_key = this.localization("Avoid") != false ? this.localization("Avoid") : "Avoid";
          } else if (
            this.userScore?.precision >= 18 &&
            this.userScore.precision <= 24
          ) {
            pattern.number_key = this.localization("As Needed") != false ? this.localization("As Needed") : "As Needed";
          }
          // End Pattern range
        }
        if (pattern.name == "Technical Reasoning") {
          pattern.number = this.userScore?.technical_reasoning;
          // Start Pattern range
          if (
            this.userScore?.technical_reasoning >= 25 &&
            this.userScore.technical_reasoning <= 35
          ) {
            pattern.number_key = this.localization("Use First") != false ? this.localization("Use First") : "Use First";
          } else if (
            this.userScore?.technical_reasoning >= 7 &&
            this.userScore.technical_reasoning <= 17
          ) {
            pattern.number_key = this.localization("Avoid") != false ? this.localization("Avoid") : "Avoid";
          } else if (
            this.userScore?.technical_reasoning >= 18 &&
            this.userScore.technical_reasoning <= 24
          ) {
            pattern.number_key = this.localization("As Needed") != false ? this.localization("As Needed") : "As Needed";
          }
          // End Pattern range
        }
        if (pattern.name == "Confluence") {
          pattern.number = this.userScore?.confluence;
          // Start Pattern range
          if (
            this.userScore?.confluence >= 25 &&
            this.userScore.confluence <= 35
          ) {
            pattern.number_key = this.localization("Use First") != false ? this.localization("Use First") : "Use First";
          } else if (
            this.userScore?.confluence >= 7 &&
            this.userScore.confluence <= 17
          ) {
            pattern.number_key = this.localization("Avoid") != false ? this.localization("Avoid") : "Avoid";
          } else if (
            this.userScore?.confluence >= 18 &&
            this.userScore.confluence <= 24
          ) {
            pattern.number_key = this.localization("As Needed") != false ? this.localization("As Needed") : "As Needed";
          }
          // End Pattern range
        }
      });
    },
    setAllPatternsVisible() {
      // Set all pattern items' visible property to true
      this.patterns.forEach(pattern => {
        pattern.visible = true;
      });
    }
  },

  watch: {
    userScore: {
      handler(newVal) {
        if (newVal) {
          this.handlePatterns(); // Call the function when userScore has a value
        }
      },
      immediate: true // If you want the watcher to trigger immediately when the component is mounted and `userScore` already has a value
    },
    visible: {
      handler(newVal) {
        if (newVal === true) {
          this.setAllPatternsVisible(); // Call a function to make all patterns visible
        }
      },
      immediate: true // Run the watcher on initial mount if visible is true
    }
  }
};
</script>

<style lang="scss" scoped>
// Use Your Learning Patterns
.main_title {
  color: #101828;
  font-weight: 600;
  font-size: 20px;
  &.white_text {
    color: #fff;
  }
}
.patterns_wrapper {
  .wrapper_for_pattern {
    display: flex;
    justify-content: space-between;
    gap: 1.5%;
  }
  .main_pattern_card {
    border-radius: 8px;
    width: calc(94% / 4);
    border: 1px solid transparent;
    &.border_card {
      border: 1px solid #eaecf0;
    }
    .part_one {
      padding: 15px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border: 1px solid transparent;
      &.round_card {
        border-radius: 8px;
        border: 1px solid #eaecf0;
      }
      .pattern_name {
        font-weight: 500;
        font-size: 17px;
        color: #fff;
        margin-bottom: 15px;
        display: block;
      }
      .numbers_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .number {
          font-weight: 500;
          font-size: 48px;
          color: #fff;
        }
        .number_key {
          font-weight: 500;
          font-size: 17px;
          color: #fff;
        }
      }
    }
    .part_two {
      padding: 15px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
      height: 200px;
      .pattern_name {
        font-weight: 500;
        font-size: 17px;
        color: #344054;
      }
      .list_items {
        margin-bottom: 0 !important;
        margin-left: 0;
        padding-left: 17px;
      }
      .item_in_list {
        font-weight: 400;
        font-size: 16px;
        color: #667085;
      }
    }
  }
}
</style>
