import Resource from './resource';
import request from "../utils/request";

class Teams extends Resource {
  constructor() {
    super('teams');
  }
  
  question_one_statements() {
    return request({
      url: `/users/statements/questionone`,
      method: 'post',
    })
  }

  question_two_statements() {
    return request({
      url: `/users/statements/questiontwo`,
      method: 'post',
    })
  }
  
  join_team(payload) {
    return request({
      url: `/users/join/team`,
      method: 'post',
      data: payload
    })
  }

  remove_team_member( teamId , id ) {
    return request({
      url: `users/remove/team/member/${teamId}/${id}`,
      method: "delete"
    });
  }

  retrive_team_member( teamId , id ) {
    return request({
      url: `users/retrive/team/member/${teamId}/${id}`,
      method: "delete"
    });
  }

}

export {Teams as default};
