<template>
  <div class="w-100 m-0 p-0">
    <el-form
        :model="teamForm"
        :rules="rules"
        ref="addTeam"
        :class="{ 'text-right build_with_rtl': isRtl() }"
    >
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <h4 class="m-0">Enter team details</h4>
                    <p class="text-muted m-0">Enter the team name and add members to it.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="row pt-2" :class="{ 'd-flex flex-row-reverse': isRtl() }">
                        <div class="col-md-6 my-2">
                            <el-form-item :label="`${localization('Name your team *')}`" prop="name">
                                <el-input
                                    :placeholder="`${localization('Give you team a name')}`"
                                    v-model="teamForm.name"
                                ></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row pt-4">
                        <div class="col-md-6 pb-2">
                            <h4 class="m-0">Invite members to your team *</h4>
                            <p class="text-muted m-0 mb-3">Invite members to your team by any of the following methods.</p>
                            <div class="border rounded-lg d-flex p-3" sty>
                                <div class="mr-3">
                                    <img src="@/assets/new-theme/i.png" alt="" >
                                </div>
                                <div>
                                    <p class="m-0" >Team should have at least 3 members</p>
                                    <p class="text-muted m-0">Invite up to 5 members to your team</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 my-2">
                            <el-form-item :label="`${localization('Member from your contacts')}`">
                                <el-select
                                    ref="users"
                                    :placeholder="`${localization('Select and add a team members')}`"
                                    :no-data-text="`${localization('Write Name')}`"
                                    style="width: 100%"
                                    v-model="teamForm.selected_team_members"
                                    filterable
                                    multiple
                                    remote
                                    reserve-keyword
                                    :remote-method="search"
                                    :loading="loadingValue"
                                >
                                    <el-option
                                    v-for="user in contactList"
                                    :key="user.id"
                                    :label="user.first_name + ' ' + user.last_name"
                                    :value="user.id"
                                    :disabled="teamForm.selected_team_members.length == 5"
                                    >
                                    <span style="color: #8492a6; font-size: 13px">
                                        <i class="el-icon-user-solid"></i>
                                        {{ user.first_name + " " + user.last_name }}
                                    </span>
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <el-form-item label="Institution Members" prop>
                                <el-select
                                    v-model="teamForm.selected_institutions_users"
                                    :placeholder="`${localization('Select and add Institution members')}`"
                                    style="width: 100%"
                                    multiple
                                    class
                                    filterable
                                >
                                    <el-option
                                        v-for="item in institutionUsersList"
                                        :key="item.id"
                                        :label="item.first_name + ' ' + item.last_name"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mb-2">
                    <div class="row pt-2" :class="{ 'd-flex flex-row-reverse': isRtl() }">
                        <div class="col-md-6 my-2">
                            <el-form-item label="Invite members by email" style="width: 100%" prop="emails">
                                <el-select
                                    v-model="teamForm.emails"
                                    :placeholder="`${localization('Enter the member email')}`"
                                    style="width: 100%"
                                    multiple
                                    class
                                    filterable
                                    :no-data-text="`${localization('Type Email')}`"
                                    allow-create
                                    default-first-option
                                ></el-select>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 my-2">
                    <div class="dialog_footer" :class="{ 'justify-content-start': isRtl() }">
                        <el-button
                            class="text-center text-capitalize save_button"
                            @click="save_team"
                        >
                            {{ localization("Add Team") }}
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </el-form>
    <div>
        <!-- <div
        class="dialog_footer show_line_graph_btn"
        :class="{ 'justify-content-end': isRtl() }"
        >
        <el-button
            class="text-center text-capitalize save_button"
            @click="teamLineGraphHandler"
            :disabled="teamForm.selected_team_members.length < 1"
        >{{ localization("Show Team Line Graph") }}</el-button>
        </div> -->

        <div v-if="line_graph_members.length >= 1">
        <div class="the_main_wrapper_for_charts" v-if="!loadingValue">
            <div class="is_chart_wrapper">
            <chart-card
                v-if="scores.length > 0"
                :chart-data="teamChart"
                :chart-options="barChart.options"
                :chart-responsive-options="barChart.responsiveOptions"
            >
                <template slot="header">
                <div class="d-flex" :class="{ 'flex-row-reverse': isRtl() }">
                    <i
                    class="el-icon el-icon-data-analysis"
                    style="font-size: 30px; margin-right: 7px"
                    ></i>
                    <h4 class="card-title">{{ localization("Team Line Graph") }}</h4>
                </div>
                </template>
            </chart-card>
            <div class="lines_in_chart avoid">
                <span class="is_text_chart">Avoid</span>
            </div>
            <div class="lines_in_chart needed">
                <span class="is_text_chart">As Needed</span>
            </div>
            <div class="lines_in_chart first">
                <span class="is_text_chart">Use First</span>
            </div>
            </div>
            <div
            class="for_line_graph_members_container"
            :class="{ for_line_graph_members_with_rtl: isRtl() }"
            >
            <div
                v-for="(member, index) in line_graph_members"
                :key="member.id"
                class="every_bullet_wrapper"
                :class="`member_${index + 1}`"
            >
                <span class="bullet"></span>
                <span class="text-capitalize">{{ member.first_name }} {{ member.last_name }}</span>
            </div>
            </div>
            <div class="text-center team_description_text">
            <p>{{ line_graph_members_description }}</p>
            </div>
        </div>
        </div>
    </div>
    <!-- <div class="team_routes_wrapper" :class="{ 'd-flex flex-row-reverse': isRtl() }">
    <router-link class="back_link" to="/teams">
        <i class="fa fa-arrow-left back_icon mr-1"></i>
        {{ localization("Back to team") }}
    </router-link>
    <router-link class="back_link" to="/working-together">
        {{ localization("next") }}
        <i class="fa fa-arrow-right back_icon mr-1"></i>
    </router-link>
    </div> -->
  </div>
</template>
  
<script>
import { Card } from "src/components/index";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { ChartCard, StatsCard } from "src/components/index";
export default {
  data() {
    return {
      teamForm: {
        name: "",
        selected_team_members: [],
        emails: [],
        selected_institutions_users: []
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      barChart: {
        options: {
          seriesBarDistance: 20,
          showPoint: true,
          labelDirection: "explode",
          chartPadding: 0,
          labelOffset: 20,
          low: 7,
          high: 40,
          showLine: true,
          labelInterpolationFnc: function(value) {
            return `${value.split(" ")[0]}...`;
          },
          height: "350px",
          width: "95%",
          axisX: {
            offset: 30,
            position: "end",
            showLabel: true,
            showGrid: true,
            labelOffset: {
              x: 0,
              y: 5
            }
          },
          axisY: {
            offset: 50,
            position: "start",
            showLabel: true,
            showGrid: true,
            scaleMinSpace: 20,
            onlyInteger: true,
            labelOffset: {
              x: 0,
              y: 0
            }
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      }
    };
  },

  components: {
    Card,
    ChartCard,
    StatsCard
  },
  computed: {
    ...mapGetters({
      loadingValue: ["user/usersLoad"],
      contactList: ["contacts/contactList"],
      line_graph_members: ["user/line_graph_members"],
      line_graph_members_description: ["user/line_graph_members_description"],
      team_pages_content: ["user/get_team_pages_content"],
      team_pages_titles_content: ["user/get_team_pages_titles_content"],
      institutionUsersList: ["groups/getInstUsers"]
    }),
    scores() {
      let scores = [];
      this.line_graph_members.forEach(element => {
        scores.push([
          element.sequence,
          element.precision,
          element.technical_reasoning,
          element.confluence
        ]);
      });
      return scores;
    },
    teamChart() {
      let data = {};

      data.labels = [
        "Sequence",
        "Precision",
        "Technical Reasoning",
        "Confluence"
      ];
      data.series = this.scores;
      return data;
    }
  },
  mounted() {
    this.getContactsList();
    this.$store.dispatch("user/get_all_teams_content");
    this.$store.dispatch("groups/institutionUsersGroup");
  },
  methods: {
    getContactsList(query) {
      this.$store.dispatch("contacts/GetContactsList", { query: query });
    },
    search(query) {
      this.usersRoles(query);
    },

    usersRoles(search = "", limit = 10) {
      this.$store
        .dispatch("contacts/GetContactsList", {
          search_text: search
        })
        .then(() => {
          this.search_group = this.$store.getters["contacts/contactList"];
        });
    },
    teamLineGraphHandler() {
      this.$store.dispatch("user/get_team_line_graph", {
        users: [...this.teamForm.selected_team_members, this.auth().id]
      });
    },
    teamLineGraphHandlerForEmptyData() {
      this.$store.dispatch("user/get_team_line_graph", {
        users: this.teamForm.selected_team_members
      });
    },
    save_team() {
      this.$refs["addTeam"].validate(valid => {
        if (valid) {
          let selectedMem = [
            ...this.teamForm.selected_team_members,
            ...this.teamForm.selected_institutions_users
          ];
          this.$store
            .dispatch("user/add_team", {
              name: this.teamForm.name,
              emails: this.teamForm.emails,
              users: selectedMem
            })
            .then(response => {
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              }).then(data => {
                if (data.isConfirmed) {
                  this.teamForm.name = "";
                  this.teamForm.emails = [];
                  this.teamForm.selected_team_members = [];
                  this.teamForm.selected_institutions_users = [];
                  this.teamLineGraphHandlerForEmptyData();
                  this.$emit("teamsAnswers", response.team.id)
                }
              });
            });
        } else {
        }
      });
    }
  }
};
</script>
<style scoped>
::v-deep
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-right: 4px;
  margin-bottom: 0px;
}

::v-deep .el-form-item__label{
    margin: 0px;
    color: #344054 !important;
}

::v-deep .el-input__inner{
  border-radius: 8px;
}

::v-deep .el-input__inner::placeholder {
  color: #667085;
  opacity: 1; /* Firefox */
}
</style>