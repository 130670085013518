var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper class_for_side_tooltips",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),(_vm.showNavbar || _vm.showSidebar)?_c('side-bar',[_c('template',{slot:"links"},[(_vm.hasPermission('old_theme'))?_c('div',[_c('div',{staticClass:"wrapper_for_side_items pt-3"},[(
              _vm.hasPermission('access_dashboard') &&
                _vm.hasPermission('show_old_tabs')
            )?_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('Admin Dashboard') != false
                  ? _vm.localization('Admin Dashboard')
                  : 'Admin Dashboard')),
              icon: 'nc-icon nc-chart-pie-35 font-weight-800',
              path: '/overview'
            }}}):_vm._e()],1),(_vm.auth())?_c('div',{staticClass:"finally_handler_sidebar"},[(_vm.hasPermission('show_old_tabs'))?_c('div',{on:{"click":_vm.testHello}},[_c('sidebar-item',{attrs:{"link":{
                name: ("" + (_vm.localization('My Account') != false
                    ? _vm.localization('My Account')
                    : 'My Account')),
                icon: 'el-icon-user font-weight-800',
                path: '#'
              }}})],1):_vm._e(),(_vm.switchCollapse)?_c('div',{staticClass:"switchCollapse_items"},[_c('el-tooltip',{staticClass:"item",attrs:{"content":("" + (_vm.localization('Complete the Learning Connections Inventory and discover how your mind helps you learn, complete tasks, and work with others.'))),"placement":"right-start","effect":"dark"}},[_c('div',{staticClass:"handle_sidebar_tooltips "},[(_vm.hasPermission('access_lci') || _vm.hasPermission('guest'))?_c('router-link',{staticClass:"just_style_if_sidebar",attrs:{"to":"/edit-questionnaire"}},[_vm._v(_vm._s(_vm.localization("LCI") != false ? _vm.localization("LCI") : "LCI"))]):_vm._e()],1)]),_c('el-tooltip',{staticClass:"item",attrs:{"content":("" + (_vm.localization('Your Personal Learning Profile results will show your Learning Patterns by type and degree of use.'))),"placement":"right-start","effect":"dark"}},[_c('div',{staticClass:"handle_sidebar_tooltips "},[_c('router-link',{staticClass:"just_style_if_sidebar",attrs:{"to":"/profile"}},[_vm._v(_vm._s(_vm.localization("Personal Learning Profile") != false ? _vm.localization("Personal Learning Profile") : "Personal Learning Profile"))])],1)]),_c('el-tooltip',{staticClass:"item",attrs:{"content":("" + (_vm.localization('Edit your account, change your password, etc.'))),"placement":"right-start"}},[_c('div',{staticClass:"handle_sidebar_tooltips "},[_c('router-link',{staticClass:"just_style_if_sidebar",attrs:{"to":"/edit-user"}},[_vm._v(_vm._s(_vm.localization("Edit Account") != false ? _vm.localization("Edit Account") : "Edit Account"))])],1)])],1):_vm._e()]):_vm._e(),(
            _vm.userScore.confluence != null &&
              _vm.userScore.precision != null &&
              _vm.userScore.sequence != null &&
              _vm.userScore.technical_reasoning != null
          )?_c('div',{staticClass:"wrapper_for_side_items"},[(_vm.hasPermission('show_old_tabs'))?_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('My Dashboard') != false
                  ? _vm.localization('My Dashboard')
                  : 'My Dashboard')),
              icon: 'nc-icon nc-chart-pie-35 font-weight-800',
              path: '/my-dashboard'
            }}}):_vm._e()],1):_vm._e(),(
            (_vm.auth().family_type == 1 || _vm.auth().family_type == 2) &&
              _vm.hasPermission('show_old_tabs')
          )?_c('sidebar-item',{staticClass:"just_display_if_mobile",attrs:{"link":{
            name: ("" + (_vm.localization('My Account') != false
                ? _vm.localization('My Account')
                : 'My Account')),
            icon: 'el-icon-users'
          }}},[(_vm.hasPermission('access_lci') || _vm.hasPermission('guest'))?_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('LCI') != false ? _vm.localization('LCI') : 'LCI')),
              path: '/edit-questionnaire'
            }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('Personal Learning Profile') != false
                  ? _vm.localization('Personal Learning Profile')
                  : 'Personal Learning Profile')),
              path: '/profile'
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('Edit Account') != false
                  ? _vm.localization('Edit Account')
                  : 'Edit Account')),
              path: '/edit-user'
            }}})],1):_vm._e(),_c('div',{staticClass:"finally_handler_sidebar",class:{ 'd-none': _vm.hasPermission('guest') }},[_c('div',{on:{"click":_vm.testHello2}},[(_vm.hasPermission('show_old_tabs'))?_c('sidebar-item',{attrs:{"link":{
                name: ("" + (_vm.localization('Use Your Patterns') != false
                    ? _vm.localization('Use Your Patterns')
                    : 'Use Your Patterns')),
                icon: 'el-icon-user font-weight-800',
                path: '##'
              }}}):_vm._e()],1),(_vm.switchCollapse2)?_c('div',{staticClass:"switchCollapse_items"},[_c('el-tooltip',{staticClass:"item",attrs:{"content":("" + (_vm.localization('Select the personal strategies you need to succeed in completing a learning assignment!'))),"placement":"right-start","effect":"dark"}},[_c('div',{staticClass:"handle_sidebar_tooltips "},[_c('router-link',{staticClass:"just_style_if_sidebar",attrs:{"to":"/tasks"}},[_vm._v(_vm._s(_vm.localization("School Strategies") != false ? _vm.localization("School Strategies") : "School Strategies"))])],1)]),_c('el-tooltip',{staticClass:"item",attrs:{"content":("" + (_vm.localization('Select the personal strategies you need to succeed in completing a work assignment!'))),"placement":"right-start","effect":"dark"}},[_c('div',{staticClass:"handle_sidebar_tooltips "},[_c('router-link',{staticClass:"just_style_if_sidebar",attrs:{"to":"/at-work"}},[_vm._v(_vm._s(_vm.localization("Work Strategies") != false ? _vm.localization("Work Strategies") : "Work Strategies"))])],1)]),_c('el-tooltip',{staticClass:"item",attrs:{"content":("" + (_vm.localization('See if a job is the right fit for you!'))),"placement":"right-start","effect":"dark"}},[_c('div',{staticClass:"handle_sidebar_tooltips "},[_c('router-link',{staticClass:"just_style_if_sidebar",attrs:{"to":"/career-match-questions"}},[_vm._v(_vm._s(_vm.localization("Career Match") != false ? _vm.localization("Career Match") : "Career Match"))])],1)])],1):_vm._e()]),_c('sidebar-item',{staticClass:"just_display_if_mobile",class:{ 'd-none': _vm.hasPermission('guest') },attrs:{"link":{
            name: ("" + (_vm.localization('Use Your Patterns') != false
                ? _vm.localization('Use Your Patterns')
                : 'Use Your Patterns')),
            icon: 'nc-icon nc-ruler-pencil'
          }}},[_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('School Strategies') != false
                  ? _vm.localization('School Strategies')
                  : 'School Strategies')),
              path: '/tasks'
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('Work Strategies') != false
                  ? _vm.localization('Work Strategies')
                  : 'Work Strategies')),
              path: '/at-work'
            }}}),_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('Career Match') != false
                  ? _vm.localization('Career Match')
                  : 'Career Match')),
              path: '/career-match-questions'
            }}})],1),(_vm.hasPermission('show_old_tabs'))?_c('el-tooltip',{staticClass:"item just_display_if_not_mobile",class:{ 'd-none': _vm.hasPermission('guest') },attrs:{"content":("" + (_vm.localization('Build a team and learn how to use the groups Learning Patterns to work better together!'))),"placement":"right-start"}},[_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('Teams') != false
                  ? _vm.localization('Teams')
                  : 'Teams')),
              icon: 'el-icon-collection font-weight-800',
              path: '/teams'
            }}})],1):_vm._e(),_c('div',{staticClass:"just_display_if_this_mobile",class:{ 'd-none': _vm.hasPermission('guest') }},[_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('Teams') != false
                  ? _vm.localization('Teams')
                  : 'Teams')),
              icon: 'el-icon-collection',
              path: '/teams'
            }}})],1),(_vm.hasPermission('show_old_tabs'))?_c('el-tooltip',{staticClass:"item just_display_if_not_mobile",class:{ 'd-none': _vm.hasPermission('guest') },attrs:{"content":("" + (_vm.localization('Invite your classmates, friends, business contacts to share their LCI scores with you and build your opportunity to share strategies!'))),"placement":"right-start"}},[_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('Contacts') != false
                  ? _vm.localization('Contacts')
                  : 'Contacts')),
              icon: 'nc-icon nc-credit-card font-weight-800',
              path: '/contacts'
            }}})],1):_vm._e(),_c('div',{staticClass:"just_display_if_this_mobile",class:{ 'd-none': _vm.hasPermission('guest') }},[_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('Contacts') != false
                  ? _vm.localization('Contacts')
                  : 'Contacts')),
              icon: 'nc-icon nc-credit-card',
              path: '/contacts'
            }}})],1),(_vm.hasPermission('show_old_tabs'))?_c('el-tooltip',{staticClass:"item just_display_if_not_mobile",class:{ 'd-none': _vm.hasPermission('guest') },attrs:{"content":("" + (_vm.localization('Organize your contacts into groups and see the group dynamic.'))),"placement":"right-start"}},[_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('Groups') != false
                  ? _vm.localization('Groups')
                  : 'Groups')),
              icon: 'nc-icon nc-paper-2 font-weight-800',
              path: '/groups'
            }}})],1):_vm._e(),_c('div',{staticClass:"just_display_if_this_mobile",class:{ 'd-none': _vm.hasPermission('guest') }},[_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('Groups') != false
                  ? _vm.localization('Groups')
                  : 'Groups')),
              icon: 'nc-icon nc-paper-2',
              path: '/groups'
            }}})],1),(
            _vm.hasPermission('access_institutions') &&
              _vm.hasPermission('show_old_tabs')
          )?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (_vm.localization('Institutions') != false
                ? _vm.localization('Institutions')
                : 'Institutions')),
            icon: 'nc-icon nc-bank font-weight-800',
            path: '/institution'
          }}}):_vm._e(),(
            _vm.hasPermission('access_topics') &&
              _vm.hasPermission('access_strategies') &&
              _vm.hasPermission('access_words') &&
              _vm.hasPermission('access_questions') &&
              _vm.hasPermission('access_cms') &&
              _vm.hasPermission('access_roles') &&
              _vm.hasPermission('access_logs') &&
              _vm.hasPermission('show_old_tabs')
          )?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (_vm.localization('Management') != false
                ? _vm.localization('Management')
                : 'Management')),
            icon: 'nc-icon nc-settings-90 font-weight-800',
            path: '/management'
          }}}):_vm._e(),(_vm.hasPermission('manage_institutions'))?_c('sidebar-item',{attrs:{"link":{
            name: "User Categories",
            icon: 'nc-icon nc-settings-90',
            path: '/user-categories'
          }}}):_vm._e(),(_vm.hasPermission('show_old_tabs'))?_c('el-tooltip',{staticClass:"item just_display_if_not_mobile",class:{ 'd-none': _vm.hasPermission('guest') },attrs:{"content":("" + (_vm.localization('How else can you use the LCI? Explore more here.'))),"placement":"right-start"}},[_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('How will the LCI help you?') != false
                  ? _vm.localization('How will the LCI help you?')
                  : 'How will the LCI help you?')),
              icon: 'el-icon-question',
              path: '/lci-help-you'
            }}})],1):_vm._e(),_c('div',{staticClass:"just_display_if_this_mobile",class:{ 'd-none': _vm.hasPermission('guest') }},[_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('How will the LCI help you?') != false
                  ? _vm.localization('How will the LCI help you?')
                  : 'how will the lci help you')),
              icon: 'el-icon-question',
              path: '/lci-help-you'
            }}})],1),(_vm.hasPermission('show_old_tabs'))?_c('el-tooltip',{staticClass:"item just_display_if_not_mobile",attrs:{"content":("" + (_vm.localization('If you need more explanations of how parts of the PLC work, check out our videos!'))),"placement":"right-start"}},[_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('Videos') != false
                  ? _vm.localization('Videos')
                  : 'Videos')),
              icon: 'el-icon-video-play font-weight-800',
              path: '/videos'
            }}})],1):_vm._e(),_c('div',{staticClass:"just_display_if_this_mobile"},[_c('sidebar-item',{attrs:{"link":{
              name: ("" + (_vm.localization('Videos') != false
                  ? _vm.localization('Videos')
                  : 'Videos')),
              icon: 'el-icon-video-play',
              path: '/videos'
            }}})],1),(
            _vm.hasPermission('access_words') && _vm.hasPermission('show_old_tabs')
          )?_c('sidebar-item',{attrs:{"link":{
            name: ("" + (_vm.localization('Families') != false
                ? _vm.localization('Families')
                : 'Families')),
            icon: 'nc-icon nc-bank font-weight-800',
            path: '/families'
          }}}):_vm._e()],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: ("" + (_vm.localization('My Account') != false
                ? _vm.localization('My Account')
                : 'My Account')),
          icon: 'nc-icon nc-single-02 font-weight-800',
          path: '/new-theme/profile'
        }}}),(
          _vm.hasPermission('access_users')
        )?_c('sidebar-item',{attrs:{"link":{
          name: ("" + (_vm.localization('Users') != false ? _vm.localization('Users') : 'Users')),
          icon: 'fa fa-users',
          path: '/users'
        }}}):_vm._e(),(_vm.hasPermission('access_lci'))?_c('sidebar-item',{attrs:{"link":{
          name: ("" + (_vm.localization('lCI') != false
                ? _vm.localization('lCI')
                : 'lCI')),
          icon: 'nc-icon nc-single-copy-04 font-weight-800',
          path: '/new-theme/instructions'
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: ("" + (_vm.localization('Personalized Profile') != false
                ? _vm.localization('Personalized Profile')
                : 'Personalized Profile')),
          icon: 'nc-icon nc-badge font-weight-800',
          path: '/new-theme/plp'
        }}}),(_vm.hasPermission('access_tasks'))?_c('sidebar-item',{attrs:{"link":{
          name: ("" + (_vm.localization('School Strategies') != false
                ? _vm.localization('School Strategies')
                : 'School Strategies')),
          icon: 'nc-icon nc-ruler-pencil font-weight-800',
          path: '/new-theme/tasks'
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: ("" + (_vm.localization('Work Strategies') != false
                ? _vm.localization('Work Strategies')
                : 'Work Strategies')),
          icon: 'nc-icon nc-bank font-weight-800',
          path: '/new-theme/at-work'
        }}}),(_vm.hasPermission('access_career'))?_c('sidebar-item',{attrs:{"link":{
          name: ("" + (_vm.localization('Career Match') != false
                ? _vm.localization('Career Match')
                : 'Career Match')),
          icon: 'nc-icon nc-bag font-weight-800',
          path: '/new-theme/career'
        }}}):_vm._e(),(_vm.hasPermission('access_pwp'))?_c('sidebar-item',{attrs:{"link":{
          name: ("" + (_vm.localization('PWP') != false
                ? _vm.localization('PWP')
                : 'PWP')),
          icon: 'nc-icon nc-notes font-weight-800',
          path: '/new-theme/pwp'
        }}}):_vm._e(),(_vm.hasPermission('access_team'))?_c('sidebar-item',{attrs:{"link":{
          name: ("" + (_vm.localization('Teams') != false
                ? _vm.localization('Teams')
                : 'Teams')),
          icon: 'nc-icon nc-grid-45 font-weight-800',
          path: '/new-theme/teams'
        }}}):_vm._e(),(_vm.hasPermission('access_families'))?_c('sidebar-item',{attrs:{"link":{
          name: ("" + (_vm.localization('Family Members') != false
                ? _vm.localization('Family Members')
                : 'Family Members')),
          icon: 'el-icon-user font-weight-800',
          path: '/family-members'
        }}}):_vm._e(),_c('el-tooltip',{staticClass:"item just_display_if_not_mobile",class:{ 'd-none': _vm.hasPermission('guest') },attrs:{"content":("" + (_vm.localization('Organize your contacts into groups and see the group dynamic.'))),"placement":"right-start"}},[_c('sidebar-item',{attrs:{"link":{
            name: ("" + (_vm.localization('Groups') != false
                ? _vm.localization('Groups')
                : 'Groups')),
            icon: 'nc-icon nc-grid-45 font-weight-800',
            path: '/new-theme/groups'
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: ("" + (_vm.localization('Contacts') != false
                ? _vm.localization('Contacts')
                : 'Contacts')),
          icon: 'nc-icon nc-email-85 font-weight-800',
          path: '/new-theme/contacts'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: ("" + (_vm.localization('Logout') != false
              ? _vm.localization('Logout')
              : 'Logout')),
          icon: 'fa fa-sign-out ',
          path: '/logout'
        }}})],1)],2):_vm._e(),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$store.getters['roles/rolesLoad']),expression:"$store.getters['roles/rolesLoad']"}],staticClass:"main-panel",class:{ new_panel_for_new_theme: _vm.showSidebar == false }},[(_vm.showNavbar || _vm.showSidebar)?_c('top-navbar'):_vm._e(),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar.apply(null, arguments)}}}),(_vm.showNavbar || _vm.showSidebar)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }