<template>
  <div class="main_lci_wrapper ">
    <div class="main_layout d-flex">
      <div class="cards_and_logo ">
        <div class="render_action">
          <img src="@/assets/new-theme/PLC-Logo.svg" alt="" />

          <img
            v-if="hideXIcon"
            @click="hideAndShowSidebar"
            role="button"
            src="@/assets/new-theme/close.png"
            alt="x-close"
          />
        </div>
        
        <div v-if="hideShowSidebar == false">
          <h4 class="mb-0 pb-0" >{{ localization("Why is this important?") }}</h4>
          <div class="cards_wrapper" v-for="(item, i) in cardItems" :key="i">
            <LciCard :item="item"></LciCard>
          </div>
        </div>
        <div
          class="mt-5 absolute_question_mark"
          v-if="!hideXIcon"
          :class="{ make_absolute: hideShowSidebar == true }"
        >
          <div
            @click="hideAndShowSidebar"
            role="button"
            class="mark_tooltip"
            v-tooltip.top-center="`${localization('Useful tips and support')}`"
          >
            <img src="@/assets/new-theme/icons/help-circle@2x.png" alt="" />
          </div>
        </div>
      </div>
      <div class="tabs_wrapper pt-3 px-2">
        <div class="step_number_and_questions_number">
          <img
            role="button"
            src="@/assets/new-theme/icons/arrow-narrow-left-active-Button.png"
            alt=""
          />
          <div class="d-flex">
            <span class="mr-2" style="font-size: 24px; font-weight: 500; font-family: Poppins;">
              {{ localization("Step 1") }}  - {{ questionTitle }}
            </span>
          </div>

        </div>
        <div class="tabs_steps mt-4">
          <span
            v-for="(tab, index) in 4"
            :key="tab"
            class="d-block step"
            :class="{ active: index == 0 }"
          >
          </span>
        </div>
        
        <div
          class="global_theme_ui_collapse main_profile_personalize_and_collapse"
        >
          <div  class="mt-4">
            <span class="st_number_value font-size-larger h3 ">
              {{ questionTitle }}
            </span>
          </div>
          <p class="text-muted">{{ localization("Select the appropriate statements for each Learning Pattern.") }}</p>
          <div>
            <el-collapse
              class="mt-3 customization_for_collapse"
              v-model="panel"
              multiple
            >
              <div
                class="main_child "
                v-for="(attribute, i) in tabs"
                :key="i"
                style="border : 2px solid #d0d5dd"
              >
                <el-collapse-item
                  :name="`${i}`"
                  class=""
                  :class="{ break_section: i == 'Precision' }"
                >
                  <template #title>
                    <div class="custom_header_of_collapse">
                      <img
                        src="@/assets/new-theme/attributes-images/sequence.png"
                        alt="sequence"
                        v-if="i == 'Sequence'"
                      />
                      <img
                        src="@/assets/new-theme/attributes-images/precision.png"
                        alt="precision"
                        v-if="i == 'Precision'"
                      />
                      <img
                        src="@/assets/new-theme/attributes-images/technical_reasoning.png"
                        alt="technical_reasoning"
                        v-if="i == 'Technical Reasoning'"
                      />
                      <img
                        src="@/assets/new-theme/attributes-images/confluence.png"
                        alt="confluence"
                        v-if="i == 'Confluence'"
                      />
                      <span class="ml-2 mr-2">{{ i }}</span>
                    </div>
                  </template>
                  <div class="p-3">
                    <el-collapse class="collapse_checkboxes">
                      <div v-for="(item, index) in attribute" :key="index">
                        <div
                          class="checkboxes_wrapper"
                          v-for="(val, k) in item"
                          :key="k"
                        >
                          <el-checkbox-group
                            class=""
                            v-model="selected"
                            v-if="val"
                          >
                            <el-checkbox
                              v-if="val"
                              :label="val.id"
                              @change="updateSelectedAttributes"
                            >
                              {{ val.statement }}
                            </el-checkbox>
                          </el-checkbox-group>
                          <audio controls id="audio" class="d-none">
                            <source id="audio-source" type="audio/mp3" />
                          </audio>
                          <span
                            class="span_img_wrapper hide_element_while_printing"
                            role="button"
                            @click="text_speech_audio(val.statement)"
                          >
                            <img
                              src="@/assets/new-theme/attributes-images/volume.png"
                              alt="volume"
                            />
                          </span>
                        </div>
                      </div>
                    </el-collapse>
                  </div>
                </el-collapse-item>
              </div>
            </el-collapse>
          </div>

          <div class="mt-3 d-flex justify-content-center mb-5">
            <button
              @click="nextTab"
              class="lci_btn save mx-4"
            >
              {{ localization("Save and Continue") }}
            </button>

            <button
              @click="nextTab"
              class="prev_next"
            >
              <img
                class="flip-horizontal"
                src="@/assets/new-theme/icons/arrow-narrow-left-active-Button.png"
                alt="edit icon"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TeamForm from "./TeamForm.vue";
import TeamAnswers from "./TeamAnswers.vue";
export default {
  name: "PwpQuestions",
  data() {
    return {
      hideShowSidebar: false,
      getAllStatements: {},
      hideXIcon: false,
      cardItems: [
        {
          src: require("@/assets/new-theme/switch.png"),
          title: this.localization("Team interaction"),
          description:
            this.localization("Share with your teammates what you will bring to the team and what you need from the team for each Learning Pattern.")
        },
        {
          src: require("@/assets/new-theme/smile.png"),
          title: this.localization("Team transparency"),
          description:
            this.localization("The statements you select for each Learning Pattern will appear to your teammates when you are working together.")
        },
        {
          src: require("@/assets/new-theme/target.png"),
          title: this.localization("Be accurate and detailed"),
          description:
            this.localization("Try to be as accurate and detailed as possible when selecting your answer.")
        }
      ],
      tabs: [],
      active: 0,
      activeTabs: [0],
      currentTab: 0,
      team_id: null,
      panel: [`Precision`, `Sequence`, "Technical Reasoning", "Confluence"],
      selected: []
    };
  },
  components:{
    TeamForm,
    TeamAnswers
  },
  computed: {
    ...mapGetters({
      userLoad: ["teams/userLoad"],
      questionOne: ["teams/questionOne"],
    }),
    careerMatchStatementsQuestions() {
      return this.$store.getters["user/getCareerMatchStatementsQuestions"];
    },
    mainUserId() {
      return this.auth().id;
    },
    questionTitle() {
      if(this.questionOne){
        return Object.keys(this.questionOne)[0];
      }
    }
  },
  methods: {
    hideAndShowSidebar() {
      this.hideShowSidebar = !this.hideShowSidebar;
      this.hideXIcon = !this.hideXIcon;
    },
    refresh(query) {
      this.$store.dispatch("teams/step1").then(_=>{
        this.tabs = Object.entries(this.questionOne)[0][1]
      });
    },
    isActive(index) {
      return this.activeTabs.includes(index);
    },
    updatePanel(index) {
    },
    switchTab(index) {
    },
    nextTab() {
      this.$store
        .dispatch("attributes/updateIntroducingStatements", {
          id: this.auth().id,
          query: { my_team_statements: this.selected }
        })
        .then(() => {
          this.$router.push({name:'step-2'})
        });
    },
    teamsAnswers(id) {
      this.team_id = id;
      this.$router.push({name:'step-2'})
    },
    prevTab() {
    },
    getMainUserData() {
      this.$store.dispatch("user/user", this.mainUserId).then(res => {
        if(res.user.is_own_team == 1 ){
          this.$router.push({ name:'step-3' });
        }
        if (
          res.user.my_career_statements &&
          res.user.my_career_statements != null &&
          res.user.my_career_statements != false
        ) {
          this.selected = JSON.parse(res.user.my_career_statements);
        } else {
          this.selected = [];
        }
      });
    },
    updateSelectedAttributes() {
      this.$store
        .dispatch("attributes/updateCareerMatchStatements", {
          id: this.mainUserId,
          query: { my_career_statements: this.selected }
        })
    },
    text_speech_audio(any_text) {
      this.$store
        .dispatch("user/get_text_to_speech_audio", { text: any_text })
        .then(res => {
          let audioBlob = new Blob([res], { type: "audio/mpeg" });
          let audioURL = URL.createObjectURL(audioBlob);
          let audio = document.getElementById("audio");
          var source = document.getElementById("audio-source");
          source.src = audioURL;
          audio.load();
          audio.play();
        });
    },
    getKey(any_key) {
      return any_key;
    }
  },
  beforeMount(){
    this.getMainUserData();
  },
  mounted() {
    this.refresh();
    this.$store.dispatch("user/get_all_teams_content");
  }
};
</script>

<style lang="scss" scoped>
.main_lci_wrapper {
  width: 100%;

  .render_action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main_layout {
    gap: 40px;
  }
  .cards_and_logo {
    background: #fcfcfd;
    max-width: 25%;
    min-width: 25%;
    padding: 20px;
    position: relative;
    .absolute_question_mark {
      position: fixed;
      left: 10px;
      top: 82%;
      &.make_absolute {
        position: absolute;
        bottom: 0;
      }
    }
    .cards_wrapper {
      margin-top: 40px;
    }
  }
  // Tabs
  .tabs_wrapper {
    flex-grow: 1;
    // background: #eee;
    .step_number_and_questions_number {
      display: flex;
      align-items: center;
      gap: 10px;
      .st_number_value{
        color: #101828;
        font-size: 22px;
        font-weight: 500;
      }
      .st_questions_number {
        color: #667085;
        font-size: 14px;
      }
      .st_number_value{
        p{
          font-size: 22px;
          font-weight: 500;
        }
      }
    }
    .tabs_steps {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      .step {
        height: 4px;
        border-radius: 8px;
        background-color: #e9d7fe;
        flex-grow: 1;
        // width: 180px;
        &.active {
          background-color: #7f56d9;
        }
      }
    }
  }
}
// Buttons Next And Previous
.lci_btn {
  background-color: #f2f4f7;
  color: #667085;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  width: 240px;
  &.save {
    background-color: #7f56d9;
    color: #fff;
  }
}

.prev_next {
  background-color: #f2f4f7;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  color: #667085;
  width: 70px;
}
.mark_tooltip {
  border: 1px solid #d0d5dd;
  width: fit-content;
  padding: 12px;
  border-radius: 50%;
}

.main_profile_personalize_and_collapse{
  position: relative;
}

.main_profile_personalize_and_collapse::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-size: contain;
  opacity: 0.5; 
  z-index: -1;
  pointer-events: none;
}

.main_profile_personalize_and_collapse {
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), 
              url('../../../assets/new-theme/lci/background.png') no-repeat right center;
  background-size: contain;
}
</style>

<style lang="scss">
.main_profile_personalize_and_collapse {
  .el-collapse {
    border: none !important;
  }
  .el-collapse-item__content {
    box-shadow: none !important;
    border: none !important;

    // @media print {
    //   padding-bottom: 0;
    // }
  }
  .el-collapse-item__wrap {
    border-bottom: none !important;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
    font-size: 16px;
    line-height: inherit;
    color: #344054 !important;
  }
  .el-checkbox__inner {
    border: 1px solid #d0d5dd;
    border-radius: 6px;
    height: 20px;
    width: 20px;
  }
  .el-checkbox {
    align-items: flex-start;
    display: flex;
  }
  .el-checkbox__label,
  .el-checkbox is-checked {
    color: #344054 !important;
    font-weight: 400;
    font-size: 16px;

    // max-width: 98%;
    word-break: break-word !important;
    white-space: pre-wrap;
    // word-wrap: break-word;
    white-space: normal;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #6941c6;
    border-color: #6941c6;
  }
  .el-checkbox__inner::after {
    left: 7px;
    top: 4px;
    border-width: 2px;
  }
}
</style>
