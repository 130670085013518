import request from '../utils/request';

export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data: data,
  });
}


export function logout(token) {
  return request({
    url: '/logout',
    method: 'post',
  });
}

export function Register(data, contact, team, token) {
  if (contact != null || undefined && team != null || undefined && token != null || undefined) {
    return request({
      url: `/register/${team}/${token}`,
      method: 'post',
      data: data
    });
  } else {
    return request({
      url: `/registeration`,
      method: 'post',
      data: data
    });
  }
}

export function registerData(data) {
  return request({
    url: 'user/register/data',
    method: 'post',
    params: data
  });
}


// export function RegisterFamily(data) {
//   return request({
//     url: `/register`,
//     method: 'post',
//     data: data
//   });
// }
