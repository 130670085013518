// import {GetQuestions, SaveQuestions,deleteQuestion} from '../../api/questionnaire'; // getInfo
import Contacts from "@/api/contacts"; // getInfo
// import {Auth, deleteToken, removeAuth, removeToken, resetPermission, setLogged, setToken} from '../../utils/auth';

const contacts = new Contacts();

const contactsModule = {
  namespaced: true,
  state: {
    usersLoad: false,
    contacts: [],
    topics: [],
    totalTopics: null,
    searchContacts: [],
    restoredContacts: [],
    my_contact_count: null,
    pending_count: null,
    received_count: null,
    // new code
    contactList: [],
    totalContactList: null,
    // for add new contact
    newsContacts: []
  },
  getters: {
    contacts: state => {
      return state.contacts;
    },
    // new code
    contactList: state => {
      return state.contactList;
    },
    totalContactList: state => {
      return state.totalContactList;
    },
    newsContacts: state => {
      return state.newsContacts;
    },
    // forms: (state) => {
    //   return state.forms
    // },
    totalTopics: state => {
      return state.totalTopics;
    },
    usersLoad: state => {
      return state.usersLoad;
    },
    searchContacts: state => state.searchContacts,
    restoredContacts: state => state.restoredContacts,
    myContactCount: state => {
      return  state.my_contact_count
    },
    pendingCount: state => state.pending_count,
    receivedCount: state => state.received_count,
  },

  mutations: {
    SET_CONTACTS: (state, contacts) => {
      state.contacts = contacts;
    },

    SET_TOTAL_TOPICS: (state, totalTopics) => {
      state.totalTopics = totalTopics;
    },

    SET_SEARCH_CONTACTS: (state, searchContacts) => {
      state.searchContacts = searchContacts;
    },

    SET_RESTORED_CONTACTS: (state, restoredContacts) => {
      state.restoredContacts = restoredContacts;
    },
    // new code
    GET_CONTACTS_LIST: (state, list) => {
      state.contactList = list;
    },
    SET_TOTAL_CONTACTS_LIST: (state, lists) => {
      state.totalContactList = lists;
    },
    SET_MyContactCount: (state, lists) => {
      state.my_contact_count = lists;
    },
    SET_PendingCount: (state, lists) => {
      state.pending_count = lists;
    },
    SET_ReceivedCount: (state, lists) => {
      state.received_count = lists;
    },
    ADD_NEW_CONTACTS: (state, newContact) => {
      state.newsContacts = newContact;
    },
    // SET_FORMS: (state, forms) => {
    //   state.forms = forms;
    // },
    // SET_USER: (state, user) => {
    //   state.user = user;
    // },
    // SET_GROUP_ID: (state, group_id) => {
    //   if (group_id == 1) {
    //     state.user.age_group_id = "6-12 years old";
    //   }
    //   if (group_id == 2) {
    //     state.user.age_group_id = "13 -21 years old";
    //   }

    //   if (group_id == 1) {
    //     state.user.age_group_id = "22 or older";
    //   }

    // },
    // SET_GROUP_NAME: (state, group_name) => {
    //   if (group_name == "6-12 years old") {
    //     state.user.age_group_id = 1;
    //   }
    //   if (group_name == "13 -21 years old") {
    //     state.user.age_group_id = 2;
    //   }

    //   if (group_name == "22 or older") {
    //     state.user.age_group_id = 3;
    //   }
    // },
    // SET_LOAD_USER: (state, load) => {
    //   state.userLoad = load;
    // },
    // SET_TOTAL_USERS: (state, total) => {
    //   state.totalUsers = total;
    // },
    SET_USERS_LOAD: (state, load) => {
      state.usersLoad = load;
    }
    // SET_COURSES: (state, courses) => {
    //   state.courses = courses;
    // },
    // SET_TASKS: (state, tasks) => {
    //   state.tasks = tasks;
    // },
  },
  actions: {
    GetContacts({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        contacts
          .contacts()
          .then(response => {
            commit("SET_CONTACTS", response.contacts);
            // commit('SET_TOTAL_TOPICS', response.count);

            commit("SET_USERS_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    UpdateContact({ commit }, payload) {
      return new Promise((resolve, reject) => {
        contacts
          .update(payload.query, payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    AddContact({ commit }, payload) {

      return new Promise((resolve, reject) => {
        contacts
          .add(payload.query)

          // form.add(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        contacts
          .delete_contact(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getSearchContacts({ commit }, payload) {

      return new Promise((resolve, reject) => {
        // commit("SET_USERS_LOAD", true);
        contacts
          .search_contacts(payload)
          .then(response => {

            commit("SET_SEARCH_CONTACTS", response.users);

            resolve();
            // commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            // commit("SET_USERS_LOAD", false);
          });
      });
    },

    // get restored contacts
    GetRestoredContacts({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        contacts
          .restored_contacts(payload.query)
          .then(response => {

            // commit("SET_CONTACTS", response.contacts);
            // commit('SET_TOTAL_TOPICS', response.count);
            commit("SET_USERS_LOAD", false);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addNewContact({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        contacts
          .add_new_contact(payload.id, payload.status)
          .then(response => {

            resolve();
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    // new code
    addNewContacts({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        contacts
          .add_new_contacts(payload)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    //? invite_restored_contact
    invite_restored_contact({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        contacts
          .invite_restored_contact(payload)
          .then(response => {
            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    GetContactsList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        contacts
          .contactsList(payload.query)
          .then(response => {
            commit("GET_CONTACTS_LIST", response.users);
            commit("SET_TOTAL_CONTACTS_LIST", response.count);
            commit("SET_MyContactCount", response.my_contact_count);
            commit("SET_PendingCount", response.pending_count);
            commit("SET_ReceivedCount", response.received_count);
            commit("SET_USERS_LOAD", false);

            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    changeContactStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        contacts
          .change_contact_status(payload.id, payload.status)
          .then(response => {

            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    },
    acceptUserInvitation({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        contacts
          .accept_invitation(id)
          .then(response => {

            resolve(response);
            commit("SET_USERS_LOAD", false);
          })
          .catch(error => {
            reject(error);
            commit("SET_USERS_LOAD", false);
          });
      });
    }
  }
};

export default contactsModule;
