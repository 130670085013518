import html2pdf from "html2pdf.js";

export const generatePdf = async (elementSelector, hiddenElementsSelector) => {

  const element = document.querySelector(elementSelector);


  const hiddenElements = element.querySelectorAll(hiddenElementsSelector);
  hiddenElements.forEach(el => (el.style.display = "none"));


  const options = {
    margin: 10,
    filename: "task-details.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 1, useCORS: true },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    enableLinks: true
  };

  const pdf = await html2pdf()
    .set(options)
    .from(element)
    .output("blob");


  hiddenElements.forEach(el => (el.style.display = ""));

  return new Blob([pdf], { type: "application/pdf" });
};
