<template>
  <div
    class="wrapper class_for_side_tooltips"
    :class="{ 'nav-open': $sidebar.showSidebar }"
  >
    <notifications></notifications>
    <!-- v-if="showNavbar || showNavbar" -->
    <side-bar v-if="showNavbar || showSidebar">
      <template slot="links">
        <div v-if="hasPermission('old_theme')">
          <!-- Consider it as Profile PDF  -->
          <div class="wrapper_for_side_items pt-3">
            <sidebar-item
              v-if="
                hasPermission('access_dashboard') &&
                  hasPermission('show_old_tabs')
              "
              :link="{
                name: `${
                  localization('Admin Dashboard') != false
                    ? localization('Admin Dashboard')
                    : 'Admin Dashboard'
                }`,
                icon: 'nc-icon nc-chart-pie-35 font-weight-800',
                path: '/overview'
              }"
            ></sidebar-item>
          </div>

          <!-- Consider it as Profile PDF  -->

          <!--//// Start User Dashboard Items In Medium And Large Screen // PROFILE  -->
          <div v-if="auth()" class="finally_handler_sidebar">
            <div @click="testHello" v-if="hasPermission('show_old_tabs')">
              <!-- <div class="pp_one">
              <i class="el-icon-user for_tt_icon font-weight-600"></i>
              <span class="for_tt_name">{{
                localization("My Account") != false
                  ? localization("My Account")
                  : "My Account"
              }}</span>
            </div>
            <div class="pp_two">
              <i
                class="el-icon-caret-bottom handel_top_bottom"
                v-if="switchCollapse == false"
              ></i>
              <i
                class="el-icon-caret-top handel_top_bottom"
                v-if="switchCollapse == true"
              ></i>
            </div> -->
              <sidebar-item
                :link="{
                  name: `${
                    localization('My Account') != false
                      ? localization('My Account')
                      : 'My Account'
                  }`,
                  icon: 'el-icon-user font-weight-800',
                  path: '#'
                }"
              ></sidebar-item>
            </div>
            <div v-if="switchCollapse" class="switchCollapse_items">
              <!-- Start LCI  -->
              <el-tooltip
                class="item"
                :content="`${localization('Complete the Learning Connections Inventory and discover how your mind helps you learn, complete tasks, and work with others.')}`"
                placement="right-start"
                effect="dark"
              >
                <div class="handle_sidebar_tooltips ">
                  <router-link
                    v-if="hasPermission('access_lci') || hasPermission('guest')"
                    class="just_style_if_sidebar"
                    to="/edit-questionnaire"
                    >{{
                      localization("LCI") != false ? localization("LCI") : "LCI"
                    }}</router-link
                  >
                </div>
              </el-tooltip>

              <!-- Start Personal Learning Profile  -->
              <el-tooltip
                class="item"
                :content="`${localization('Your Personal Learning Profile results will show your Learning Patterns by type and degree of use.')}`"
                placement="right-start"
                effect="dark"
              >
                <div class="handle_sidebar_tooltips ">
                  <router-link to="/profile" class="just_style_if_sidebar">{{
                    localization("Personal Learning Profile") != false
                      ? localization("Personal Learning Profile")
                      : "Personal Learning Profile"
                  }}</router-link>
                </div>
              </el-tooltip>

              <!-- Edit Account -->
              <el-tooltip
                class="item"
                :content="`${localization('Edit your account, change your password, etc.')}`"
                placement="right-start"
              >
                <div class="handle_sidebar_tooltips ">
                  <router-link to="/edit-user" class="just_style_if_sidebar">{{
                    localization("Edit Account") != false
                      ? localization("Edit Account")
                      : "Edit Account"
                  }}</router-link>
                </div>
              </el-tooltip>
            </div>
          </div>
          <!--//// End User Dashboard Items // PROFILE  -->

          <div
            class="wrapper_for_side_items"
            v-if="
              userScore.confluence != null &&
                userScore.precision != null &&
                userScore.sequence != null &&
                userScore.technical_reasoning != null
            "
          >
            <sidebar-item
              v-if="hasPermission('show_old_tabs')"
              :link="{
                name: `${
                  localization('My Dashboard') != false
                    ? localization('My Dashboard')
                    : 'My Dashboard'
                }`,
                icon: 'nc-icon nc-chart-pie-35 font-weight-800',
                path: '/my-dashboard'
              }"
            ></sidebar-item>
          </div>
          <!--//? Start User Dashboard Items In Mobile Screen // PROFILE -->
          <sidebar-item
            class="just_display_if_mobile"
            v-if="
              (auth().family_type == 1 || auth().family_type == 2) &&
                hasPermission('show_old_tabs')
            "
            :link="{
              name: `${
                localization('My Account') != false
                  ? localization('My Account')
                  : 'My Account'
              }`,
              icon: 'el-icon-users'
            }"
          >
            <!-- Start LCI  -->
            <sidebar-item
              v-if="hasPermission('access_lci') || hasPermission('guest')"
              :link="{
                name: `${
                  localization('LCI') != false ? localization('LCI') : 'LCI'
                }`,
                path: '/edit-questionnaire'
              }"
            ></sidebar-item>
            <!-- Start Personal Learning Profile  -->
            <sidebar-item
              :link="{
                name: `${
                  localization('Personal Learning Profile') != false
                    ? localization('Personal Learning Profile')
                    : 'Personal Learning Profile'
                }`,
                path: '/profile'
              }"
            ></sidebar-item>

            <sidebar-item
              :link="{
                name: `${
                  localization('Edit Account') != false
                    ? localization('Edit Account')
                    : 'Edit Account'
                }`,
                path: '/edit-user'
              }"
            ></sidebar-item>
          </sidebar-item>
          <!--//? End User Dashboard Items In Mobile Screen // PROFILE -->

          <!--//todo: Start Use Patterns Dropdown  -->
          <!-- [ Start In Medium And Large Screen ] -->
          <div
            class="finally_handler_sidebar"
            :class="{ 'd-none': hasPermission('guest') }"
          >
            <div @click="testHello2">
              <!-- <div class="pp_one">
              <i class="nc-icon nc-ruler-pencil for_tt_icon font-weight-600"></i>
              <span class="for_tt_name">{{
                localization("Use Your Patterns") != false
                  ? localization("Use Your Patterns")
                  : "Use Your Patterns"
              }}</span>
            </div>
            <div class="pp_two">
              <i
                class="el-icon-caret-bottom handel_top_bottom"
                v-if="switchCollapse2 == false"
              ></i>
              <i
                class="el-icon-caret-top handel_top_bottom"
                v-if="switchCollapse2 == true"
              ></i>
            </div> -->

              <sidebar-item
                v-if="hasPermission('show_old_tabs')"
                :link="{
                  name: `${
                    localization('Use Your Patterns') != false
                      ? localization('Use Your Patterns')
                      : 'Use Your Patterns'
                  }`,
                  icon: 'el-icon-user font-weight-800',
                  path: '##'
                }"
              ></sidebar-item>
            </div>
            <div v-if="switchCollapse2" class="switchCollapse_items">
              <!-- Start Tasks  -->
              <el-tooltip
                class="item"
                :content="`${localization('Select the personal strategies you need to succeed in completing a learning assignment!')}`"
                placement="right-start"
                effect="dark"
              >
                <div class="handle_sidebar_tooltips ">
                  <router-link class="just_style_if_sidebar" to="/tasks">{{
                    localization("School Strategies") != false
                      ? localization("School Strategies")
                      : "School Strategies"
                  }}</router-link>
                </div>
              </el-tooltip>
              <!-- Start At Work  -->
              <el-tooltip
                class="item"
                :content="`${localization('Select the personal strategies you need to succeed in completing a work assignment!')}`"
                placement="right-start"
                effect="dark"
              >
                <div class="handle_sidebar_tooltips ">
                  <router-link class="just_style_if_sidebar" to="/at-work">{{
                    localization("Work Strategies") != false
                      ? localization("Work Strategies")
                      : "Work Strategies"
                  }}</router-link>
                </div>
              </el-tooltip>

              <!-- Start Career Match   -->
              <el-tooltip
                class="item"
                :content="
                  `${localization('See if a job is the right fit for you!')}`
                "
                placement="right-start"
                effect="dark"
              >
                <div class="handle_sidebar_tooltips ">
                  <router-link
                    class="just_style_if_sidebar"
                    to="/career-match-questions"
                    >{{
                      localization("Career Match") != false
                        ? localization("Career Match")
                        : "Career Match"
                    }}</router-link
                  >
                </div>
              </el-tooltip>
              <!-- Career Match Questions -->
            </div>
          </div>
          <!-- [ End In Medium And Large Screen ] -->

          <!-- [In Mobile Screen] -->
          <sidebar-item
            class="just_display_if_mobile"
            :link="{
              name: `${
                localization('Use Your Patterns') != false
                  ? localization('Use Your Patterns')
                  : 'Use Your Patterns'
              }`,
              icon: 'nc-icon nc-ruler-pencil'
            }"
            :class="{ 'd-none': hasPermission('guest') }"
          >
            <!-- Start In School  -->
            <sidebar-item
              :link="{
                name: `${
                  localization('School Strategies') != false
                    ? localization('School Strategies')
                    : 'School Strategies'
                }`,
                path: '/tasks'
              }"
            ></sidebar-item>
            <!-- Start At Work  -->
            <sidebar-item
              :link="{
                name: `${
                  localization('Work Strategies') != false
                    ? localization('Work Strategies')
                    : 'Work Strategies'
                }`,
                path: '/at-work'
              }"
            ></sidebar-item>
            <!-- Start  Career Match  -->
            <sidebar-item
              :link="{
                name: `${
                  localization('Career Match') != false
                    ? localization('Career Match')
                    : 'Career Match'
                }`,
                path: '/career-match-questions'
              }"
            ></sidebar-item>
          </sidebar-item>
          <!--//todo: End Use Patterns Dropdown [In Mobile Screen]-->

          <!-- Start Teams -->
          <el-tooltip
            v-if="hasPermission('show_old_tabs')"
            class="item just_display_if_not_mobile"
            :content="`${localization('Build a team and learn how to use the groups Learning Patterns to work better together!')}`"
            placement="right-start"
            :class="{ 'd-none': hasPermission('guest') }"
          >
            <sidebar-item
              :link="{
                name: `${
                  localization('Teams') != false
                    ? localization('Teams')
                    : 'Teams'
                }`,
                icon: 'el-icon-collection font-weight-800',
                path: '/teams'
              }"
            ></sidebar-item>
          </el-tooltip>
          <div
            class="just_display_if_this_mobile"
            :class="{ 'd-none': hasPermission('guest') }"
          >
            <sidebar-item
              :link="{
                name: `${
                  localization('Teams') != false
                    ? localization('Teams')
                    : 'Teams'
                }`,
                icon: 'el-icon-collection',
                path: '/teams'
              }"
            ></sidebar-item>
          </div>
          <!-- End Teams -->
          <!-- Start Contacts -->
          <el-tooltip
            v-if="hasPermission('show_old_tabs')"
            class="item just_display_if_not_mobile"
            :content="`${localization('Invite your classmates, friends, business contacts to share their LCI scores with you and build your opportunity to share strategies!')}`"
            placement="right-start"
            :class="{ 'd-none': hasPermission('guest') }"
          >
            <sidebar-item
              :link="{
                name: `${
                  localization('Contacts') != false
                    ? localization('Contacts')
                    : 'Contacts'
                }`,
                icon: 'nc-icon nc-credit-card font-weight-800',
                path: '/contacts'
              }"
            ></sidebar-item>
          </el-tooltip>
          <div
            class="just_display_if_this_mobile"
            :class="{ 'd-none': hasPermission('guest') }"
          >
            <sidebar-item
              :link="{
                name: `${
                  localization('Contacts') != false
                    ? localization('Contacts')
                    : 'Contacts'
                }`,
                icon: 'nc-icon nc-credit-card',
                path: '/contacts'
              }"
            ></sidebar-item>
          </div>
          <!-- End Contacts -->

          <!-- Start Groups -->
          <el-tooltip
            v-if="hasPermission('show_old_tabs')"
            class="item just_display_if_not_mobile"
            :content="`${localization('Organize your contacts into groups and see the group dynamic.')}`"
            placement="right-start"
            :class="{ 'd-none': hasPermission('guest') }"
          >
            <sidebar-item
              :link="{
                name: `${
                  localization('Groups') != false
                    ? localization('Groups')
                    : 'Groups'
                }`,
                icon: 'nc-icon nc-paper-2 font-weight-800',
                path: '/groups'
              }"
            ></sidebar-item>
          </el-tooltip>
          <div
            class="just_display_if_this_mobile"
            :class="{ 'd-none': hasPermission('guest') }"
          >
            <sidebar-item
              :link="{
                name: `${
                  localization('Groups') != false
                    ? localization('Groups')
                    : 'Groups'
                }`,
                icon: 'nc-icon nc-paper-2',
                path: '/groups'
              }"
            ></sidebar-item>
          </div>
          <!-- End Groups -->
          <sidebar-item
            v-if="
              hasPermission('access_institutions') &&
                hasPermission('show_old_tabs')
            "
            :link="{
              name: `${
                localization('Institutions') != false
                  ? localization('Institutions')
                  : 'Institutions'
              }`,
              icon: 'nc-icon nc-bank font-weight-800',
              path: '/institution'
            }"
          ></sidebar-item>
          <sidebar-item
            v-if="
              hasPermission('access_topics') &&
                hasPermission('access_strategies') &&
                hasPermission('access_words') &&
                hasPermission('access_questions') &&
                hasPermission('access_cms') &&
                hasPermission('access_roles') &&
                hasPermission('access_logs') &&
                hasPermission('show_old_tabs')
            "
            :link="{
              name: `${
                localization('Management') != false
                  ? localization('Management')
                  : 'Management'
              }`,
              icon: 'nc-icon nc-settings-90 font-weight-800',
              path: '/management'
            }"
          ></sidebar-item>

          <sidebar-item
            v-if="hasPermission('manage_institutions')"
            :link="{
              name: `User Categories`,
              icon: 'nc-icon nc-settings-90',
              path: '/user-categories'
            }"
          ></sidebar-item>

          <!-- Start How will the LCI help you? -->
          <el-tooltip
            v-if="hasPermission('show_old_tabs')"
            class="item just_display_if_not_mobile"
            :content="`${localization('How else can you use the LCI? Explore more here.')}`"
            placement="right-start"
            :class="{ 'd-none': hasPermission('guest') }"
          >
            <sidebar-item
              :link="{
                name: `${
                  localization('How will the LCI help you?') != false
                    ? localization('How will the LCI help you?')
                    : 'How will the LCI help you?'
                }`,
                icon: 'el-icon-question',
                path: '/lci-help-you'
              }"
            ></sidebar-item>
          </el-tooltip>
          <div
            class="just_display_if_this_mobile"
            :class="{ 'd-none': hasPermission('guest') }"
          >
            <sidebar-item
              :link="{
                name: `${
                  localization('How will the LCI help you?') != false
                    ? localization('How will the LCI help you?')
                    : 'how will the lci help you'
                }`,
                icon: 'el-icon-question',
                path: '/lci-help-you'
              }"
            ></sidebar-item>
          </div>
          <!-- End How will the LCI help you? -->

          <!-- Start Videos -->
          <el-tooltip
            v-if="hasPermission('show_old_tabs')"
            class="item just_display_if_not_mobile"
            :content="`${localization('If you need more explanations of how parts of the PLC work, check out our videos!')}`"
            placement="right-start"
          >
            <sidebar-item
              :link="{
                name: `${
                  localization('Videos') != false
                    ? localization('Videos')
                    : 'Videos'
                }`,
                icon: 'el-icon-video-play font-weight-800',
                path: '/videos'
              }"
            ></sidebar-item>
          </el-tooltip>
          <div class="just_display_if_this_mobile">
            <sidebar-item
              :link="{
                name: `${
                  localization('Videos') != false
                    ? localization('Videos')
                    : 'Videos'
                }`,
                icon: 'el-icon-video-play',
                path: '/videos'
              }"
            ></sidebar-item>
          </div>
          <!-- End Videos -->
          <sidebar-item
            v-if="
              hasPermission('access_words') && hasPermission('show_old_tabs')
            "
            :link="{
              name: `${
                localization('Families') != false
                  ? localization('Families')
                  : 'Families'
              }`,
              icon: 'nc-icon nc-bank font-weight-800',
              path: '/families'
            }"
          ></sidebar-item>
        </div>
        <!-- NEW BUTTON NEW THEME -->
        <sidebar-item
          :link="{
            name: `${
                localization('My Account') != false
                  ? localization('My Account')
                  : 'My Account'
              }`,
            icon: 'nc-icon nc-single-02 font-weight-800',
            path: '/new-theme/profile'
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="
            hasPermission('access_users')
          "
          :link="{
            name: `${
              localization('Users') != false ? localization('Users') : 'Users'
            }`,
            icon: 'fa fa-users',
            path: '/users'
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="hasPermission('access_lci')"
          :link="{
            name: `${
                localization('lCI') != false
                  ? localization('lCI')
                  : 'lCI'
              }`,
            icon: 'nc-icon nc-single-copy-04 font-weight-800',
            path: '/new-theme/instructions'
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: `${
                localization('Personalized Profile') != false
                  ? localization('Personalized Profile')
                  : 'Personalized Profile'
              }`,
            icon: 'nc-icon nc-badge font-weight-800',
            path: '/new-theme/plp'
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="hasPermission('access_tasks')"
          :link="{
            name: `${
                localization('School Strategies') != false
                  ? localization('School Strategies')
                  : 'School Strategies'
              }`,
            icon: 'nc-icon nc-ruler-pencil font-weight-800',
            path: '/new-theme/tasks'
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: `${
                localization('Work Strategies') != false
                  ? localization('Work Strategies')
                  : 'Work Strategies'
              }`,
            icon: 'nc-icon nc-bank font-weight-800',
            path: '/new-theme/at-work'
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="hasPermission('access_career')"
          :link="{
            name: `${
                localization('Career Match') != false
                  ? localization('Career Match')
                  : 'Career Match'
              }`,
            icon: 'nc-icon nc-bag font-weight-800',
            path: '/new-theme/career'
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="hasPermission('access_pwp')"
          :link="{
            name: `${
                localization('PWP') != false
                  ? localization('PWP')
                  : 'PWP'
              }`,
            icon: 'nc-icon nc-notes font-weight-800',
            path: '/new-theme/pwp'
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="hasPermission('access_team')"
          :link="{
            name: `${
                localization('Teams') != false
                  ? localization('Teams')
                  : 'Teams'
              }`,
            icon: 'nc-icon nc-grid-45 font-weight-800',
            path: '/new-theme/teams'
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="hasPermission('access_families')"
          :link="{
            name: `${
                localization('Family Members') != false
                  ? localization('Family Members')
                  : 'Family Members'
              }`,
            icon: 'el-icon-user font-weight-800',
            path: '/family-members'
          }"
        ></sidebar-item>
        <el-tooltip
          class="item just_display_if_not_mobile"
          :content="`${localization('Organize your contacts into groups and see the group dynamic.')}`"
          placement="right-start"
          :class="{ 'd-none': hasPermission('guest') }"
        >
          <sidebar-item
            :link="{
              name: `${
                localization('Groups') != false
                  ? localization('Groups')
                  : 'Groups'
              }`,
              icon: 'nc-icon nc-grid-45 font-weight-800',
              path: '/new-theme/groups'
            }"
          ></sidebar-item>
        </el-tooltip>
        <sidebar-item
          :link="{
            name: `${
                localization('Contacts') != false
                  ? localization('Contacts')
                  : 'Contacts'
              }`,
            icon: 'nc-icon nc-email-85 font-weight-800',
            path: '/new-theme/contacts'
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: `${
              localization('Logout') != false
                ? localization('Logout')
                : 'Logout'
            }`,
            icon: 'fa fa-sign-out ',
            path: '/logout'
          }"
        ></sidebar-item>
      </template>
    </side-bar>
    <div
      class="main-panel"
      :class="{ new_panel_for_new_theme: showSidebar == false }"
      v-loading="$store.getters['roles/rolesLoad']"
    >
      <top-navbar v-if="showNavbar || showSidebar"></top-navbar>
      <!-- v-if="showNavbar || showNavbar" -->
      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer v-if="showNavbar || showSidebar"></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
// import PerfectScrollbar from 'perfect-scrollbar';
// import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  data() {
    return {
      navbarObj: {},
      switchCollapse: false,
      switchCollapse2: false
    };
  },
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
    // UserMenu
  },
  watch: {
    $route(to, from) {
      if (this.auth()) {
        this.$store
          .dispatch("roles/getAuthUserRolesPermissions", this.auth().id)
          .then(res => {
            // if (!this.hasPermission("guest")) {
            //   if (
            //     this.$store.getters["roles/authUser"].sequence == null ||
            //     this.$store.getters["roles/authUser"].precision == null ||
            //     this.$store.getters["roles/authUser"].technical_reasoning ==
            //       null ||
            //     this.$store.getters["roles/authUser"].confluence == null
            //   ) {
            //     // check to & from type
            //     // user need to be redirected to the before page if no LCI score
            //     if (from.path !== "/edit-questionnaire") {
            //       // if (from.path !== "/before") {
            //       // If no score redirect to the Before page
            //       this.$router.push(`/edit-questionnaire`);
            //       // this.$router.push(`/before`);
            //     } else {
            //       // if user is in the Before page, then allow redirect to the LCI page
            //       this.$router.push(`/edit-questionnaire`);
            //     }
            //   }
            // }
          });
      }
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },

    initScrollbar() {
      // let docClasses = document.body.classList;
      // let isWindows = navigator.platform.startsWith('Win');
      // if (isWindows) {
      //   // if we are on windows OS we activate the perfectScrollbar function
      //   initScrollbar('main-panel');
      //
      //   docClasses.add('perfect-scrollbar-on');
      // } else {
      //   docClasses.add('perfect-scrollbar-off');
      // }
    },
    testHello() {
      this.switchCollapse = !this.switchCollapse;
    },
    testHello2() {
      this.switchCollapse2 = !this.switchCollapse2;
    }
  },
  computed: {
    id() {
      // return this.auth().id;
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    authUserPermission() {
      return this.$store.getters["roles/authUserRolesPermissionsName"];
    },
    // Hide sidebar and navbar
    showSidebar() {
      return !this.$route.meta.hideSidebar;
    },
    showNavbar() {
      return !this.$route.meta.hideNavbar;
    }
  },
  mounted() {
    // this.initScrollbar()
    if (this.auth()) {
      this.$store
        .dispatch("roles/getAuthUserRolesPermissions", this.auth().id)
        .then(_ => {
          // if (
          //   this.$store.getters["roles/authUser"].sequence == null ||
          //   this.$store.getters["roles/authUser"].precision == null ||
          //   this.$store.getters["roles/authUser"].technical_reasoning == null ||
          //   this.$store.getters["roles/authUser"].confluence == null
          // ) {
          //   this.$router.push(`/before`);
          // }
        });
      this.$store.dispatch("user/user", this.id);
    }
  }
};
</script>

<style>
.swal2-container.swal2-bottom,
.swal2-container.swal2-center,
.swal2-container.swal2-top {
  grid-template-columns: auto minmax(0, 1fr) auto;
  z-index: 100000;
}
</style>

<style scoped lang="scss">
.main-panel {
  background: rgba(203, 203, 210, 0.15) !important;
  height: auto !important;
  // height: calc(100% - 78px) !important;

  &.new_panel_for_new_theme {
    background: #fff !important;
    width: 100% !important;
    padding: 0 30px;
  }
}
.just_display_if_mobile {
  display: none;
  @media (min-width: 320px) and (max-width: 700px) {
    display: block;
  }
}

.just_display_if_this_mobile {
  display: none;
  @media (min-width: 320px) and (max-width: 700px) {
    display: block;
  }
}
.finally_handler_sidebar {
  @media (min-width: 320px) and (max-width: 700px) {
    display: none;
  }
  .switch_between_arrows {
    display: flex;
    justify-content: space-between;
    // padding: 10px 22px;
    margin: 5px 0 0 0;
    opacity: 0.86;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background-color: #f9f5ff;
      color: #6941c6;
      opacity: 1;
    }
    .pp_one {
      display: flex;
      .for_tt_name {
        margin-left: 15px;
        font-size: 12px;
        font-weight: 600;
        text-transform: capitalize;
        opacity: 0.86;
      }
      .for_tt_icon {
        font-size: 17px;
        color: #667085 !important;
      }
    }
    .pp_two {
      .handel_top_bottom {
        font-size: 12px;
        margin-top: 20px;
        margin-right: 10px;
      }
    }
  }
  .handle_sidebar_tooltips {
    // padding: 5px 0;
    // margin: 5px 0 0 0;
    padding: 0 15px;
    .just_style_if_sidebar {
      display: block;
      color: #101828;
      // margin: 5px 15px 0px 10px;
      margin: 5px 0 0 0;
      opacity: 0.86;
      border-radius: 4px;
      text-transform: capitalize;
      line-height: 30px;
      font-size: 12px;
      font-weight: 400;
      padding: 5px 0 5px 25px;
      white-space: nowrap;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      &:hover {
        background-color: #f9f5ff !important;
        opacity: 1;
      }
      &.router-link-exact-active {
        background-color: #f9f5ff !important;
        color: #6941c6;
        opacity: 1;
      }
    }
  }
  .switchCollapse_items {
    transition: 0.5s;
  }
}
.font-weight-600 {
  font-weight: 600 !important;
}
::v-deep .font-weight-800 {
  font-weight: 800 !important;
}
</style>

<style lang="scss">
.el-tooltip__popper.is-dark {
  background-color: #f9f5ff !important;
  color: #6941c6 !important;
  max-width: 300px;

  &::after {
    border-right-color: #f9f5ff !important;
  }

  // }
}
.el-tooltip__popper[x-placement^="right"] .popper__arrow {
  border-right-color: #f9f5ff !important;
}
.just_display_if_not_mobile {
  display: none;
  @media (min-width: 700px) {
    display: block;
  }
}
</style>
