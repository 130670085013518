<template>
  <div class="empty-state">
    <img src="@/assets/new-theme/Illustration.png" alt="search" v-if="search" />
    <img src="@/assets/new-theme/no_results_Illustration.png" alt="search" v-else />
    
    <p class="h2" v-if="search">No {{ viewName }} Found</p>
    <div v-else style="width: 400px;" class="mx-auto">
      <p class="h2 m-0 p-0 mb-3">There are no {{ viewName }} here!</p>
      <div class="text-muted" style="line-height: 20px;">
        You can simply add new task and discover what patterns are needed to successfully complete it.
      </div>
    </div>
    <p v-if="search">
      Your search “{{ search }}” did not match any {{ viewName }}. Please Check
      <br />
      your inputs and try again.
    </p>
    <div class="action-buttons">
      <el-button
        @click="clearSearch"
        v-if="clearText && search"
        class="mr-2 clear-search-button"
      >
        {{ clearText }}
      </el-button>
      <AppButton
        @click="addNew"
        :text="addNewText"
        v-if="addNewText"
      ></AppButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableEmptySection",
  props: {

    title: {
      type: String,
      default: ""
    },
    viewName: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    },
    search: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    addNewText: {
      type: String,
      default: ""
    },
    clearText: {
      type: String,
      default: ""
    },
    total: {
      type: [String, Number],
      default: "",
      required: false
    }
  },
  methods: {
    clearSearch() {
      this.$emit("clearSearch");
    },
    
    addNew() {
      this.$emit("toggleModal");
    }
  }
};
</script>

<style lang="scss" scoped>
.empty-state {
  margin: 50px 0;
  color: rgba(16, 24, 40, 1);
  img {
    width: 170px;
    height: auto;
  }

  .h2 {
    margin: 15px 0 15px;
    font-size: 22px;
    color: rgba(16, 24, 40, 1);
  }

  p {
    margin-bottom: 30px;
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
  }
}

.action-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  .clear-search-button {
    background-color: #f8f9fa;
    color: #495057;
    width: 187px;
    height: 41px;
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.5;
    box-shadow: none;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }

  .clear-search-button:hover {
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }
}
</style>
