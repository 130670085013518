<template>
  <div
    class="sidebar"
    :style="sidebarStyle"
    :data-color="backgroundColor"
    :data-image="backgroundImage"
    :data-active-color="activeColor"
  >
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <div class="logo plc_main_sidebar_logo pb-3">
        <a  :class="$sidebar.isMinimized ? 'main_mini_link_logo d-block' : 'logo-mini main_mini_link_logo d-block'" href="/">
          <div class="main_logo_content_container">
            <img
              v-if="!$sidebar.isMinimized"
              src="../../assets/new-theme/PLC-Logo.png"
              alt="logo"
              class="main_img_logo"
            />
            <img
              v-else
              src="../../assets/new-theme/Logo-Icon.png"
              alt="logo"
            />
            <a
              href="/"
              class="simple-text logo-normal logo_normal_title d-block"
            >
              {{ title }}
            </a>
          </div>
        </a>
      </div>
      <!-- Start Sidebar style="margin-top: 20px; height: 200px;"  -->

      <!-- End Sidebar  -->

      <slot> </slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  name: "sidebar",
  props: {
    title: {
      type: String,
      default: "PLC"
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: value => {
        let acceptedValues = [
          "white",
          "blue",
          "azure",
          "green",
          "orange",
          "red",
          "purple",
          "black"
        ];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    backgroundImage: {
      type: String,
      default: "/static/img/sidebar-5.jpg"
    },
    activeColor: {
      type: String,
      default: "success",
      validator: value => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger"
        ];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    logo: {
      type: String,
      default: "/static/img/vue-logo.jpg"
    },
    sidebarLinks: {
      type: Array,
      default: () => []
    },
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show3: false
    };
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  computed: {
    sidebarStyle() {
      return {
        // backgroundImage: `url(${this.backgroundImage})`,
        backgroundColor: 'white', 
      };
    }
  },
  methods: {
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("sidebar-wrapper");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    }
  },
  mounted() {
    this.initScrollbar();
  }
};
</script>

<style lang="scss">
@media (min-height: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

.plc_main_sidebar_logo {
  border-bottom: 1px solid #EAECF0;
  .main_mini_link_logo {
    float: none !important;
    // background: #eee;
    .main_logo_content_container {
      display: flex;
      align-items: center;
      gap: 10px;
      .main_img_logo {
        // height: 80px;
      }
      .logo_normal_title {
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.textddddd {
  position: relative;
  .shiwwwww {
    position: absolute;
  }
}

.sidebar_style {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: center center;
}

.sidebar .nav-link p{
  color: #101828 !important ;
}

.sidebar:after, .sidebar:before{
  content: unset !important;
}

</style>
