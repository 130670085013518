// import {GetQuestions, SaveQuestions,deleteQuestion} from '../../api/questionnaire'; // getInfo
import Words from "../../api/words"; // getInfo
const FileDownload = require("js-file-download");

// import {Auth, deleteToken, removeAuth, removeToken, resetPermission, setLogged, setToken} from '../../utils/auth';

const words = new Words();

const wordsModule = {
  namespaced: true,
  state: {
    usersLoad: false,
    total: null,
    words: [],
    list: [],
    languages: []
  },
  getters: {
    words: state => {
      return state.words;
    },
    total: state => {
      return state.total;
    },
    list: state => {
      return state.list;
    },
    languages: state => {
      return state.languages;
    },
    usersLoad: state => {
      return state.usersLoad;
    }
  },

  mutations: {
    SET_WORDS: (state, words) => {
      state.words = words;
    },
    SET_LANGUAGES: (state, languages) => {
      state.languages = languages;
    },
    SET_TOTAL_WORDS: (state, total) => {
      state.total = total;
    },

    SET_USERS_LOAD: (state, load) => {
      state.usersLoad = load;
    }
  },
  actions: {
    walls({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        words
          .walls(payload.query)
          .then(response => {
            commit("SET_WORDS", response.walls);
            commit("SET_TOTAL_WORDS", response.count);
            commit("SET_USERS_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },



    words({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_USERS_LOAD", true);
        words
          .list(payload.query)
          .then(response => {
            commit("SET_WORDS", response.words);
            commit("SET_LANGUAGES", response.languages);
            commit("SET_TOTAL_WORDS", response.count);
            commit("SET_USERS_LOAD", false);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    save_wall({commit}, payload) {
      return new Promise((resolve, reject) => {
        words
          .update_wall(payload.query, payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    add_wall({commit}, payload) {
      return new Promise((resolve, reject) => {
        words
          .add_wall(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    delete({commit}, id) {
      return new Promise((resolve, reject) => {
        words
          .delete_wall(id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    save_word({commit}, payload) {
      return new Promise((resolve, reject) => {
        words
          .update(payload.query, payload.id)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    add_word({commit}, payload) {
      return new Promise((resolve, reject) => {
        words
          .add(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    delete_word({commit,state}, id) {
      return new Promise((resolve, reject) => {
        words
          .delete(id)
          .then(response => {
            commit(
              "SET_WORDS",
              state.words.filter((word) => word.id != id)
            );
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    Export({commit}, payload) {
      return new Promise((resolve, reject) => {
        words.Export(payload.query)
          .then(response => {
            FileDownload(response, "words.xlsx");
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    // Bulk process
    ImportBulk({commit}, payload) {
      return new Promise((resolve, reject) => {
        words
          .Import_bulk(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    ExportBulk({commit}, payload) {
      return new Promise((resolve, reject) => {
        words
          .Export_bulk()
          .then(response => {
            FileDownload(response, "Temp_words.xlsx");
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};

export default wordsModule;
