import Topics from '../../api/topics'; // getInfo
const FileDownload = require('js-file-download');
const topics = new Topics();

const topicsModule = {
  namespaced: true,
  state: {
    usersLoad: false,
    topics: [],
    languages: [],
    topic: {},
    totalTopics: null,
    totalCategories:[],
    usersCategories:[],
    usersCategoriesCount:[],
    usersCategoriesDetails:[],
    usersCategoriesDetailsCount:[],
    categoriesTasks:[],
    categoriesTasksCount:[],
    standardTopics:[],
    // At Work -- Career Match Categories
    atWorkCategories:[],
    careerMatchCategories:[],
  },
  getters: {
    topics: (state) => {
      return state.topics
    },
    languages: (state) => {
      return state.languages
    },
    topic: (state) => {
      return state.topic
    },
    totalTopics: (state) => {
      return state.totalTopics
    },
    getTotalCategories: (state) => {
      return state.totalCategories
    },
    usersLoad: (state) => {
      return state.usersLoad
    },
    getUsersCategories: (state) => {
      return state.usersCategories
    },
    getUsersCategoriesCount: (state) => {
      return state.usersCategoriesCount
    },
    getUsersCategoriesDetails: (state) => {
      return state.usersCategoriesDetails
    },
    getUsersCategoriesDetailsCount: (state) => {
      return state.usersCategoriesDetailsCount
    },
    getCategoriesTasks: (state) => {
      return state.categoriesTasks
    },
    getCategoriesTasksCount: (state) => {
      return state.categoriesTasksCount
    },
    getStandardTopics: (state) => {
      return state.standardTopics
    },
    // At Work -- Career Match Categories
    getAtWorkCategories: (state) => {
      return state.atWorkCategories
    },
    getCareerMatchCategories: (state) => {
      return state.careerMatchCategories
    },
  },

  mutations: {
    SET_TOPICS: (state, topics) => {
      state.topics = topics;
    },
    SET_TOPIC: (state, topic) => {
      state.topic = topic;
    },
    SET_LANG: (state, languages) => {
      state.languages = languages;
    },
    SET_TOTAL_TOPICS: (state, totalTopics) => {
      state.totalTopics = totalTopics;
    },
    SET_TOTAL_CATEGORIES: (state, totalCategories) => {
      state.totalCategories = totalCategories;
    },
    SET_USERS_LOAD: (state, load) => {
      state.usersLoad = load;
    },
    SET_USERS_CATEGORIES: (state, cat) => {
      state.usersCategories = cat;
    },
    SET_USERS_CATEGORIES_COUNT: (state, catNum) => {
      state.usersCategoriesCount = catNum;
    },
    SET_USERS_CATEGORIES_DETAILS: (state, details) => {
      state.usersCategoriesDetails = details;
    },
    SET_USERS_CATEGORIES_DETAILS_COUNT: (state, details) => {
      state.usersCategoriesDetailsCount = details;
    },
    SET_CATEGORIES_TASKS: (state, details) => {
      state.categoriesTasks = details;
    },
    SET_CATEGORIES_TASKS_COUNT: (state, details) => {
      state.categoriesTasksCount = details;
    },
    SET_STANDARD_TOPICS: (state, top) => {
      state.standardTopics = top;
    },
    // At Work -- Career Match Categories
    SET_AT_WORK_CATEGORIES: (state, category) => {
      state.atWorkCategories = category;
    },
    SET_CAREER_MATCH_CATEGORIES: (state, category) => {
      state.careerMatchCategories = category;
    },
  },
  actions: {
    GetTopics({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        topics.topics(payload.query).then(response => {
          commit('SET_TOPICS', response.topic);

          commit('SET_LANG', response.languages);
          commit('SET_TOTAL_TOPICS', response.count);
          commit('SET_TOTAL_CATEGORIES', response.categories);
          commit('SET_AT_WORK_CATEGORIES', response.work_categories);
          commit('SET_CAREER_MATCH_CATEGORIES', response.career_categories);

          commit('SET_USERS_LOAD', false);
          resolve();
        }).catch(error => {
          commit('SET_USERS_LOAD', false);
          reject(error);
        });
      });
    },

    topic({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        topics.show_topic(payload.id).then(response => {
          commit('SET_TOPIC', response.topic);
          commit('SET_LANG', response.languages);
          commit('SET_USERS_LOAD', false);
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },

    Import({commit}, payload) {
      return new Promise((resolve, reject) => {
        topics.Import(payload.query)
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    Export({commit}, payload) {
      return new Promise((resolve, reject) => {
        topics.Export(payload.query)
          .then(response => {
            FileDownload(response, 'topics.xlsx');
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    UpdateTopics({commit}, payload) {
      return new Promise((resolve, reject) => {
        topics.update(payload.query, payload.id)
          .then(response => {
            resolve();
          }).catch(error => {
          reject(error);
        });
      });
    },

    AddTopics({commit}, payload) {
      return new Promise((resolve, reject) => {
        topics.add(payload.query)
          .then(response => {
            resolve();
          }).catch(error => {
          reject(error);
        });
      });
    },

    delete({commit}, id) {
      return new Promise((resolve, reject) => {
        topics.delete_topic(id).then(response => {
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },

    // users_categories
    handlerUserCategories({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        topics.users_categories(payload.query).then(response => {
          commit('SET_USERS_CATEGORIES', response.categories);
          commit('SET_USERS_CATEGORIES_COUNT', response.count);

          commit('SET_USERS_LOAD', false);
          resolve();
        }).catch(error => {
          commit('SET_USERS_LOAD', false);
          reject(error);
        });
      });
    },

    addUserTask({commit}, payload) {
      commit('SET_USERS_LOAD', true);
      return new Promise((resolve, reject) => {
        topics.add_users_cat_task(payload)
          .then(response => {
            commit('SET_USERS_LOAD', false);
            resolve(response);
          }).catch(error => {
            commit('SET_USERS_LOAD', false);
          reject(error);
        });
      });
    },


    // Category Task [ Topics Task ]
    addTopicTask({commit}, payload) {
      commit('SET_USERS_LOAD', true);
      return new Promise((resolve, reject) => {
        topics.add_topics_task(payload)
          .then(response => {
            commit('SET_USERS_LOAD', false);
            resolve(response);
          }).catch(error => {
            commit('SET_USERS_LOAD', false);
          reject(error);
        });
      });
    },
    // STANDARD TOPICS
    handlerStandardTopics({commit}) {
      commit('SET_USERS_LOAD', true);
      return new Promise((resolve, reject) => {
        topics.get_standard_topics()
          .then(response => {
            commit('SET_STANDARD_TOPICS', response.topics);

            resolve(response);
            commit('SET_USERS_LOAD', false);
          }).catch(error => {
            commit('SET_USERS_LOAD', false);
          reject(error);
        });
      });
    },

    deleteUserTask({commit}, id) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        topics.delete_users_cat_task(id).then(response => {
          commit('SET_USERS_LOAD', false);
          resolve(response);
        }).catch(error => {
          commit('SET_USERS_LOAD', false);
          reject(error);
        });
      });
    },

    handlerDeleteTaskCategoryTopics({commit}, id) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        topics.categories_tasks_delete(id).then(response => {
          commit('SET_USERS_LOAD', false);
          resolve(response);
        }).catch(error => {
          commit('SET_USERS_LOAD', false);
          reject(error);
        });
      });
    },


    handlerUserCategoriesDetails({commit}, id) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        topics.users_categories_details( id).then(response => {
          commit('SET_USERS_CATEGORIES_DETAILS', response.users);
          commit('SET_USERS_CATEGORIES_DETAILS_COUNT', response.count);
          commit('SET_USERS_LOAD', false);
          resolve();
        }).catch(error => {
          commit('SET_USERS_LOAD', false);
          reject(error);
        });
      });
    },
    deleteOneUserTask({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        topics.delete_one_user_cat_task(payload).then(response => {
          commit('SET_USERS_LOAD', false);
          resolve(response);
        }).catch(error => {
          commit('SET_USERS_LOAD', false);
          reject(error);
        });
      });
    },
    deleteAllUsersTask({commit}, id) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        topics.delete_all_user_cat_task(id).then(response => {
          commit('SET_USERS_LOAD', false);
          resolve(response);
        }).catch(error => {
          commit('SET_USERS_LOAD', false);
          reject(error);
        });
      });
    },
    handlerCategoriesTasks({commit}, id) {
      return new Promise((resolve, reject) => {
        commit('SET_USERS_LOAD', true);
        topics.categories_tasks(id).then(response => {
          commit('SET_CATEGORIES_TASKS', response.tasks);
          commit('SET_CATEGORIES_TASKS_COUNT', response.count);
          commit('SET_USERS_LOAD', false);
          resolve(response);
        }).catch(error => {
          commit('SET_USERS_LOAD', false);
          reject(error);
        });
      });
    },
  },

  // categories_tasks

};

export default topicsModule;
