<template>
  <div v-if="(text == 'Add new task')">
    <button
      class="main_app_button"
      @click="handleClick"
    
    >
      <img
        v-if="withImg && joinbtn == false"
        src="@/assets/new-theme/icons/plus.png"
        alt="icon button"
      />
      <img
        v-if="joinbtn"
        src="@/assets/new-theme/users-plus.png"
        alt="icon button"
      />
      <span class="ml-1">{{ text }}</span>
    </button>
  </div>
  <div v-else>
    <button
      class="main_app_button"
      @click="handleClick"
    >
      <img
        v-if="withImg && joinbtn == false"
        src="@/assets/new-theme/icons/plus.png"
        alt="icon button"
      />
      <img
        v-if="joinbtn"
        src="@/assets/new-theme/users-plus.png"
        alt="icon button"
      />
      <span class="ml-1">{{ text }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "AppButton",
  props: {
    text: {
      type: String,
      default: "Add",
      required: false
    },
    withImg: {
      type: Boolean,
      default: true,
      required: false
    },
    joinbtn: {
      type: Boolean,
      default: false
    },
    teamStatus: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    handleClick() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss" scoped>
.main_app_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 187px;
  height: 41px;
  background-color: #7f56d9;
  border: 1px solid #7f56d9;
  border-radius: 8px;
  font-size: 14px;
  color: #fff;
}
</style>
