<template>
  <div class="w-100 m-0 p-0">
    <div class="alert alert-warning alert-custom" role="alert">
      <div class="d-flex">
        <span class="mr-2">
          <img src="@/assets/new-theme/warning.png" alt="" />
        </span>
        <div>
          <div>
            <strong>{{ localization("We have sent an invitation to all invited members. Ask them to accept the invitation and complete the required steps.") }}</strong><br>{{ localization("Once the team status is changed to In progress, review what your team members bring and need, and then write your statement about how you will work with THIS team.") }}
            
          </div>
          <div>
            <a href="" @click="$router.push({name: 'TeamsView'})" class="mt-3 d-inline-block text-warning font-weight-bold"  >{{ localization("Back to teams") }} &rarr;</a>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="is_chart_wrapper">
          <chart-card
            v-if="scores.length > 0"
            :chart-data="teamChart"
            :chart-options="barChart.options"
            :chart-responsive-options="barChart.responsiveOptions"
            class="rounded-xl p-2"
            style="background: #FCFCFD;"
          >
            <template slot="header">
              <div class="d-flex">
                <i class="el-icon el-icon-data-analysis" style="font-size: 30px; margin-right: 7px"></i>
                <h4 class="card-title">{{ localization("Team Line Graph") }}</h4>
              </div>
              <p
                class="text-muted mt-2"
              >{{ localization("See on a graph the areas where team members have similarities and differences.") }}</p>
            </template>
          </chart-card>
          <div class="lines_in_chart avoid">
            <span class="is_text_chart">{{ localization("Avoid") }}</span>
          </div>
          <div class="lines_in_chart needed">
            <span class="is_text_chart">{{ localization("As Needed") }}</span>
          </div>
          <div class="lines_in_chart first">
            <span class="is_text_chart">{{ localization("Use First") }}</span>
          </div>
        </div>
    </div>

    <div>
      <h4 class="mt-3">{{ localization("Check the team members’ answers") }} </h4>
      <div>
        <template>
          <el-tabs type="border-card">
            <el-tab-pane :label="`${localization('Invited members')}`">
              <InvitedMembers />
            </el-tab-pane>
            <el-tab-pane :label="`${localization('Bring to a team')}`">
              <BringToTeam />
            </el-tab-pane>
            <el-tab-pane :label="`${localization('Need from a team')}`">
              <NeedFromTeam />
            </el-tab-pane>
            <el-tab-pane :label="`${localization('Members’ statements')}*`">
              <MembersStatements />
            </el-tab-pane>
          </el-tabs>
        </template>
      </div>
    </div>
  </div>
</template>
  
<script>
import { Card } from "src/components/index";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { ChartCard, StatsCard } from "src/components/index";
import InvitedMembers from './InvitedMembers.vue';
import BringToTeam from './BringToTeam.vue';
import NeedFromTeam from './NeedFromTeam.vue';
import MembersStatements from './MembersStatements.vue';
export default {
  data() {
    return {
    
    };
  },
  components: {
    Card,
    ChartCard,
    StatsCard,
    InvitedMembers,
    BringToTeam,
    NeedFromTeam,
    MembersStatements
  },
  computed: {
    ...mapGetters({
      loadingValue: ["user/usersLoad"],
      team_details: ["user/get_team_details"],
      team_members: ["user/get_team_members"],
      invited_team_members: ["user/get_invited_team_members"]
    }),
    teamId() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
    scores() {
      let scores = [];
      this.team_members.forEach(element => {
        scores.push([
          element.sequence,
          element.precision,
          element.technical_reasoning,
          element.confluence
        ]);
      });
      return scores;
    },
    teamChart() {
      let data = {};

      data.labels = [
        "Sequence",
        "Precision",
        "Technical Reasoning",
        "Confluence"
      ];
      data.series = this.scores;
      return data;
    }
  },
  data(){
    return {
      selected: [],
      filter:{},
      barChart: {
        options: {
          seriesBarDistance: 20,
          showPoint: true,
          labelDirection: "explode",
          chartPadding: 0,
          labelOffset: 20,
          // distributeSeries: true,
          // steps: 5,
          low: 7,
          high: 40,
          // lineSmooth: true,

          showLine: true,

          labelInterpolationFnc: function(value) {
            return `${value.split(" ")[0]}...`;
          },
          height: "350px",
          width: "95%",
          axisX: {
            offset: 30,
            position: "end",
            showLabel: true,
            showGrid: true,
            labelOffset: {
              x: 0,
              y: 5
            }
          },
          axisY: {
            offset: 50,
            position: "start",
            showLabel: true,
            showGrid: true,
            scaleMinSpace: 20,
            onlyInteger: true,
            labelOffset: {
              x: 0,
              y: 0
            }
          }
          // plugins: [Chartist.plugins.tooltip()]
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },
    }
  },
  mounted() {
    this.get_team_details();  
  },
  methods: {
    refresh(){
      this.$store.dispatch("user/every_team_members", this.teamId);
    },
    get_team_details() {
      this.$store.dispatch("user/every_team_members", this.teamId);
    },
    selections(selections) {
      this.selected = Array.from(selections, el => el.id);
    },
  }
};
</script>
<style scoped lang="scss">
::v-deep .el-tabs--border-card > .el-tabs__header {
  border-radius: 10px !important;
}
.alert-custom {
  background-color: #fffaf0; /* Light background similar to your image */
  border: 1px solid #ffd580; /* Border color to match */
  color: #c35a00; /* Text color to match the orange */
  border-radius: 10px;
}

.alert-custom i {
  color: #c35a00; /* Icon color */
  font-size: 20px;
}

.alert-custom a {
  color: #c35a00;
  text-decoration: none;
}

.alert-custom a:hover {
  text-decoration: underline;
}

::v-deep .el-tabs--border-card, .el-tabs__content{
  border: unset !important;
}

::v-deep .el-tabs__nav-scroll{
  padding: 10px !important;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  border-top-color: #DCDFE6 !important;
  border-bottom-color: #DCDFE6 !important;
  border-radius: 7px !important;
}

::v-deep .team_members{
  .el-table .cell{
    overflow: unset !important;
  }
}

::v-deep.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  color: #344054;
}

::v-deep .el-tabs--border-card{
  box-shadow: unset !important;
}

::v-deep .el-tabs--border-card>.el-tabs__header{
  border-bottom: unset !important;
}
</style>