<template>
  <div
    class="new_theme_custom_table_pagination border rounded-bottom shadow-sm"
  >
    <card
      v-if="is_local"
      class="main_data_table_pagination_container yes_yes"
      style="border-bottom-left-radius: 10px !important; border-bottom-right-radius: 10px !important;"
    >
      <!-- For Languages Table  -->
      <div
        class="col-12 d-flex justify-content-end pb-2"
        :class="{ 'flex-row-reverse': isRtl() }"
      >
        <slot name="action"></slot>
      </div>
      <el-table
        odd
        stripe
        style="width: 100% ; margin: 10px !important"
        @sort-change="setLocalSort"
        :data="pagedData"
        :row-class-name="class_name"
        :cell-class-name="class_name"
        v-loading="loading"
        :class="{ table_with_rtl_direction: isRtl() }"
      >
        <template slot="empty">
          <div class="empty-state">
            <slot name="empty">
              <div class="empty-state-content">
                <img
                  :src="emptyStateImage"
                  :alt="emptyStateTitle || 'No data'"
                />
                <h3>{{ emptyStateTitle || "No Results Found" }}</h3>
                <p>
                  {{
                    emptyStateText ||
                      "Try adjusting your search or filter to find what you're looking for."
                  }}
                </p>
                <div
                  v-if="$slots['empty-actions'] || showDefaultEmptyActions"
                  class="empty-state-actions"
                >
                  <slot name="empty-actions">
                    <el-button
                      v-if="showDefaultEmptyActions"
                      type="primary"
                      @click="$emit('reset-filters')"
                      >Clear Filters</el-button
                    >
                  </slot>
                </div>
              </div>
            </slot>
          </div>
        </template>
        <slot name="columns"></slot>
      </el-table>
      <div
        slot="footer"
        class="
        col-12
        d-flex
        align-items-center
        justify-content-center justify-content-sm-between
        flex-wrap
        pt-2
      "
        :class="{ footer_with_rtl_direction: isRtl() }"
      >
        <div class="d-flex">
          <div class="d-flex handler_showing_filters_with_rtl">
            <span style="color: #9a9a9a; font-size: 0.75rem" class="mr-1">{{
              localization("Show")
            }}</span>
            <el-select
              class="select-default mb-3"
              style="width: 100px"
              :label="`${localization('Show')}`"
              v-model="pagination.perPage"
              :placeholder="`${localization('Per page')}`"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <span
              style="
              color: #9a9a9a;
              font-size: 0.75rem;
              margin-right: 10px;
              line-height: 2rem;
            "
              class="ml-1"
              >{{ localization("Entries") }}</span
            >
            <p style="line-height: 2.5" class="card-category">
              {{ localization("Showing") }} {{ from + 1 }}
              {{ localization("to") }} {{ to }} {{ localization("of") }}
              {{ count }} {{ localization("Entries") }}
            </p>
          </div>
        </div>

        <l-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="count"
        >
        </l-pagination>
      </div>
    </card>
    <!-- Start Main Table  -->
    <card v-else class="main_data_table_pagination_container mb-0">
      <!-- for tables other than languages table  -->
      <div>
        <el-tabs
          v-if="isContact"
          v-model="request_type"
          :class="{ tabs_for_contacts_with_rtl: isRtl() }"
          class="contact-tabs"
          @tab-click="handleTabClick"
        >
          <el-tab-pane name="1">
            <template #label>
              {{ localization("My Contacts") }}
              <span class="tab-count">{{ myContactCount }}</span>
            </template>
            <div>
              <TableHeaderSection
                @toggleModal="$emit('toggleModal', $event)"
                title="My contact list"
                subTitle="contacts"
                text="Invite your classmates, friends, business contacts to share
                      their LCI scores with you and build your opportunity to share
                      strategies!"
                buttonText="Add new contact"
                :total="count"
              ></TableHeaderSection>
            </div>
            <div class="pt-1" v-if="hasFilter">
              <!-- //! start main filter -->
              <div class="custom_filter_container">
                <!-- //? start  logs filter -->
                <div class="each_filter_container with_search">
                  <img src="@/assets/new-theme/search.png" alt="search" />
                  <el-input
                    ref="searchInput"
                    class=""
                    placeholder="Search for a team member"
                    v-model="search"
                    @keyup.enter.native="Refresh(true)"
                  />
                </div>
                <div class="each_filter_container" v-if="!hidden_export_import">
                  <!-- will not be displayed at Task table since no active and inactive  -->
                  <el-select
                    v-if="!hidden_export_import"
                    v-model="removed"
                    :placeholder="`${localization('Select status')}`"
                    class="select-default mt-2 w-100"
                  >
                    <el-option
                      v-for="(state, i) in status"
                      :key="i"
                      :label="state.label"
                      :value="state.value"
                    ></el-option>
                  </el-select>
                </div>
                <div class="each_filter_container" v-if="isFilterLogs">
                  <el-date-picker
                    v-model="filter.date_from"
                    :placeholder="`${localization('Date from')}`"
                    format="dd / MM / yyyy"
                    value-format="yyyy-MM-dd"
                    type="date"
                  >
                  </el-date-picker>
                </div>
                <div class="each_filter_container" v-if="isFilterLogs">
                  <el-date-picker
                    v-model="filter.date_to"
                    :placeholder="`${localization('Date to')}`"
                    format="dd / MM / yyyy"
                    value-format="yyyy-MM-dd"
                    type="date"
                  >
                  </el-date-picker>
                </div>
                <div class="each_filter_container" v-if="isFilterLogs">
                  <el-select
                    v-model="filter.action"
                    :placeholder="`${localization('Select action')}`"
                    class="select-default mt-2 w-100"
                  >
                    <el-option
                      v-for="(act, i) in $store.getters['logs/get_action_logs']"
                      :key="i"
                      :label="act"
                      :value="act"
                    ></el-option>
                  </el-select>
                </div>

                <div class="each_filter_container">
                  <slot name="filter"></slot>
                </div>
                <div class="wrapper_clear_filter_button">
                  <button class="clear_button" @click="resetFunction">
                    {{ localization("Clear search") }}
                  </button>
                </div>
              </div>
              <!-- //! end  main filter -->
            </div>

            <el-table
              odd
              stripe
              fit
              @sort-change="sortChange"
              @selection-change="selection_change"
              :cell-class-name="class_name"
              style="width: 100%; padding: 10px"
              :data="is_local ? pagedData : DataItems"
              v-loading="isLoading || fileLoading"
              :class="{ table_with_rtl_direction: isRtl() }"
              v-if="request_type === '1'"
            >
              <slot name="columns"></slot>
              <template #empty>
                <TableEmptySection
                  @toggleModal="$emit('toggleModal', $event)"
                  @clearSearch="resetFunction"
                  :addNewText="addNewText"
                  :viewName="viewName"
                  clearText="clear search"
                  :total="count"
                  :search="search"
                ></TableEmptySection>
              </template>
              <!-- <slot name="columns" v-if="!isMediumScreen"></slot>

                <slot name="columnsSmallScreens" v-else></slot> -->
            </el-table>
          </el-tab-pane>
          <el-tab-pane name="2">
            <template #label>
              {{ localization("Pending Requests") }}
              <span class="tab-count">{{ pendingCount }}</span>
            </template>
            <div>
              <TableHeaderSection
                @toggleModal="ToggleTaskModal('add')"
                title="My pending requests"
                subTitle="pending requests"
                text="Invite your classmates, friends, business contacts to share
                      their LCI scores with you and build your opportunity to share
                      strategies!"
                buttonText="Add new contact"
                :total="count"
              ></TableHeaderSection>
            </div>
            <div class="pt-1" v-if="hasFilter">
              <!-- //! start main filter -->
              <div class="custom_filter_container">
                <!-- //? start  logs filter -->
                <div class="each_filter_container with_search">
                  <img src="@/assets/new-theme/search.png" alt="search" />
                  <el-input
                    ref="searchInput"
                    class=""
                    :placeholder="`${localization('Search')}`"
                    v-model="search"
                    @keyup.enter.native="Refresh(true)"
                  />
                </div>
                <div class="each_filter_container" v-if="!hidden_export_import">
                  <!-- will not be displayed at Task table since no active and inactive  -->
                  <el-select
                    v-if="!hidden_export_import"
                    v-model="removed"
                    :placeholder="`${localization('Select status')}`"
                    class="select-default mt-2 w-100"
                  >
                    <el-option
                      v-for="(state, i) in status"
                      :key="i"
                      :label="state.label"
                      :value="state.value"
                    ></el-option>
                  </el-select>
                </div>
                <div class="each_filter_container" v-if="isFilterLogs">
                  <el-date-picker
                    v-model="filter.date_from"
                    :placeholder="`${localization('Date from')}`"
                    format="dd / MM / yyyy"
                    value-format="yyyy-MM-dd"
                    type="date"
                  >
                  </el-date-picker>
                </div>
                <div class="each_filter_container" v-if="isFilterLogs">
                  <el-date-picker
                    v-model="filter.date_to"
                    :placeholder="`${localization('Date to')}`"
                    format="dd / MM / yyyy"
                    value-format="yyyy-MM-dd"
                    type="date"
                  >
                  </el-date-picker>
                </div>
                <div class="each_filter_container" v-if="isFilterLogs">
                  <el-select
                    v-model="filter.action"
                    :placeholder="`${localization('Select action')}`"
                    class="select-default mt-2 w-100"
                  >
                    <el-option
                      v-for="(act, i) in $store.getters['logs/get_action_logs']"
                      :key="i"
                      :label="act"
                      :value="act"
                    ></el-option>
                  </el-select>
                </div>

                <div class="each_filter_container">
                  <slot name="filter"></slot>
                </div>
                <div class="wrapper_clear_filter_button">
                  <button class="clear_button" @click="resetFunction">
                    {{ localization("Clear search") }}
                  </button>
                </div>
              </div>
              <!-- //! end  main filter -->
            </div>
            <el-table
              odd
              stripe
              fit
              @sort-change="sortChange"
              @selection-change="selection_change"
              :cell-class-name="class_name"
              style="width: 100%"
              :data="is_local ? pagedData : DataItems"
              v-loading="isLoading || fileLoading"
              :class="{ table_with_rtl_direction: isRtl() }"
              v-if="request_type === '2'"
            >
              <slot name="columns"></slot>

              <!-- <slot name="columns" v-if="!isMediumScreen"></slot>
          <slot name="columnsSmallScreens" v-else></slot> -->
            </el-table>
          </el-tab-pane>
          <el-tab-pane name="3">
            <template #label>
              {{ localization("Received Requests") }}
              <span class="tab-count">{{ receivedCount }}</span>
            </template>
            <div>
              <TableHeaderSection
                @toggleModal="ToggleTaskModal('add')"
                title="My received requests"
                subTitle="received requests"
                text="Invite your classmates, friends, business contacts to share
                      their LCI scores with you and build your opportunity to share
                      strategies!"
                buttonText="Add new contact"
                :total="count"
              ></TableHeaderSection>
            </div>
            <div class="pt-1" v-if="hasFilter">
              <!-- //! start main filter -->
              <div class="custom_filter_container">
                <!-- //? start  logs filter -->
                <div class="each_filter_container with_search">
                  <img src="@/assets/new-theme/search.png" alt="search" />
                  <el-input
                    ref="searchInput"
                    class=""
                    :placeholder="`${localization('Search')}`"
                    v-model="search"
                    @keyup.enter.native="Refresh(true)"
                  />
                </div>
                <div class="each_filter_container" v-if="!hidden_export_import">
                  <!-- will not be displayed at Task table since no active and inactive  -->
                  <el-select
                    v-if="!hidden_export_import"
                    v-model="removed"
                    :placeholder="`${localization('Select status')}`"
                    class="select-default mt-2 w-100"
                  >
                    <el-option
                      v-for="(state, i) in status"
                      :key="i"
                      :label="state.label"
                      :value="state.value"
                    ></el-option>
                  </el-select>
                </div>
                <div class="each_filter_container" v-if="isFilterLogs">
                  <el-date-picker
                    v-model="filter.date_from"
                    :placeholder="`${localization('Date from')}`"
                    format="dd / MM / yyyy"
                    value-format="yyyy-MM-dd"
                    type="date"
                  >
                  </el-date-picker>
                </div>
                <div class="each_filter_container" v-if="isFilterLogs">
                  <el-date-picker
                    v-model="filter.date_to"
                    :placeholder="`${localization('Date to')}`"
                    format="dd / MM / yyyy"
                    value-format="yyyy-MM-dd"
                    type="date"
                  >
                  </el-date-picker>
                </div>
                <div class="each_filter_container" v-if="isFilterLogs">
                  <el-select
                    v-model="filter.action"
                    :placeholder="`${localization('Select action')}`"
                    class="select-default mt-2 w-100"
                  >
                    <el-option
                      v-for="(act, i) in $store.getters['logs/get_action_logs']"
                      :key="i"
                      :label="act"
                      :value="act"
                    ></el-option>
                  </el-select>
                </div>

                <div class="each_filter_container">
                  <slot name="filter"></slot>
                </div>
                <div class="wrapper_clear_filter_button">
                  <button class="clear_button" @click="resetFunction">
                    {{ localization("Clear search") }}
                  </button>
                </div>
              </div>
              <!-- //! end  main filter -->
            </div>
            <el-table
              odd
              stripe
              fit
              @sort-change="sortChange"
              @selection-change="selection_change"
              :cell-class-name="class_name"
              style="width: 100%"
              :data="is_local ? pagedData : DataItems"
              v-loading="isLoading || fileLoading"
              :class="{ table_with_rtl_direction: isRtl() }"
            >
              <slot name="columns"></slot>
            </el-table>
          </el-tab-pane>
        </el-tabs>

        <div v-else>
          <div class="pt-1" v-if="hasFilter">
            <!-- //! start main filter -->
            <div class="custom_filter_container">
              <!-- //? start  logs filter -->
              <div class="each_filter_container with_search">
                <img src="@/assets/new-theme/search.png" alt="search" />
                <el-input
                  ref="searchInput"
                  class=""
                  placeholder="Search"
                  v-model="search"
                  @keyup.enter.native="Refresh(true)"
                />
              </div>
              <div class="each_filter_container" v-if="!hidden_export_import">
                <!-- will not be displayed at Task table since no active and inactive  -->
                <el-select
                  v-if="!hidden_export_import"
                  v-model="removed"
                  :placeholder="`${localization('Select status')}`"
                  class="select-default mt-2 w-100"
                >
                  <el-option
                    v-for="(state, i) in status"
                    :key="i"
                    :label="state.label"
                    :value="state.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="each_filter_container" v-if="isFilterLogs">
                <el-date-picker
                  v-model="filter.date_from"
                  :placeholder="`${localization('Date from')}`"
                  format="dd / MM / yyyy"
                  value-format="yyyy-MM-dd"
                  type="date"
                >
                </el-date-picker>
              </div>
              <div class="each_filter_container" v-if="isFilterLogs">
                <el-date-picker
                  v-model="filter.date_to"
                  :placeholder="`${localization('Date to')}`"
                  format="dd / MM / yyyy"
                  value-format="yyyy-MM-dd"
                  type="date"
                >
                </el-date-picker>
              </div>
              <div class="each_filter_container" v-if="isFilterLogs">
                <el-select
                  v-model="filter.action"
                  :placeholder="`${localization('Select action')}`"
                  class="select-default mt-2 w-100"
                >
                  <el-option
                    v-for="(act, i) in $store.getters['logs/get_action_logs']"
                    :key="i"
                    :label="act"
                    :value="act"
                  ></el-option>
                </el-select>
              </div>

              <div class="each_filter_container" >
                <slot name="filter" v-if="$route.name != 'Users'"></slot>
              </div>
              <div class="wrapper_clear_filter_button">
                <button class="clear_button" @click="resetFunction">
                    {{ localization("Clear search") }}
                </button>
              </div>
            </div>
            <div class="mb-5 px-3" v-if="$route.name == 'Users'">
              <slot name="filter"></slot>
            </div>
            <!-- //! end  main filter -->
          </div>
          <el-table
            @sort-change="sortChange"
            @selection-change="selection_change"
            :cell-class-name="class_name"
            style="width: 100%"
            :data="is_local ? pagedData : DataItems"
            v-loading="isLoading || fileLoading"
            :class="{ table_with_rtl_direction: isRtl() }"
          >
            <slot name="columns"></slot>
            <template #empty>
              <TableEmptySection
                @toggleModal="$emit('toggleModal', $event)"
                @clearSearch="resetFunction"
                :addNewText="addNewText"
                :viewName="viewName"
                :clearText="`${localization('clear search')}`"
                :total="count"
                :search="search"
              ></TableEmptySection>
            </template>
            <!-- <slot name="columns" v-if="!isMediumScreen"></slot>

            <slot name="columnsSmallScreens" v-else></slot> -->
          </el-table>
        </div>
      </div>
      <!-- //? Start footer  -->
      <div
        v-if="!isGroup"
        slot="footer"
        class="
        col-12
        d-flex
        align-items-center
        justify-content-center justify-content-sm-between
        flex-wrap
        pt-2
        data_table_pagination_footer
      "
        :class="{ footer_with_rtl_direction: isRtl() }"
      >
        <div class="d-flex">
          <div
            class="d-flex showing_numbers_container handler_showing_filters_with_rtl"
          >
            <span
              style="color: #9a9a9a; font-size: 0.75rem"
              class="mr-1 mt-1"
              >{{ localization("Show") }}</span
            >
            <el-select
              class="select-default mb-3"
              style="width: 100px"
              :label="`${localization('Show')}`"
              v-model="pagination.perPage"
              :placeholder="`${localization('Per page')}`"
            >
              <!-- :class="{
                'd-none':
                  ($route.name != 'Users' && item == 500) ||
                  ($route.name != 'Users' && item == 1000)
              }" -->
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <span
              style="color: #9a9a9a; font-size: 0.75rem; margin-right: 10px"
              class="ml-1 mt-1"
              >{{ localization("Entries") }}</span
            >
            <p style="line-height: 2.7" class="card-category card_numbers_text">
              {{ localization("Showing") }} {{ from + 1 }}
              {{ localization("to") }} {{ to }} {{ localization("of") }}
              {{ count }} {{ localization("Entries") }}
            </p>
          </div>
        </div>

        <l-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="count"
        >
        </l-pagination>
      </div>
      <!-- //? End footer  -->
    </card>
    <!-- End Main Table  -->
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import Swal from "sweetalert2";
export default {
  components: {
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  name: "CustomTable",
  props: {
    hidden_pagination: { default: false, type: Boolean },
    DataItems: Array,
    import_url: String,
    filter: Object,
    class_name: String,
    action: {
      default: true,
      type: Boolean
    },
    hasFilter: {
      default: true,
      type: Boolean
    },
    title: String,
    name: String,
    addNewText: String,
    viewName: String,
    loading: Boolean,
    count: Number,
    myContactCount: Number,
    pendingCount: Number,
    receivedCount: Number,
    is_local: {
      default: false,
      type: Boolean
    },
    hidden_export_import: {
      default: false,
      type: Boolean
    },
    fileLoading: Boolean,
    allow_bulk_process: Boolean,
    admin_permission: String,
    edit_permission: String,
    isContact: {
      type: Boolean,
      required: false
    },
    isGroup: {
      type: Boolean,
      required: false
    },
    isFilterLogs: {
      type: Boolean,
      required: false
    },
    emptyStateTitle: {
      type: String,
      default: ""
    },
    emptyStateText: {
      type: String,
      default: ""
    },
    emptyStateImage: {
      type: String,
      default: () => require("@/assets/new-theme/empty-state.png")
    },
    showDefaultEmptyActions: {
      type: Boolean,
      default: true
    }
    // request_type: {
    //   type: String,
    //   default: "1"
    // }
  },
  data() {
    return {
      request_type: "1",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 500, 1000]
      },
      sort: { column: "id", asc: false },
      locallySortedData: [],
      locallySorted: false,
      progress_count: null,
      status: [
        { value: 1, label: `Inactive` },
        { value: 0, label: `Active` }
      ],
      search: null,
      removed: 0,
      //? logs
      // date_from: null,
      // date_to: null,
      // action: null,
      // action_log: null,
      isLoading: true,
      isMediumScreen: false, // used to display content of tables depending on screens width
      datePickerOptions: {
        disabledDate(date) {
          return date < new Date();
        }
      }
    };
  },
  computed: {
    import_src() {
      return `${process.env.VUE_APP_BASE_URL}/${this.import_url}`;
    },
    pagedData() {
      return this.DataItems.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.count;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },
  mounted() {
    console.log('=============================================================>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ', this.myContactCount);
    
    let query = {
      limit: this.pagination.perPage - 1,
      offset: 0
      // order_by: this.sort.column ? this.sort.column : "id",
      // order_direction: this.sort.asc ? "ASC" : "DESC",
    };
    this.$emit("Refresh", query);

    // (this.$store.getters["logs/get_action_logs"]);
    // set isMediumScreen to false if screen is large size or more
    // this.isMediumScreen = window.innerWidth <= 768;

    // this.$refs["searchInput"].addEventListener("keyup", (e) => {
    //   if (e.key == "Enter" && this.search.length > 0) {
    //     this.Refresh(true);
    //   }
    // });
  },

  watch: {
    "pagination.currentPage": function() {
      if (!this.is_local) this.Refresh();
    },
    "pagination.perPage": function() {
      this.Refresh();
    },
    "search": function() {
      this.Refresh();
    },
    "filter.organization_id": function() {
      this.Refresh();
    },
    "filter.language": function() {
      this.Refresh();
    },
    "filter.type": function() {
      this.Refresh();
    },
    "filter.age_group_id": function() {
      this.Refresh();
    },
    "filter.pattern": function() {
      this.Refresh();
    },
    "filter.classification": function() {
      this.Refresh();
    },
    "filter.topic_id": function() {
      this.Refresh();
    },
    "filter.age_group": function() {
      this.Refresh();
    },
    "filter.category": function() {
      this.Refresh();
    },
    "filter.priority": function() {
      this.Refresh();
    },
    // request_type: function() {
    //   this.Refresh();
    // },
    request_type: function(newVal) {
      // Emit to parent component
      this.$emit("tab-change", newVal);
    },
    removed: function() {
      this.Refresh();
    },
    // //? new filter in logs
    "filter.date_from": function() {
      this.Refresh();
    },
    "filter.date_to": function() {
      this.Refresh();
    },
    "filter.action": function() {
      this.Refresh();
    },

    "filter.lci": function() {
      this.Refresh();
    },
    "filter.selected_attributes": function() {
      this.Refresh();
    },
    "filter.team_member": function() {
      this.Refresh();
    },
    "filter.tasks": function() {
      this.Refresh();
    },
    "filter.work_tasks": function() {
      this.Refresh();
    },
    "filter.career_tasks": function() {
      this.Refresh();
    },
    // date_from: function() {
    //   this.Refresh();
    // },
    // date_to: function() {
    //   this.Refresh();
    // },
    // action_log: function() {
    //   this.Refresh();
    // },

    DataItems: function() {
      this.isLoading = false;
    },
    filter: function() {
      this.isLoading = true;
    }
  },
  methods: {
    handleTabClick(tab) {
      // Emit an event so the child knows the tab changed
      this.$emit("tab-change", tab.name);
    },
    ToggleTaskModal(){
      // Emit an event so the child knows the tab changed
      console.log('================================================================');
      
      this.$emit("toggleModal");
    },
    Refresh(search = false) {
      this.isLoading = true;
      Object.filter = (obj, predicate) =>
        Object.keys(obj)
          .filter(key => predicate(obj[key]))
          .reduce((res, key) => ((res[key] = obj[key]), res), {});
      let query = this.is_local
        ? {
            limit: this.pagination.perPage - 1,
            offset: (this.pagination.currentPage - 1) * this.pagination.perPage
          }
        : {
            limit: this.pagination.perPage - 1,
            offset: search
              ? 0
              : (this.pagination.currentPage - 1) * this.pagination.perPage,
            search_text: this.search,
            order_by: this.sort.column ? this.sort.column : "id",
            order_direction: this.sort.asc ? "ASC" : "DESC",
            request_type: this.request_type,
            removed: this.removed,
            date_from: this.date_from,
            date_to: this.date_to,
            action_log: this.action_log,
            ...Object.filter(this.filter ? this.filter : {}, el => el != "")
          };
      this.$store.commit("SET_FILTER", this.filter);
      this.$emit("Refresh", query);
      this.progress_count = null;
      this.isLoading = false;
    },
    handleCommand(command) {
      if (command) command();
    },

    progress(e, file, list) {
      this.isLoading = true;
      this.progress_count = e.percent;
      this.isLoading = false;
    },
    //
    handleErrorUpload(error, file, fileList) {
      // const errors = JSON.stringify(error);
      // const errors2 = JSON.parse(error);
      Swal.fire({
        text: `${error}`,
        type: "error",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false,
        icon: "error"
      }).then(date => {
        if (date.isConfirmed) {
          this.isLoading = false;
        }
        this.isLoading = false;
      });
    },
    selection_change(selections) {
      this.$emit("selection", selections);
    },
    sortChange(val) {
      this.sort.column = val.prop;
      this.sort.asc = val.order == "ascending" ? true : false;
      this.Refresh();
    },
    setLocalSort(val) {
      // (val.prop, val.order);
      this.sort.column = val.prop;
      this.locallySortedData = [...this.pagedData].sort((a, b) => {
        if (val.order == "ascending") a[val.prop] - b[val.prop];
        if (val.order == "descending") b[val.prop] - a[val.prop];
      });
      this.Refresh();
    },
    Export() {
      Object.filter = (obj, predicate) =>
        Object.keys(obj)
          .filter(key => predicate(obj[key]))
          .reduce((res, key) => ((res[key] = obj[key]), res), {});
      let query = {
        search_text: this.search,
        ...Object.filter(this.filter, el => el != ""),
        removed: this.removed,
        date_from: this.date_from,
        date_to: this.date_to,
        action_log: this.action_log
      };
      this.$emit("export", query);
    },
    Export_Bulk() {
      this.$emit("Export_Bulk");
    },
    emitImportBulk() {
      this.$emit("addBulk");
    },
    resetFunction() {
      this.search = "";
      // this.date_from = "";
      // this.date_to = "";
      // this.action_log = "";
      this.removed = 0;
      this.Refresh(false);
      this.$emit("reset");
    },
    goBack() {
      this.$router.push("/new-theme/profile");
    }
  }
};
</script>

<style lang="scss">
.custom_expand {
  cursor: pointer !important;
}

.resetButton .el-button.is-round:focus,
.resetButton .el-button.is-round:hover,
.resetButton .el-button.is-round:active {
  border-color: #173f5f;
  color: #173f5f;
  color: #fff;
  background-color: #173f5f;
  opacity: 1;
}

.base_color {
  opacity: 0.9;
  color: #fff;
  background-color: #173f5f;
}

.resetHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
  margin: 0.5rem 0rem;

  .el-button {
    margin: 0rem 0.7rem;
  }

  div label {
    margin: 0rem 0.3rem;
  }
}

.resetButton {
  color: #fff;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  margin-left: 1rem;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: "";
  position: absolute;
  background-color: #ffffff;
  z-index: 1;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}

thead tr th .cell {
  border-bottom-width: 1px !important;
  font-size: 0.75rem !important;
  text-transform: uppercase !important;
  color: #9a9a9a !important;
  font-weight: 400 !important;
  padding-bottom: 5px !important;
}

.el-icon-arrow-right {
  color: #23ccef;
}
.main_data_table_pagination_container {
  .yes_yes {
    background: #000 !important;
  }
  .main_collapse_data_pagination_wrapping {
    padding: 1.1rem;
    @media (min-width: 320px) and (max-width: 767px) {
      padding: 0.2rem;
    }
    .el-select-dropdown__list {
      padding: 16px 0 !important;
    }
  }

  // .el-select-dropdown__item {
  //   padding: 10px 20px !important;
  // }
}

.collapse_with_rtl_direction {
  .el-collapse-item__header {
    flex-direction: row-reverse;
    .wrapper_for_search_and_filter {
      flex-direction: row-reverse;
    }
  }
}

.table_with_rtl_direction {
  // background: #f00 !important;
  // padding: 40px;
  direction: rtl;
  .el-table__header-wrapper {
    direction: rtl;
  }
  .cell {
    text-align: right;
  }
}
.footer_with_rtl_direction {
  flex-direction: row-reverse;
  .handler_showing_filters_with_rtl {
    flex-direction: row-reverse;
  }
}
.tabs_for_contacts_with_rtl {
  // background: #ddd;
  direction: rtl;
  .el-tabs__nav {
    float: right;
  }
  .el-tabs__active-bar {
    display: none;
  }
  .el-tabs__item {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      height: 0;
      background-color: #409eff;
      z-index: 1;
      width: 90%;
      bottom: 0;
      right: 0;
    }
    &.is-active {
      &::before {
        height: 2px;
      }
    }
  }
}

.el-table__empty-text {
  width: 100%;
}

.el-tabs__content {
  border: 1px solid #dee2e6 !important;
  border-radius: 10px;
}
.el-tabs__nav {
  width: 100%;
}

.el-tabs__nav-wrap {
  overflow: hidden;
  /* margin-bottom: -1px; */
  position: relative;
  padding: 8px;
  background: rgb(249, 250, 251);
  margin: 8px 0 15px;
  border-radius: 8px;
}
.el-tabs__nav-wrap::after {
  display: none !important;
  height: 0 !important;
}
.el-tabs__item {
  padding: 0 8px !important;
  height: auto;
  font-size: 16px;
  margin: 3px;
  color: rgba(102, 112, 133, 1) !important;
}

.el-tabs__item.is-active {
  background: white;
  color: rgba(52, 64, 84, 1) !important;
  border-radius: 6px;
  padding: 0 8px !important;
  height: auto;
  font-size: 16px;
  margin: 3px;
  box-shadow: 1px 3px 0px 0px rgba(102, 112, 133, 0.08);
  .tab-count {
    display: inline;
  }
}
.el-tabs__item:hover {
  color: rgba(52, 64, 84, 1) !important;
  cursor: pointer;
}
.el-tabs__active-bar {
  display: none;
}

.tab-count {
  background: #f2f4f7;
  border-radius: 16px;
  padding: 4px 10px;
  font-size: 14px;
  color: #344054;
}

.empty-table-wrapper {
  text-align: center;
  color: #fff; /* White text for dark background */
}

// @media print {
.el-table__body,
.el-table__footer,
.el-table__header {
  table-layout: auto !important;
  border-collapse: separate;
  width: 100% !important;
}
.el-table .cell {
  padding: 0;
}
// }
</style>

<style lang="scss" scoped>
// ::v-deep .el-select-dropdown__list {
// ::v-deep .el-select-dropdown__list {
// padding: 16px 0 !important;
//   background: #eee !important;
//   .el-select-dropdown__item {
//     background: #eee !important;
//   }
// }
.main_data_table_pagination_container {
  .data_table_pagination_footer {
    .showing_numbers_container {
      @media (min-width: 320px) and (max-width: 576px) {
        display: block !important;
        .card_numbers_text {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

::v-deep .el-table .el-table__body tr {
  border-bottom: 1px solid #e5e5e5; /* Adjust the color as needed */
}

// ::v-deep .el-table .el-table__body td {
//   border-right: 1px solid #e4e7ed; /* Adjust the color as needed */
// }

::v-deep .el-table__body {
  border-collapse: collapse !important;
}

::v-deep .card-footer,
.card {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

::v-deep .el-table__body,
.el-table__footer,
.el-table__header {
  table-layout: auto !important;
  border-collapse: separate;
}
</style>
